import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class LoadMoreButton extends Component {
  render() {
    const { message, onLoadMore } = this.props

    return (
      <div className='row'>
        <div className='col-md-12'>
          <button onClick={onLoadMore} className='btn btn-primary btn-block'>
            {message}
          </button>
        </div>
      </div>
    )
  }
}

LoadMoreButton.propTypes = {
  message: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired
}
