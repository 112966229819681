import * as Types from '../constants/ActionTypes'

export const resetStore = () => {
  return dispatch => {
    dispatch(resettingStore())
  }
}

const resettingStore = () => {
  return {
    type: Types.RESET_STORE
  }
}
