import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import CreatableSelect from 'react-select/lib/Creatable'

import InputText from '../InputText'
import TextArea from '../TextArea'

const ComicForm = ({
  form,
  isLoading,
  errors,
  onChange,
  onSearchSeries,
  onSelectSeries,
  seriesList
}) => {
  const { title, isbn, upc, issue_number, creators, description, series } = form
  const initialSeries = series ? { value: series.id, label: series.title } : {}

  return (
    <Fragment>
      <InputText
        label='Title'
        name='title'
        value={title}
        errors={errors.title}
        onChange={onChange}
      />
      <InputText label='ISBN' name='isbn' value={isbn} onChange={onChange} />
      <InputText label='UPC' name='upc' value={upc} onChange={onChange} />
      <div className='form-group'>
        <label>Series</label>
        <CreatableSelect
          defaultValue={initialSeries}
          options={seriesList}
          onChange={onSelectSeries}
          onInputChange={onSearchSeries}
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            return (
              selectOptions.length === 0 && inputValue.length > 0 && !isLoading
            )
          }}
        />
      </div>
      <InputText
        label='Issue No. or Volume No.'
        name='issue_number'
        value={issue_number}
        onChange={onChange}
      />
      <InputText
        label='Creators'
        name='creators'
        value={creators}
        errors={errors.creators}
        onChange={onChange}
      />
      <TextArea
        maxLength='2000'
        label='Description'
        name='description'
        value={description}
        errors={errors.description}
        onChange={onChange}
      />
    </Fragment>
  )
}

ComicForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  onSearchSeries: PropTypes.func,
  onSelectSeries: PropTypes.func,
  seriesList: PropTypes.array
}

export default ComicForm
