import React from 'react'

import PropTypes from 'prop-types'

const ReservationListInfo = ({ reservation_list }) => {
  const statusMap = {
    open: 'Pending',
    checked: 'Checked',
    approved: 'Approved',
    // eslint-disable-next-line camelcase
    partially_approved: 'Partially Approved',
  }
  
  return (
    <div className='customer-info'>
      <div className='customer'>
        <h5>
          <span>
            {reservation_list.user.first_name} {reservation_list.user.last_name}
          </span>
          {' / '}
          <span className='contact-number'>
            {reservation_list.phone_number} /
          </span>{' '}
          {reservation_list.delivery_option === 'shipping' ? (
            <span className='contact-branch'>
              {reservation_list.shipping_address}
            </span>
          ) : (
            <span className='contact-branch'>{reservation_list.branch}</span>
          )}{' '}
          {reservation_list.user.blocked === 'true' ? (
            <span className='text-danger'> / Blocked User!</span>
          ) : (
            <span />
          )}
          <span>
            Status: {statusMap[reservation_list.status]}
          </span>
          <br />
          <span>
            Created: {reservation_list.created_at} / Updated:{' '}
            {reservation_list.updated_at}
          </span>
        </h5>
      </div>
    </div>
  )
}

ReservationListInfo.propTypes = {
  reservation_list: PropTypes.object.isRequired
}

export default ReservationListInfo
