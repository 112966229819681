export const fetchPrice = item => {
  if (item.product_item) {
    return +item.product_item.price
  }
  if (item.reservation) {
    return +item.reservation.product.price
  }
}

export const fetchQuantity = item => {
  if (item.reservation) {
    return +item.reservation.quantity
  }
  return 1
}

export const fetchProductTitle = item => {
  if (item.product) {
    return item.product.title
  }
  if (item.product_title) {
    return item.product_title
  }
}
