const reservationStatuses = {
  for_approval: 'Pending',
  fill: 'Reserved',
  void: 'Cancelled',
  short: 'Short',
  internal: 'Short',
  external: 'Short'
}

export const humanizeReservationStatus = status => {
  return reservationStatuses[status]
}

export const mapReservationStatus = (statuses, { mergeShort }) => {
  let mapped = statuses

  if (mergeShort) {
    mapped = statuses.filter(s => !['internal', 'external'].includes(s))
    mapped = [...mapped, 'short']
  }

  return mapped.map(s => {
    return { name: humanizeReservationStatus(s), value: s }
  })
}
