const SOUTH_NCR_CITIES = ['Las Pinas City', 'Paranaque City', 'Muntinlupa City']

export const calculateShippingFee = region => {
  if (region === 'metro') {
    return 100
  }
  if (region === 'south_ncr') {
    return 150
  }
  if (region === 'province') {
    return 200
  }
  return 0
}

export const calculateItemsTotal = cartItems => {
  if (cartItems.length <= 0) {
    return 0.0
  }

  return cartItems.reduce((sum, value) => {
    const price = value.product_item ? Number(value.price) : Number(value.price)
    return sum + price
  }, 0)
}

export const calculateDiscount = (discount_rate, totalPrice) => {
  return (+discount_rate / 100) * +totalPrice
}

export const calculateSubTotal = (total, discount = 0) => {
  return total - discount
}

export const calculateDeliveryCost = (
  cartItems,
  shippingAddress = '',
  region = '',
  city = '',
  // subTotal
) => {
  if (cartItems.length === 0) {
    return 0
  }

  // No more free delivery for now
  // if (subTotal >= 1000) {
  //   return 0
  // }

  if (!shippingAddress && !region) {
    return 0
  }
  
  if (SOUTH_NCR_CITIES.includes(city) || SOUTH_NCR_CITIES.some(southNcrCity => shippingAddress.includes(southNcrCity))) {
    return 150.0 // South NCR addresses have increased delivery fee
  } else if (shippingAddress && shippingAddress.includes('Metro Manila')) {
    return 100.0
  } else if (region && region === 'Metro Manila') {
    return 100.0
  } else {
    return 200.0
  }
}

export const calculateTotal = (subTotal, deliveryCost) => {
  return subTotal + deliveryCost
}
