import * as Types from '../constants/ActionTypes'
import UsersAPI from '../services/users_api'

export function fetchCurrentUser() {
  return dispatch => {
    UsersAPI.fetch(
      data => {
        dispatch(receiveUser(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

function receiveUser(user) {
  return {
    type: Types.RECEIVE_USER,
    contact_number: user.contact_number,
    branch_name: user.branch_name,
    series_ids: user.series_ids,
    discount_rate: user.discount_rate,
    addresses: user.addresses,
    primary_address: user.primary_address,
    default_fulfillment: user.default_fulfillment
  }
}
