import {
  RECEIVE_PRODUCTS,
  RECEIVE_RESERVATIONS,
  RECEIVE_RELEASES,
  CREATE_RESERVATION,
  UPDATE_RESERVATION,
  DELETE_RESERVATION,
  REFRESH_RELEASE,
  SWITCH_RELEASES,
  CONFIRM_RESERVATION,
  RECEIVE_FILTER,
  RECEIVE_LAYOUT,
  RECEIVE_LOADING,
  CLEAR_PRODUCTS,
  RESET_STORE
} from '../constants/ActionTypes'
import includes from 'lodash/includes'
import filter from 'lodash/filter'

const initialState = {
  releases: [],
  products: [],
  currentPage: 1,
  selectedRelease: {},
  reservations: [],
  reserving: false,
  finalPage: false,
  layout: 'list',
  loading: false
}

export default function releases(state = initialState, action) {
  let updatedReservations, newReservations, reservationIds, newRelease

  switch (action.type) {
    case RESET_STORE:
      return initialState
    case CLEAR_PRODUCTS:
      return { ...state, products: [] }
    case RECEIVE_LOADING:
      return { ...state, loading: action.loading }
    case RECEIVE_LAYOUT:
      return { ...state, layout: action.layout }
    case RECEIVE_RESERVATIONS:
      return {
        ...state,
        reservations: action.reservations,
        reserving: action.reserving
      }
    case CREATE_RESERVATION:
      return {
        ...state,
        reservations: state.reservations.concat(action.reservation)
      }
    case UPDATE_RESERVATION:
      updatedReservations = removeReservation(
        state.reservations,
        action.reservation
      ).concat(action.reservation)
      return { ...state, reservations: updatedReservations }
    case DELETE_RESERVATION:
      newReservations = removeReservation(
        state.reservations,
        action.reservation
      )
      return { ...state, reservations: newReservations }
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: state.products.concat(action.products),
        currentPage: state.currentPage + 1,
        finalPage: checkForFinalPage(action.products, action.override)
      }
    case RECEIVE_RELEASES:
      return {
        ...state,
        releases: action.releases,
        selectedRelease: action.releases[action.releases.length - 1]
      }
    case SWITCH_RELEASES:
      return {
        ...state,
        releases: action.releases,
        selectedRelease: action.newRelease,
        products: [],
        currentPage: 1,
        finalPage: false
      }
    case REFRESH_RELEASE:
      return { ...state, selectedRelease: action.selectedRelease }
    case RECEIVE_FILTER:
      reservationIds = collectReservationIds(state.reservations)

      newRelease = filterByProductTitle(
        filterByReservation(
          filterByPublisher(
            filterByCategory(state.selectedRelease, action.data.category_id),
            action.data.publisher_id
          ),
          reservationIds,
          action.data.reservation
        ),
        action.data.search
      )

      return { ...state, selectedRelease: newRelease }
    case CONFIRM_RESERVATION:
      return { ...state, reserving: action.reserving }
    default:
      return state
  }
}

export function getReleases(state) {
  return {
    releases: state.releases,
    selectedRelease: state.selectedRelease
  }
}

export function getReleasesAndOrders(state) {
  return {
    releases: state.releases.releases,
    selectedRelease: state.releases.selectedRelease,
    hasExistingOrder: findExistingOrder(
      state.orders.orders,
      state.releases.selectedRelease
    )
  }
}

export function getReleasesAndReservations(state) {
  return {
    releases: state.releases,
    selectedRelease: state.selectedRelease,
    reservations: state.reservations,
    reserving: state.reserving
  }
}

function findExistingOrder(orders, currentRelease) {
  const existingOrder = filter(orders, item => {
    return item.release_id === currentRelease.id
  })

  return existingOrder.length !== 0
}

function removeReservation(array, target) {
  return filter(array, element => {
    return element.product.id !== target.product.id
  })
}

function filterByProductTitle(release, keyword) {
  if (keyword === '') {
    return release
  } else {
    return Object.assign({}, release, {
      products: filter(release.products, item => {
        return includes(item.title.toLowerCase(), keyword.toLowerCase())
      })
    })
  }
}

function filterByPublisher(release, keyword) {
  if (parseInt(keyword) === 0) {
    return release
  } else {
    return Object.assign({}, release, {
      products: filter(release.products, item => {
        return item.publisher_id === parseInt(keyword)
      })
    })
  }
}

function filterByCategory(release, keyword) {
  if (parseInt(keyword) === 0) {
    return release
  } else {
    return Object.assign({}, release, {
      products: filter(release.products, item => {
        return item.category_id === parseInt(keyword)
      })
    })
  }
}

function filterByReservation(release, reservationIds, keyword) {
  switch (keyword) {
    case 'Reserved':
      return Object.assign({}, release, {
        products: filter(release.products, item => {
          return includes(reservationIds, item.id)
        })
      })
    case 'Unreserved':
      return Object.assign({}, release, {
        products: filter(release.products, item => {
          return !includes(reservationIds, item.id)
        })
      })
    default:
      return release
  }
}

function collectReservationIds(reservations) {
  let reservationIds = []

  reservations.map(reservation => {
    reservationIds.push(reservation.product.id)
  })

  return reservationIds
}

const checkForFinalPage = (array, override) => {
  if (override !== undefined) {
    return override
  }
  return array.length === 0
}
