import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import { getReleasesAndReservations } from '../../reducers/releases'
import { getUser } from '../../reducers/users'
import { switchRelease, switchLayout } from '../../actions/releases'
import {
  createReservation,
  updateReservation,
  deleteReservation,
  cancelReservation
} from '../../actions/reservations'
import {
  confirmReservations,
  cancelReservationList
} from '../../actions/reservation_lists'
import { blowUpImage, fetchProducts } from '../../actions/products'
import ProductList from '../../components/products/ProductList'
import ProductItem from '../../components/products/ProductItem'
import BlankSlate from '../../components/shared/BlankSlate'
import LoadingScreen from '../../components/shared/LoadingScreen'
import LoadMoreButton from '../../components/shared/LoadMoreButton'
import ReservationModal from '../../components/modals/ReservationModal'
import ProductLayoutSwitcher from '../../components/products/ProductLayoutSwitcher'
import FilterContainer from '../../containers/FilterContainer'

class ReservationContainer extends Component {
  static propTypes = {
    currentReservationList: PropTypes.object,
    currentRelease: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    releases: PropTypes.object.isRequired,
    currentPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    finalPage: PropTypes.bool.isRequired,
    currentLayout: PropTypes.string.isRequired,
    products: PropTypes.array.isRequired,
    confirmReservations: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    switchLayout: PropTypes.func.isRequired,
    updateReservation: PropTypes.func.isRequired,
    createReservation: PropTypes.func.isRequired,
    deleteReservation: PropTypes.func.isRequired,
    cancelReservation: PropTypes.func.isRequired,
    cancelReservationList: PropTypes.func.isRequired,
    blowUpImage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      currentReservationList: this.props.currentReservationList
    }
  }

  componentWillUpdate(nextProps) {
    if (
      !isEqual(
        nextProps.currentReservationList,
        this.state.currentReservationList
      )
    ) {
      this.setState({
        loading: false,
        currentReservationList: nextProps.currentReservationList
      })
    }
  }

  findReservation(productId) {
    return find(this.props.releases.reservations, reservation => {
      return reservation.product.id === productId
    })
  }

  renderItems() {
    const { products, currentRelease, currentLayout, user } = this.props
    const {
      updateReservation,
      createReservation,
      deleteReservation,
      blowUpImage
    } = this.props

    return products.map((product, index) => {
      return (
        <ProductItem
          view={currentLayout}
          key={index}
          releaseId={currentRelease.id}
          onUpdateReservation={updateReservation}
          onCreateReservation={createReservation}
          onDeleteReservation={deleteReservation}
          onBlowUpImage={blowUpImage}
          user={user}
          reservation={this.findReservation(product.id)}
          reservationList={this.state.currentReservationList}
          product={product}
        />
      )
    })
  }

  onSubmitReservations = data => {
    this.setState({
      loading: true,
      currentReservationList: {
        ...this.state.currentReservationList,
        updated: true
      }
    })
    this.props.confirmReservations(data)
  }

  renderInfo() {
    const { fetchProducts, switchLayout } = this.props
    const {
      currentRelease,
      currentPage,
      finalPage,
      currentLayout,
      products,
      loading
    } = this.props

    return (
      <div className='container-fluid'>
        <div className='release-list-header'>
          <div className='row align-items-center'>
            <div className='col-md-10'>
              <FilterContainer />
            </div>
            <div className='col-md-2'>
              <ProductLayoutSwitcher
                currentLayout={currentLayout}
                onSwitchLayout={switchLayout}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12'>
            {products.length === 0 ? (
              <BlankSlate
                message={
                  loading === true
                    ? 'Loading...'
                    : 'There are no items in your search criteria'
                }
              />
            ) : (
              <ProductList view={currentLayout}>
                {this.renderItems()}
              </ProductList>
            )}

            {finalPage === true ? (
              ''
            ) : (
              <LoadMoreButton
                message={'Load More'}
                onLoadMore={() => fetchProducts(currentRelease.id, currentPage)}
              />
            )}
            {!isEmpty(this.props.user) && (
              <ReservationModal
                currentUser={this.props.user}
                onCloseModal={this.props.cancelReservation}
                onSubmitReservations={this.onSubmitReservations}
                cancelReservationList={this.props.cancelReservationList}
                showModal={this.props.releases.reserving}
                loading={this.state.loading}
                attributes={this.state.currentReservationList}
                reservations={this.props.releases.reservations}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { releases } = this.props.releases
    const { loading } = this.props

    return (
      <div>
        {releases.length === 0 ? '' : this.renderInfo()}
        {loading === true ? <LoadingScreen /> : ''}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    releases: getReleasesAndReservations(state.releases),
    products: state.releases.products,
    currentRelease: state.releases.selectedRelease,
    user: getUser(state.users),
    currentReservationList: find(
      state.reservation_lists.reservationLists,
      item => {
        return item.release_id === state.releases.selectedRelease.id
      }
    ),
    currentPage: state.releases.currentPage,
    finalPage: state.releases.finalPage,
    currentLayout: state.releases.layout,
    loading: state.releases.loading
  }
}

export default connect(mapStateToProps, {
  switchRelease,
  createReservation,
  updateReservation,
  deleteReservation,
  cancelReservation,
  blowUpImage,
  fetchProducts,
  switchLayout,
  cancelReservationList,
  confirmReservations
})(ReservationContainer)
