import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import debounce from 'lodash/debounce'
import numeral from 'numeral'

export default class ProductItem extends Component {
  handleSubmit(params) {
    const { id } = this.props.product
    const { releaseId } = this.props
    const { onUpdateProduct } = this.props

    onUpdateProduct(releaseId, id, params)
  }

  handleDrop = files => {
    const { id } = this.props.product
    const { releaseId } = this.props
    const { onUploadCover } = this.props

    onUploadCover(releaseId, id, files[0])
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.product.featured !== nextProps.product.featured ||
      this.props.product.cover_url !== nextProps.product.cover_url
    )
  }

  renderPublishers() {
    const { publishers } = this.props

    return publishers.map(publisher => {
      return (
        <option key={publisher.id} value={publisher.id}>
          {publisher.title}
        </option>
      )
    })
  }

  renderCategories() {
    const { categories } = this.props

    return categories.map(category => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      )
    })
  }

  render() {
    const {
      cover_url,
      title,
      creators,
      description,
      publisher_id,
      category_id,
      price,
      cover_price,
      total_reservations,
      featured,
      quantity,
      id
    } = this.props.product
    const { onDestroyProduct } = this.props
    const { releaseId } = this.props
    let titleInput,
      creatorsInput,
      descriptionInput,
      publisherSelect,
      categorySelect,
      priceInput,
      quantityInput,
      featuredInput

    return (
      <tr>
        <td className='cover'>
          <Dropzone
            className='dropzone'
            accept='image/jpeg, image/jpg, image/png, image/gif'
            multiple={false}
            onDrop={this.handleDrop}
          >
            <div>
              <img
                src={cover_url}
                className={`cover-image ${
                  cover_url.match(/default/) ? '-default-cover' : '-with-cover'
                }`}
              />
            </div>
          </Dropzone>
          <small className='cover-notice'>Drag & drop cover to update</small>
        </td>

        <td className='-highlight'>
          <input
            className='-issue-title'
            ref={el => {
              titleInput = el
            }}
            type='text'
            onChange={debounce(() => {
              this.handleSubmit({ title: titleInput.value })
            }, 500)}
            defaultValue={title}
          />

          <input
            className='-issue-creators'
            ref={el => {
              creatorsInput = el
            }}
            type='text'
            onChange={debounce(() => {
              this.handleSubmit({ creators: creatorsInput.value })
            }, 500)}
            defaultValue={creators}
          />

          <textarea
            className='-issue-description'
            ref={el => {
              descriptionInput = el
            }}
            onChange={debounce(() => {
              this.handleSubmit({ description: descriptionInput.value })
            }, 500)}
            defaultValue={description}
          />
        </td>
        <td className='price -sm'>
          <input
            ref={el => {
              priceInput = el
            }}
            type='number'
            onChange={debounce(() => {
              this.handleSubmit({ price: priceInput.value })
            }, 500)}
            min='0'
            step='any'
            defaultValue={price}
          />
        </td>
        <td>
          <select
            ref={el => {
              publisherSelect = el
            }}
            onChange={() => {
              this.handleSubmit({ publisher_id: publisherSelect.value })
            }}
            defaultValue={publisher_id}
          >
            {this.renderPublishers()}
          </select>
        </td>
        <td>
          <select
            ref={el => {
              categorySelect = el
            }}
            onChange={() => {
              this.handleSubmit({ category_id: categorySelect.value })
            }}
            defaultValue={category_id}
          >
            {this.renderCategories()}
          </select>
        </td>
        <td>{numeral(cover_price).format('$0,0.00')}</td>
        <td className='orders_count' hidden={releaseId === undefined}>
          <input
            ref={el => {
              quantityInput = el
            }}
            type='number'
            onChange={debounce(() => {
              this.handleSubmit({ quantity: quantityInput.value })
            }, 500)}
            min='0'
            step='any'
            defaultValue={quantity}
          />
        </td>
        <td hidden={releaseId === undefined}>0</td>
        <td hidden={releaseId === undefined}>
          <input
            type='checkbox'
            ref={el => {
              featuredInput = el
            }}
            onChange={() => {
              this.handleSubmit({ featured: !featured })
            }}
            checked={featured}
          />
        </td>
        {releaseId === undefined ? (
          ''
        ) : (
          <td className='actions'>
            <a href={`/admin/products/${id}`} className='-issue-action'>
              <i className='ion-eye' />
            </a>
            <a
              onClick={() => onDestroyProduct(releaseId, id)}
              className='-issue-action'
            >
              <i className='ion-ios-trash' />
            </a>
          </td>
        )}
      </tr>
    )
  }
}
