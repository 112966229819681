import findIndex from 'lodash/findIndex'

import updateObjectInArray from '../utilities/updateObjectInArray'
import * as Types from './actionTypes'

const initialState = {
  reservations: [],
  current_page: 1,
  total_pages: 1,
  products: [],
  uiState: {
    search: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.RECEIVE_RESERVATIONS:
      return {
        ...state,
        ...action.payload
      }
    case Types.RECEIVE_PRODUCTS:
      return {
        ...state,
        ...action.payload
      }
    case Types.UPDATE_UI_STATE:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          ...action.payload
        }
      }
    case Types.RECEIVE_UPDATED_RESERVATION:
      return {
        ...state,
        reservations: updateObjectInArray(state.reservations, {
          index: findIndex(state.reservations, { id: action.reservation.id }),
          item: action.reservation
        })
      }
    default:
      return state
  }
}

export default reducer
