import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ProductDetailItem from './ProductDetailItem'

export default class ProductDetail extends Component {
  closeModal() {
    $('.special-modal').modal('hide')
  }

  renderDescriptionWithBreaklines(text) {
    return text.split('\n').map(item => {
      return (
        <span>
          {item}
          <br />
        </span>
      )
    })
  }

  renderProductItemTable() {
    const { productItems } = this.props

    return Object.keys(productItems).map((key, index) => {
      return (
        <ProductDetailItem key={index} productItem={productItems[key][0]} />
      )
    })
  }

  render() {
    const {
      cover_url,
      description,
      title,
      creators,
      publisher_name
    } = this.props.product

    return (
      <div className='row'>
        <a
          onClick={this.closeModal}
          aria-label='Close'
          className='btn btn-default btn-sm'
        >
          Close
        </a>
        <hr />

        <div className='col-md-4'>
          <img src={cover_url} />
        </div>
        <div className='col-md-8'>
          <h4> {title} </h4>
          <p key='publisher_and_creators'>
            {publisher_name}
            &nbsp; | &nbsp;
            {creators}
          </p>

          {description === null ? (
            ''
          ) : (
            <p key='description'>
              {this.renderDescriptionWithBreaklines(description)}
            </p>
          )}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className='col-md-12'>
          <table className='table table-striped table-condensed'>
            <thead>
              <tr>
                <th> Condition </th>
                <th> Price </th>
                <th />
              </tr>
            </thead>
            <tbody>{this.renderProductItemTable()}</tbody>
          </table>
        </div>
      </div>
    )
  }
}
