import React from 'react'
import PropTypes from 'prop-types'

import LocationsAPI from '../../services/locations_api'
import CartItemAPI from '../../services/cart_items_api'
import OrdersAPI from '../../services/orders_api'
import CheckoutFormSection from '../components/CheckoutFormSection'
import CartItems from '../components/CartItems'
import AddressAPI from '../../services/address_api'
import SweetAlert from '../../components/shared/SweetAlert'
import {
  calculateSubTotal,
  calculateDiscount,
  calculateTotal,
  calculateDeliveryCost,
  calculateItemsTotal
} from '../../utilities/summaryCalculations'
import { fullAddress } from '../../utilities/addressFormatter'
import {
  getBarangays,
  getCities,
  getRegions
} from '../../utilities/locationsHelper'

export default class CartBox extends React.Component {
  static propTypes = {
    setParentState: PropTypes.func,
    user: PropTypes.object
  }

  state = {
    form: {
      delivery_option: this.props.user.default_fulfillment || 'shipping',
      branch: this.props.user.branch_name || '',
      shipping_address:
        this.props.user.default_fulfillment === 'store'
          ? ''
          : this.props.user.primary_full_address || ''
    },
    addressForm: {
      country: 'Philippines'
    },
    cartItems: [],
    cities: [],
    barangays: [],
    regions: [],
    cartItemsLoaded: false,
    locationsLoaded: false,
    disableButton: false
  }

  componentDidMount() {
    LocationsAPI.fetch(({ data }) => {
      const regionsData = getRegions(data)
      this.setState({
        locations: data,
        regions: regionsData,
        locationsLoaded: true
      })
    })
    CartItemAPI.fetch(this.props.user.id, response => {
      this.setState({
        cartItems: response.data,
        cartItemsLoaded: true
      })
      this.props.setParentState({
        cartItems: response.data
      })
    })
  }

  handleChange = e => {
    const { form } = this.state
    const { name, value } = e.target

    let newForm = {
      ...form,
      [name]: value
    }

    this.setState({
      form: newForm
    })
  }

  getShippingRegion = () => {
    const { cartItems, form, region, city} = this.state
    if (calculateDeliveryCost(cartItems, form.shipping_address, region, city) === 100.0) {
      return 'metro'
    } else if (calculateDeliveryCost(cartItems, form.shipping_address, region, city) === 150.0) {
      return 'south_ncr'
    } else {
      return 'province'
    }
  }

  getFullAddresses = () => {
    return this.props.user.addresses.map(address => {
      return { name: fullAddress(address) }
    })
  }

  handleAddressChange = e => {
    const { addressForm } = this.state
    const { name, value } = e.target

    let newAddressForm = {
      ...addressForm,
      [name]: value
    }

    this.setState({
      addressForm: newAddressForm
    })
  }

  selectRegion = e => {
    this.setState({
      addressForm: {
        ...this.state.addressForm,
        [e.target.name]: e.target.value
      },
      region: e.target.value,
      cities: getCities(e.target.value, this.state.locations),
      barangays: []
    })
  }

  selectCity = e => {
    this.setState({
      addressForm: {
        ...this.state.addressForm,
        [e.target.name]: e.target.value
      },
      city: e.target.value,
      barangays: getBarangays(
        this.state.region,
        e.target.value,
        this.state.locations
      )
    })
  }

  selectBarangay = e => {
    this.setState({
      addressForm: {
        ...this.state.addressForm,
        [e.target.name]: e.target.value
      },
      barangay: e.target.value
    })
  }

  onCartRemove = cartItemId => {
    if (!cartItemId) {
      return null
    }

    SweetAlert.prompt('Yes, remove it!').then(result => {
      if (result.value) {
        CartItemAPI.destroy(this.props.user.id, cartItemId, response => {
          if (response.statusText === 'OK') {
            this.setState({
              cartItems: this.state.cartItems.filter(
                item => cartItemId !== item.id
              )
            })
            this.props.setParentState({
              cartItems: this.state.cartItems
            })
            SweetAlert.success(
              'Removed!',
              'This product has been removed from your cart'
            )
          }
        })
      }
    })
  }

  onCartRemoveAll = () => {
    SweetAlert.prompt('Clear Cart').then(result => {
      CartItemAPI.destroy_all(this.props.user.id, response => {
        if (response.statusText === 'OK') {
          this.setState({
            cartItems: []
          })
          this.props.setParentState({
            cartItems: this.state.cartItems
          })
          SweetAlert.success(
            'Removed!',
            'All product has been removed from your cart'
          )
        }
      })
    })
  }

  handleCheckoutFormData = data => {
    this.props.setParentState({
      cartData: { ...data.order },
      showCartForm: false
    })
  }

  handleSubmit = form => {
    const { cartItems, region, addressForm, city } = this.state
    const itemsTotal = calculateItemsTotal(cartItems)
    const discountTotal = calculateDiscount(
      this.props.user.discount_rate,
      itemsTotal
    )
    const subTotal = calculateSubTotal(itemsTotal, discountTotal)
    const deliveryCost = calculateDeliveryCost(
      cartItems,
      form.shipping_address,
      region,
      city,
    )
    const total = calculateTotal(subTotal, deliveryCost, discountTotal)
    let data = {
      order: {
        ...form,
        shipping_total: deliveryCost,
        shipping_region: this.getShippingRegion(),
        discount_total: discountTotal,
        sub_total_price: subTotal,
        total_price: total
      }
    }
    if (form.delivery_option === 'shipping' && !form.shipping_address) {
      const dataAddress = addressForm
      data = {
        order: {
          ...data.order,
          shipping_address: fullAddress(addressForm)
        }
      }
      AddressAPI.create(
        this.props.user.id,
        dataAddress,
        response => {
          this.setState({
            form: {
              ...form,
              shipping_address: fullAddress(response.data)
            }
          })
          this.handleCheckoutFormData(data)
        },
        error => {
          SweetAlert.error('Error', error)
        }
      )
    } else {
      this.handleCheckoutFormData(data)
    }
  }

  handleDeliveryOption = e => {
    if (e.target.value === 'store') {
      this.setState({
        form: {
          ...this.state.form,
          branch: this.props.user.branch_name || '',
          shipping_address: '',
          [e.target.name]: e.target.value
        }
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          branch: '',
          shipping_address: this.props.user.primary_full_address || '',
          [e.target.name]: e.target.value
        }
      })
    }
  }

  render() {
    const {
      cartItems,
      form,
      locations,
      regions,
      addressForm,
      cities,
      barangays,
      locationsLoaded,
      cartItemsLoaded,
      region,
      city,
      disableButton
    } = this.state
    const {
      handleDeliveryOption,
      handleChange,
      handleAddressChange,
      selectRegion,
      selectCity,
      selectBarangay,
      getFullAddresses
    } = this
    const itemsTotal = calculateItemsTotal(cartItems)
    const discountTotal = calculateDiscount(
      this.props.user.discount_rate,
      itemsTotal
    )
    const subTotal = calculateSubTotal(itemsTotal, discountTotal)
    const deliveryCost = calculateDeliveryCost(
      cartItems,
      form.shipping_address,
      region,
      city
    )
    const total = calculateTotal(subTotal, deliveryCost, discountTotal)
    if (!locationsLoaded || !cartItemsLoaded) {
      return null
    }
    return (
      <div className='row justify-content-center'>
        <div className='cart col-md-8 col-sm-12'>
          <div className='row justify-content-between mb-3'>
            <div className='col-4'>
              <h1>Your Cart</h1>
            </div>
            <div className='col-4 text-right'>
              {cartItems.length !== 0 && (
                <button
                  className='btn btn-danger'
                  onClick={this.onCartRemoveAll}
                >
                  Clear cart
                </button>
              )}
            </div>
          </div>
          <CartItems
            cartItems={cartItems}
            onCartRemove={this.onCartRemove}
            subTotal={subTotal}
            discountTotal={discountTotal}
            user={this.props.user}
            total={total}
            deliveryCost={deliveryCost}
            shippingTotal={form.shipping_total}
          />
          <hr />
          {cartItems.length !== 0 ? (
            <CheckoutFormSection
              onSubmit={this.handleSubmit}
              user={this.props.user}
              getFullAddresses={getFullAddresses()}
              {...{
                handleDeliveryOption,
                cartItems,
                locations,
                regions,
                form,
                handleChange,
                addressForm,
                handleAddressChange,
                selectRegion,
                selectCity,
                selectBarangay,
                cities,
                barangays,
                disableButton
              }}
            />
          ) : (
            <div className='alert alert-danger'>
              Your cart is currently empty
            </div>
          )}
        </div>
      </div>
    )
  }
}
