import API from './api'
import axios from 'axios'
import { onError, serviceHandlers } from './serviceHandlers'

const AddressAPI = {
  fetch: async (userId, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/addresses`
    try {
      const response = await axios.get(url)
      onSuccess(response)
    } catch (e) {
      onException(e)
    }
  },
  create: async (userId, options, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/addresses`
    try {
      const response = await axios.post(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  },
  update: async (
    userId,
    addressId,
    options,
    onSuccess,
    onException = onError
  ) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/addresses/${addressId}`
    try {
      const response = await axios.put(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  }
}

export default AddressAPI
