import API from './api'

const ReleasesAPI = {
  fetch(onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  fetchOne(releaseId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  approveReservationLists(
    releaseId,
    onSuccess,
    onError,
    onComplete = () => {}
  ) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservation_lists/approve`,
      method: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default ReleasesAPI
