import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Provider } from 'react-redux'

import ReservationContainer from './ReservationContainer'
import NotificationContainer from '../NotificationContainer'
import ModalContainer from '../ModalContainer'
import DevTools from '../DevTools'

export default class ReservationRoot extends Component {
  render() {
    const { store, env } = this.props

    return (
      <Provider store={store}>
        <div>
          <NotificationContainer />
          <ReservationContainer />
          <ModalContainer />
          {env === 'development' ? <DevTools /> : ''}
        </div>
      </Provider>
    )
  }
}

ReservationRoot.propTypes = {
  store: PropTypes.object.isRequired,
  env: PropTypes.string.isRequired
}
