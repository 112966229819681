import React from 'react'
import PropTypes from 'prop-types'
import ProductRow from './ProductRow'
import DiscountRateView from './DiscountRateView'
import { formatCurrency } from '../../presenters/format'
import ShippingFeeView from './ShippingFeeView'

const CartItems = ({
  cartItems,
  subTotal,
  user,
  discountTotal,
  total,
  deliveryCost,
  shippingTotal,
  onCartRemove
}) => {
  return (
    <table className='table table-striped orders'>
      <thead>
        <tr>
          <td>Product</td>
          <td>
            <div className='text-center'>Quantity</div>
          </td>
          <td>Price</td>
          {onCartRemove && <td />}
        </tr>
      </thead>
      <tbody>
        {cartItems.map((cartItem, index) => {
          if (cartItem.product) {
            return (
              <ProductRow
                key={index}
                cartItem={cartItem}
                product={cartItem.product}
                price={cartItem.price}
                onCartRemove={onCartRemove}
              />
            )
          }
          if (cartItem.reservation) {
            return (
              <ProductRow
                key={index}
                cartItem={cartItem}
                product={cartItem.reservation.product}
                price={cartItem.price}
                onCartRemove={onCartRemove}
              />
            )
          }
        })}
        {user.discount_rate && (
          <DiscountRateView
            discountTotal={discountTotal}
            discountRate={user.discount_rate}
          />
        )}
        <tr>
          <td>
            <strong>Sub Total</strong>
          </td>
          <td />
          <td>{formatCurrency(subTotal)}</td>
          <td />
        </tr>
        <tr>
          <td className='-highlight'>Shipping Fee</td>
          <td />
          <ShippingFeeView deliveryCost={deliveryCost} subTotal={subTotal} />
          <td />
        </tr>
        <tr className='total'>
          <td>
            <h4>Total</h4>
          </td>
          <td />
          <td>{formatCurrency(total)}</td>
          <td />
        </tr>
      </tbody>
    </table>
  )
}

CartItems.propTypes = {
  cartItems: PropTypes.array,
  subTotal: PropTypes.number,
  user: PropTypes.object,
  discountTotal: PropTypes.number,
  total: PropTypes.number,
  deliveryCost: PropTypes.number,
  shippingTotal: PropTypes.number,
  onCartRemove: PropTypes.func
}

export default CartItems
