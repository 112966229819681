import React from 'react';

const Carousel = () => {  
  return (
    <div id="homeCarousel" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {window.carouselData.map((_, index) => (
          <li
            key={index}
            data-target="#homeCarousel"
            data-slide-to={index}
            className={index === 0 ? 'active' : ''}
          />
        ))}
      </ol>
      <div className="carousel-inner">
        {window.carouselData.map((slide, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            {slide.url ? (
              <a href={slide.url}>
                <img className="d-block w-100" src={slide.image_url} alt={`Slide ${index + 1}`} />
              </a>
            ) : (
              <img className="d-block w-100" src={slide.image_url} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>
      <a className="carousel-control-prev" href="#homeCarousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"/>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#homeCarousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"/>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default Carousel;
