import * as Types from '../constants/ActionTypes'
import PublishersAPI from '../services/publishers_api'

export const fetchPublishers = () => {
  return dispatch => {
    PublishersAPI.fetch(
      data => {
        dispatch(receivePublishers(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receivePublishers = publishers => {
  return {
    type: Types.RECEIVE_PUBLISHERS,
    publishers: publishers
  }
}
