import API from './api'

const CategoriesAPI = {
  fetch(onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/categories`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default CategoriesAPI
