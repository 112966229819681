import React from 'react'
import PropTypes from 'prop-types'

import times from 'lodash/times'

class QuantityOptions extends React.Component {
  static propTypes = {
    count: PropTypes.number,
    reservation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  }

  render() {
    const count = this.props.count || 3

    return (
      <select
        className='custom-select'
        value={this.props.reservation.quantity}
        onChange={e =>
          this.props.onChange(this.props.reservation, {
            quantity: e.target.value
          })
        }
      >
        {times(count, i => {
          return (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          )
        })}
      </select>
    )
  }
}

export default QuantityOptions
