/* eslint-disable react/prop-types */

import React from 'react'
import { Provider } from 'react-redux'

import ReservationManagementContainer from './ReservationManagementContainer'
import DevTools from '../../containers/DevTools'

class AdminReservationManagementRoot extends React.Component {
  render() {
    const { store, env } = this.props

    return (
      <Provider store={store}>
        <div>
          <ReservationManagementContainer />
          {env === 'development' ? <DevTools /> : ''}
        </div>
      </Provider>
    )
  }
}

export default AdminReservationManagementRoot
