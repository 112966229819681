import React from 'react'
import PropTypes from 'prop-types'
import InputSelect from '../../AdminProductItem/components/InputSelect'

const BranchSection = ({ branch, handleChange }) => {
  return (
    <div className='form-group'>
      <InputSelect
        label='Branch'
        name='branch'
        value={branch}
        onChange={handleChange}
        placeholder='Select Branch'
        options={[
          { name: 'Robinsons Galleria', value: 'Robinsons Galleria' }
        ]}
      />
      <hr />
      <p>
        <strong>
          For store pick up, items may take a few days to arrive at the store.
          You will be informed by the staff once items are ready for pick up.
          You may also pay online to expedite your pick up.
        </strong>
      </p>
    </div>
  )
}

BranchSection.propTypes = {
  branch: PropTypes.string,
  handleChange: PropTypes.func
}

export default BranchSection
