// @flow

import React, { Fragment } from 'react'
import type { Node } from 'react'

type propTypes = {
  children: Node
}

const ProductItemTable = (props: propTypes) => {
  const { children } = props
  return (
    <Fragment>
      <div className='table-responsive'>
        <table className='table table-striped table-hover reservations -grid'>
          <thead>
            <tr>
              <th className='w-25'>SKU</th>
              <th>Condition</th>
              <th>Description</th>
              <th>Price</th>
              <th>Location</th>
              <th>Available</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default ProductItemTable
