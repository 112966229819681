import API from './api'

const ReservationsAPI = {
  fetch(releaseId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservations`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  create(releaseId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservations`,
      type: 'POST',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  createReservationListReservation(
    reservationListId,
    data,
    onSuccess,
    onError,
    onComplete
  ) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/reservation_lists/${reservationListId}/reservations`,
      type: 'POST',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  update(releaseId, reservationId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservations/${reservationId}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  updateReservationListReservation(
    reservationListId,
    reservationId,
    data,
    onSuccess,
    onError,
    onComplete
  ) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/reservation_lists/${reservationListId}/reservations/${reservationId}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  destroy(releaseId, reservationId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservations/${reservationId}`,
      type: 'DELETE',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default ReservationsAPI
