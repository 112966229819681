import React, { Fragment, Component } from 'react'

import AdminProductItemContainer from './AdminProductItemContainer'
import { fetchCategories, fetchPublishers } from '../apiWrappers'
import Alert from '../components/Alert'

const messages = {
  general: 'Something went wrong. Please try again.'
}

export default class AdminProductItemMount extends Component {
  state = {
    publishers: [],
    categories: [],
    error: false
  }

  componentDidCatch(_error, info) {
    this.setState({ error: true })
    console.error(info)
  }

  async componentDidMount() {
    try {
      const publishers = await fetchPublishers()
      const categories = await fetchCategories()
      this.setState({
        publishers: publishers.data,
        categories: categories.data
      })
    } catch (err) {
      this.setState({ error: true })
      console.error(err)
    }
  }

  render() {
    const { publishers, categories, error } = this.state
    return (
      <Fragment>
        {error && (
          <Fragment>
            <Alert type='error' message={messages.general} />
            <hr />
          </Fragment>
        )}
        <AdminProductItemContainer
          publishers={publishers}
          categories={categories}
        />
      </Fragment>
    )
  }
}
