import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const PaymentTypeDescription = ({ paymentType }) => {
  return (
    <Fragment>
      {paymentType === 'paypal' && (
        <div className='form-group'>
          <h4 className='mb-3'>Pay using PayPal or Credit Card</h4>
        </div>
      )}
      {paymentType === 'pay_at_store' && (
        <div className='form-group'>
          <h4>Pay At Store</h4>
        </div>
      )}
      {paymentType === 'cod' && (
        <div className='form-group'>
          <h4>Cash on Delivery</h4>
        </div>
      )}
      {paymentType === 'bank_deposit' && (
        <div className='form-group'>
          <h4>Instructions to pay via bank</h4>
          <p>
            Deposit the total order amount to any of these bank accounts near
            you then upload a picture of the deposit slip with the form below,
            using the following details:
          </p>
          <div className='row'>
            <div className='col-6'>
              <strong>Bank: BPI</strong>
              <br />
              Account Name: Comic Odyssey, Inc.
              <br />
              Account Number: 2601-0259-41
            </div>
            <div className='col-6'>
              <strong>Bank: BDO</strong>
              <br />
              Account Name: Ma. Rowena Sansolis
              <br />
              Account Number: 0034-1802-0926
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

PaymentTypeDescription.propTypes = {
  paymentType: PropTypes.string
}

export default PaymentTypeDescription
