import * as Types from './actionTypes'
import { ReleaseProductsAPI } from './apiWrappers'

export const fetchProducts = releaseId => {
  return dispatch => {
    ReleaseProductsAPI.fetch(
      releaseId,
      data => {
        dispatch(receiveProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const updateProduct = (releaseId, productId, data) => {
  let productData = { product: data }
  return dispatch => {
    ReleaseProductsAPI.update(
      releaseId,
      productId,
      productData,
      data => {
        dispatch(receiveProduct(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const uploadCover = (releaseId, productId, file) => {
  let formData = new FormData()
  formData.append('product[cover]', file)

  NProgress.start()

  return dispatch => {
    ReleaseProductsAPI.uploadCover(
      releaseId,
      productId,
      formData,
      data => {
        dispatch(receiveproduct(data))
      },
      error => {
        console.error(error)
      },
      () => {
        NProgress.done()
      }
    )
  }
}

export const destroyProduct = (releaseId, productId) => {
  return dispatch => {
    ReleaseProductsAPI.destroy(
      releaseId,
      productId,
      data => {
        dispatch(removeProduct(productId))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const setReleaseId = releaseId => {
  return dispatch => {
    dispatch(receiveReleaseId(releaseId))
  }
}

// --------------------------------------------------

const receiveReleaseId = id => {
  return {
    type: Types.RECEIVE_RELEASE_ID,
    releaseId: id
  }
}

const removeProduct = productId => {
  return {
    type: Types.REMOVE_PRODUCT,
    productId: productId
  }
}

const receiveProduct = product => {
  return {
    type: Types.RECEIVE_PRODUCT,
    product: product
  }
}

const receiveProducts = products => {
  return {
    type: Types.RECEIVE_PRODUCTS,
    products: products
  }
}
