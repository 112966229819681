import * as Types from '../constants/ActionTypes'
import ReleasesAPI from '../services/releases_api'
import find from 'lodash/find'

import { fetchProducts } from './products'
import { fetchReservations } from './reservations'
import { fetchReservationLists } from './reservation_lists'
import { changeId } from '../utilities/urlHelpers'

export function fetchReleases(currentReleaseId = null) {
  return dispatch => {
    ReleasesAPI.fetch(
      data => {
        let releases = data
        const selectedRelease = find(releases, { id: currentReleaseId })
        dispatch(receiveReleases(releases))
        selectedRelease && dispatch(receiveRelease(selectedRelease))
        if (releases.length > 0) {
          let releaseId = selectedRelease
            ? selectedRelease.id
            : releases[releases.length - 1].id
          dispatch(fetchProducts(releaseId))
          dispatch(fetchReservations(releaseId))
        }
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function fetchRelease(releaseId) {
  return dispatch => {
    ReleasesAPI.fetchOne(
      releaseId,
      data => {
        dispatch(fetchProducts(releaseId))
        dispatch(receiveRelease(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function switchRelease(id) {
  return (dispatch, getState) => {
    const releases = getState().releases.releases
    const selected = find(releases, { id: id })
    const selectedRelease = getState().releases.selectedRelease

    changeId(getState().releases.selectedRelease.id, id)
    dispatch(switchReleases(releases, selected))
    dispatch(fetchReservations(selected.id))
    dispatch(fetchProducts(selected.id))
    dispatch(fetchReservationLists(selected.id, { type: 'current_user' }))
  }
}

export const switchLayout = grid => {
  return dispatch => {
    dispatch(receiveLayout(grid))
  }
}

export const switchLoading = state => {
  return dispatch => {
    dispatch(receiveLoading(state))
  }
}

const receiveLoading = state => {
  return {
    type: Types.RECEIVE_LOADING,
    loading: state
  }
}

const receiveLayout = grid => {
  return {
    type: Types.RECEIVE_LAYOUT,
    layout: grid
  }
}

function receiveReleases(releases) {
  return {
    type: Types.RECEIVE_RELEASES,
    releases: releases
  }
}

function receiveRelease(release) {
  return {
    type: Types.REFRESH_RELEASE,
    selectedRelease: release
  }
}

function switchReleases(releases, selected) {
  return {
    type: Types.SWITCH_RELEASES,
    releases: releases,
    newRelease: selected
  }
}
