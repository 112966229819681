import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'

import {
  updateReservation,
  updateReservationList,
  searchProduct,
  createReservation
} from '../actions'
import ReservationListInfo from '../components/ReservationListInfo'
import ReservationListTable from '../components/ReservationListTable'

class ReservationListSection extends React.Component {
  static propTypes = {
    updateReservation: PropTypes.func.isRequired,
    searchProduct: PropTypes.func.isRequired,
    updateReservationList: PropTypes.func.isRequired,
    createReservation: PropTypes.func.isRequired,
    reservation_list: PropTypes.object.isRequired,
    reservationListId: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    currentFilters: PropTypes.object.isRequired,
    searchResults: PropTypes.array
  }

  toggleFulfill = reservation => {
    let updatedReservation
    let reservationIndex
    let updatedReservations = [...this.props.reservation_list.reservations]

    if (reservation.status === 'for_approval') {
      updatedReservation = { ...reservation, status: 'short' }
    } else {
      updatedReservation = { ...reservation, status: 'for_approval' }
    }
    reservationIndex = findIndex(updatedReservations, { id: reservation.id })
    updatedReservations[reservationIndex] = updatedReservation
    this.props.updateReservation(
      this.props.reservationListId,
      updatedReservation
    )
  }

  toggleChecked = reservation_list => {
    let updatedReservationList

    if (reservation_list.status === 'open' || reservation_list.status === 'partially_approved') {
      updatedReservationList = { ...reservation_list, status: 'checked' }
    } else if (reservation_list.status === 'checked') {
      updatedReservationList = { ...reservation_list, status: 'open' }
    }
    this.updateReservationList(
      updatedReservationList.id,
      updatedReservationList
    )
  }

  updateReservationList = (reservationListId, reservation_list) => {
    let newReservationList = {
      ...this.props.reservation_list,
      ...reservation_list
    }

    this.props.updateReservationList(reservationListId, newReservationList, {
      ...this.props.currentFilters,
      page: this.props.page
    })
  }

  render() {
    return (
      <div>
        <ReservationListInfo
          reservation_list={this.props.reservation_list}
          updateReservationList={this.props.updateReservationList}
        />
        <ReservationListTable
          reservationList={this.props.reservation_list}
          toggleFulfill={this.toggleFulfill}
          toggleChecked={this.toggleChecked}
          updateReservationList={this.updateReservationList}
          updateReservation={this.props.updateReservation}
          createReservation={this.props.createReservation}
          searchProduct={this.props.searchProduct}
          searchResults={this.props.searchResults}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    release: state.reservation_management.release,
    reservation_list: find(
      state.reservation_management.results.reservation_lists,
      { id: ownProps.reservationListId }
    ),
    searchResults: state.reservation_management.searchResults,
    page: state.reservation_management.results.page
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateReservation: (reservationListId, reservation) => {
      dispatch(
        updateReservation(reservationListId, reservation.id, reservation)
      )
    },
    updateReservationList: (
      reservationListId,
      reservation_list,
      currentFilters = {}
    ) => {
      dispatch(
        updateReservationList(
          reservationListId,
          reservation_list,
          currentFilters
        )
      )
    },
    searchProduct: (releaseId, query) => {
      dispatch(searchProduct(releaseId, query))
    },
    createReservation: (releaseListId, reservation) => {
      dispatch(createReservation(releaseListId, reservation))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationListSection)
