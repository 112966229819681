import API from '../services/api'

export const ProductsAPI = {
  fetch(page, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/products`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  fetchOne(id, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/products/${id}`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  query(data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/products/search`,
      headers: API.authorizationHeaders(),
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  filter(data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/products/filter`,
      headers: API.authorizationHeaders(),
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}
