import React from 'react'
import PropTypes from 'prop-types'

class SearchBar extends React.Component {
  static propTypes = {
    search: PropTypes.func.isRequired,
    searchKey: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
  }

  submit = e => {
    e.preventDefault()
    console.log(this.props)
    this.props.search({ [this.props.searchKey]: this.search.value })
  }

  render() {
    return (
      <div className='reservations-search'>
        <div className='form-group form-group-lg has-feedback'>
          <div className='input-group brand-switcher'>
            <form className='form-inline' onSubmit={this.submit}>
              <span className='icon ion-ios-search form-control-feedback' />
              <input
                type='text'
                className='form-input'
                placeholder={this.props.placeholder}
                ref={el => {
                  this.search = el
                }}
              />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchBar
