import * as Types from './actionTypes'
import { ReleasesAPI } from './apiWrappers'

export const fetchReleasesIndex = (page = 1) => {
  return dispatch => {
    ReleasesAPI.fetch(
      page,
      data => {
        dispatch(receiveReleases(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const updateRelease = (releaseId, data) => {
  let releaseData = { release: data }
  return dispatch => {
    ReleasesAPI.update(
      releaseId,
      releaseData,
      data => {
        dispatch(receiveRelease(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receiveReleases = releases => {
  return {
    type: Types.RECEIVE_RELEASES,
    releases: releases
  }
}

const receiveRelease = release => {
  return {
    type: Types.RECEIVE_RELEASE,
    release: release
  }
}
