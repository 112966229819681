import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import find from 'lodash/find'
import c from 'classnames'

import { switchRelease } from '../actions/releases'
import { confirmReservation } from '../actions/reservations'

const SelectDate = props => {
  const cleanupTitle = title => {
    return title.replace('Arriving at Comic Odyssey on ', '')
  }

  return (
    <div className='selection'>
      {props.items
        .slice()
        .reverse()
        .map((item, index) => {
          return (
            <a
              href='javascript:void(0)'
              key={item.id}
              className={c({
                link: true,
                active: item.id === props.selectedRelease.id
              })}
              onClick={() => props.onClickAction(item.id)}
            >
              {cleanupTitle(item.title)}
            </a>
          )
        })}
    </div>
  )
}

class ReleaseContainer extends Component {
  componentDidMount() {
    const navbar = $("[role='release-mount']")

    $(window).scroll(() => {
      if ($(window).scrollTop() < 52) {
        navbar.removeClass('-sticky')
      } else {
        navbar.addClass('-sticky')
      }
    })
  }

  renderConfirmationLink() {
    const { reservations, hasReservationList } = this.props
    const { onConfirmReservation } = this.props
    let btnClass

    if (hasReservationList) {
      btnClass = 'btn-danger'
    } else {
      btnClass = 'btn-primary'
    }

    return (
      <a
        href='javascript:void(0)'
        onClick={onConfirmReservation}
        className={`btn ${btnClass}`}
      >
        <small>
          <span className='badge'>
            {reservations === undefined ? '' : reservations.length}
          </span>
          &nbsp;
          {hasReservationList ? 'Cancel Reservation' : 'Confirm Reservation'}
        </small>
      </a>
    )
  }

  render() {
    const { releases, selectedRelease } = this.props
    const { onSwitchRelease } = this.props

    return (
      <div className='release-title'>
        <div className='container-fluid'>
          <div className='row justify-content-between title release-switcher'>
            <div className='col-md-10 content'>
              <span className='arriving'>Arriving On:</span>
              <SelectDate
                items={releases}
                selectedRelease={selectedRelease}
                onClickAction={onSwitchRelease}
              />
            </div>

            <div className='col-md-2 col-sm-12 reservation-confirmation'>
              {this.renderConfirmationLink()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { releases } = state
  const { orders } = state.orders

  return {
    releases: releases.releases,
    selectedRelease: releases.selectedRelease,
    reservations: releases.reservations,
    hasReservationList: find(state.reservation_lists.reservationLists, item => {
      return item.release_id === state.releases.selectedRelease.id
    })
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSwitchRelease: context => {
      dispatch(switchRelease(context))
    },
    onConfirmReservation: context => {
      dispatch(confirmReservation())
    }
  }
}

ReleaseContainer.propTypes = {
  releases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  selectedRelease: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseContainer)
