import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

InputText.propTypes = {
  autoFocus: PropTypes.bool,
  col: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.string,
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array
}

InputText.defaultProps = {
  onChange: () => {},
  required: false,
  type: 'text',
  value: '',
  errors: []
}

export default function InputText(props) {
  const {
    autoFocus,
    col,
    disabled,
    label,
    name,
    onChange,
    placeholder,
    type,
    required,
    maxLength,
    value,
    errors
  } = props
  const inputProps = {
    autoFocus,
    disabled,
    id: name,
    name,
    onChange,
    placeholder,
    type,
    maxLength,
    required,
    value: value || ''
  }
  const cn = classnames('form-group', { col: col })
  return (
    <div className={cn}>
      {label && <label htmlFor={name}>{label}</label>}
      <input className='form-control' {...inputProps} />
      {errors.map(error => (
        <div className='invalid-feedback' key={error}>
          {error}
        </div>
      ))}
    </div>
  )
}
