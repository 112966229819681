import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

InputSelect.propTypes = {
  col: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ])
}

InputSelect.defaultProps = {
  onChange: () => {},
  required: false,
  value: ''
}

export default function InputSelect(props) {
  const {
    col,
    label,
    name,
    placeholder,
    onChange,
    options,
    value,
    required
  } = props
  const inputProps = {
    id: name,
    name,
    onChange,
    value: value || '',
    required
  }
  const cn = classnames('form-group', { col: col })
  return (
    <div className={cn}>
      {label && <label htmlFor={name}>{label}</label>}
      <select className='form-control' {...inputProps}>
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option, i) => (
          <option key={i} value={option.value || option.id}>
            {option.name || option.title || option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
