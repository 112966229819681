import React, { Component } from 'react'
import numeral from 'numeral'

export default class ProductItem extends Component {
  render() {
    const { title, cover_url, publisher_name, price, id } = this.props.product
    const { onViewProduct } = this.props

    return (
      <div
        className={`product-item -with-reservation-trigger -small`}
        onClick={() => {
          onViewProduct(id)
        }}
      >
        <div className='product-details'>
          <img src={cover_url} className='cover' />
          <h4> {title} </h4>
          <div className='details'>
            <div className='subline'>{publisher_name}</div>
          </div>
        </div>
        <div className='product-actions' />
      </div>
    )
  }
}
