import toastr from 'toastr'

toastr.options = {
  closeButton: false,
  positionClass: 'toast-top-center',
  timeOut: '1000'
}

export const onError = e => {
  if (e.response.data && e.response.data.message) {
    return toastr.error(e.response.data.message)
  }
  if (Array.isArray(e.response.data)) {
    return toastr.error(e.response.data.join(', '))
  }
  return toastr.error(e)
}

export const serviceHandlers = (response, onSuccess, onStatusError) => {
  if (response.status >= 200 && response.status <= 299) {
    return onSuccess(response)
  }
  onStatusError(response)
}
