import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const addressOption = address => {
  return (
    <option
      key={address.id}
      value={address.full_address}
      data-region={address.region}
    >
      {' '}
      {address.full_address}
    </option>
  )
}

const CheckoutForm = ({
  isAdmin,
  attributes,
  updateAttribute,
  handleShippingSelect
}) => {
  return (
    <Fragment>
      <div className='form-group'>
        <label htmlFor='phone_number'> Phone Number </label>
        <input
          type='text'
          id='phone_number'
          name='phone_number'
          className='form-control'
          value={attributes.phone_number}
          onChange={e => updateAttribute(e, 'phone_number')}
          required
        />
      </div>
      {!isAdmin && (
        <div className='form-group'>
          <label htmlFor='payment_option'> Delivery Option </label>
          <select
            className='form-control'
            id='delivery_option'
            name='delivery_option'
            value={attributes.delivery_option}
            onChange={e => updateAttribute(e, 'delivery_option')}
            required
          >
            <option value=''> Select a Delivery Option </option>
            <option value='store'> Pickup at Store </option>
            <option value='shipping'> Ship </option>
          </select>
        </div>
      )}
      {attributes.delivery_option === 'store' && (
        <div className='form-group'>
          <label htmlFor='branch'> Branch </label>
          <select
            className='form-control'
            id='branch'
            name='branch'
            value={attributes.branch}
            onChange={e => updateAttribute(e, 'branch')}
            required
          >
            <option value=''> Select a Branch </option>
            <option value='Robinsons Galleria'> Robinsons Galleria </option>
          </select>
        </div>
      )}
      {attributes.delivery_option === 'shipping' && (
        <div>
          <div className='form-group'>
            <label htmlFor='shipping_address'>
              {' '}
              Select a shipping address{' '}
            </label>
            <input
              type='text'
              id='shipping_region'
              name='shipping_region'
              hidden
              value={attributes.shipping_region}
            />
            <select
              className='form-control'
              id='shipping_address'
              name='shipping_address'
              value={attributes.shipping_address}
              onChange={handleShippingSelect}
              required
            >
              <option value=''> Select an Address </option>
              {attributes.addresses.map(address => addressOption(address))}
            </select>
          </div>
          <div className='form-group'>
            <a href='/addresses/new' className='btn btn-primary'>
              Add a new address
            </a>
          </div>
        </div>
      )}
    </Fragment>
  )
}

CheckoutForm.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  attributes: PropTypes.object.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  handleShippingSelect: PropTypes.func.isRequired
}

export default CheckoutForm
