import API from './api'

const ReservationListsAPI = {
  fetch(data = {}, onSuccess, onError, onComplete = _ => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/reservation_lists`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  show(reservationListId, onSuccess, onError, onComplete = _ => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/reservation_lists/${reservationListId}`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  fetchReleaseReservationLists(
    releaseId,
    data = {},
    onSuccess,
    onError,
    onComplete = _ => {}
  ) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservation_lists`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  create(releaseId, data, onSuccess, onError, onComplete = _ => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservation_lists`,
      type: 'POST',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  update(id, data, onSuccess, onError, onComplete = _ => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/reservation_lists/${id}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  destroyReleaseReservationList(
    releaseId,
    id,
    onSuccess,
    onError,
    onComplete = _ => {}
  ) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/reservation_lists/${id}`,
      type: 'DELETE',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default ReservationListsAPI
