import * as Types from '../constants/ActionTypes'
import ReservationsAPI from '../services/reservations_api'

export function fetchReservations(releaseId) {
  return dispatch => {
    ReservationsAPI.fetch(
      releaseId,
      data => {
        dispatch(receiveReservations(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function createReservation(releaseId, data) {
  return dispatch => {
    ReservationsAPI.create(
      releaseId,
      data,
      response => {
        dispatch(receiveReservation(Types.CREATE_RESERVATION, response))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function updateReservation(releaseId, reservationId, data) {
  return dispatch => {
    ReservationsAPI.update(
      releaseId,
      reservationId,
      data,
      response => {
        dispatch(receiveReservation(Types.UPDATE_RESERVATION, response))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function deleteReservation(releaseId, reservationId) {
  return dispatch => {
    ReservationsAPI.destroy(
      releaseId,
      reservationId,
      response => {
        dispatch(receiveReservation(Types.DELETE_RESERVATION, response))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export function confirmReservation() {
  return dispatch => {
    dispatch(reservationStatus(true))
  }
}

export function cancelReservation() {
  return dispatch => {
    dispatch(reservationStatus(false))
  }
}

function reservationStatus(bool) {
  return {
    type: Types.CONFIRM_RESERVATION,
    reserving: bool
  }
}

function receiveReservations(reservations) {
  return {
    type: Types.RECEIVE_RESERVATIONS,
    reservations: reservations
  }
}

function receiveReservation(type, reservation) {
  return {
    type: type,
    reservation: reservation
  }
}
