import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Notification extends Component {
  className() {
    return `alert alert-${this.props.level} react-alert`
  }

  render() {
    const { message, level } = this.props

    return (
      <div className={this.className()}>
        <div className='text-center'>{message}</div>
      </div>
    )
  }
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired
}
