// @flow

import React from 'react'
import classnames from 'classnames'

import type { CheckboxProps } from '../types'

export default function InputCheckbox(props: CheckboxProps) {
  const { autoFocus, disabled, label, name, onChange, inline, checked } = props
  const inputProps = {
    autoFocus,
    disabled,
    id: name,
    name,
    onChange,
    checked: [true, 'true'].includes(checked)
  }
  const cn = classnames('form-check', { 'form-check-inline': inline })

  return (
    <div className={cn}>
      <input type='checkbox' className='form-check-input' {...inputProps} />
      {label && (
        <label htmlFor={name} className='form-check-label'>
          {label}
        </label>
      )}
    </div>
  )
}
