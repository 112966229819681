import React from 'react'
import { render } from 'react-dom'
import { configureStore } from './stores/configureStore'

import ReservationRoot from './containers/reservations/ReservationRoot'
import ReleaseContainer from './containers/ReleaseContainer'
import AdminProductsRoot from './AdminProducts/containers/AdminProductsRoot'
import AdminReleasesRoot from './AdminReleases/containers/AdminReleasesRoot'
import AdminProductItemMount from './AdminProductItem/containers/AdminProductItemMount'
import MarketplaceRoot from './Marketplace/containers/MarketplaceRoot'
import AdminReservationManagementRoot from './AdminReservationManagement/containers'
import AdminShortagesRoot from './AdminShortages/containers'
import ReservationBoxMount from './ReservationBox/containers/ReservationBoxMount'
import AddressesContainer from './Addresses/containers/AddressesContainer'
import CartBoxMount from './Cart/containers/CartBoxMount'
import Carousel from './Marketplace/components/Carousel'

import { fetchReleasesIndex } from './AdminReleases/actions'
import { fetchProducts, setReleaseId } from './AdminProducts/actions'
import { fetchProductsIndex } from './Marketplace/actions'
import { fetchReleases } from './actions/releases'
import { fetchPublishers } from './actions/publishers'
import { fetchCategories } from './actions/categories'
import { fetchCurrentUser } from './actions/users'
import { fetchReservationLists } from './actions/reservation_lists'
import { initNotifications } from './actions/notifications'

import { resetStore } from './actions/shared'

const reactEnv = () => {
  return window.location.hostname === 'localhost' ? 'development' : 'production'
}
const store = configureStore({}, reactEnv())

const App = {}

App.reservationMount = () => {
  const elem = $("[role='reservation-mount']")[0]

  store.dispatch(resetStore())
  store.dispatch(initNotifications())
  store.dispatch(fetchCurrentUser())
  store.dispatch(fetchReleases($(elem).data().releaseId))
  store.dispatch(
    fetchReservationLists($(elem).data().releaseId, { type: 'current_user' })
  )

  render(<ReservationRoot store={store} env={reactEnv()} />, elem)
}

App.releaseMount = () => {
  const elems = $("[role='release-mount']")
  elems.each((i, elem) => {
    render(<ReleaseContainer store={store} env={reactEnv()} />, elem)
  })
}

App.adminProductsMount = () => {
  const elem = $("[role='admin-products-mount']")[0]

  store.dispatch(fetchPublishers())
  store.dispatch(fetchCategories())
  store.dispatch(setReleaseId($(elem).attr('data-release-id')))
  store.dispatch(fetchProducts($(elem).attr('data-release-id')))

  render(<AdminProductsRoot store={store} env={reactEnv()} />, elem)
}

App.adminReleasesMount = () => {
  const elem = $("[role='admin-releases-mount']")[0]

  store.dispatch(fetchReleasesIndex())

  render(<AdminReleasesRoot store={store} env={reactEnv()} />, elem)
}

App.adminProductItemMount = () => {
  const elem = $("[role='admin-product-mount']")[0]

  render(<AdminProductItemMount />, elem)
}

App.marketplace = () => {
  const elem = $("[role='marketplace-mount']")[0]

  store.dispatch(fetchPublishers())
  store.dispatch(fetchCategories())
  store.dispatch(fetchProductsIndex())

  render(<MarketplaceRoot store={store} env={reactEnv()} />, elem)
}

App.adminReservationManagementMount = target => {
  const elem = $(target)[0]
  render(
    <AdminReservationManagementRoot store={store} env={reactEnv()} />,
    elem
  )
}

App.adminShortagesMount = target => {
  const elem = $(target)[0]
  render(<AdminShortagesRoot store={store} env={reactEnv()} />, elem)
}

App.reservationBox = target => {
  const elem = $(target)[0]
  const user = JSON.parse(elem.getAttribute('data-user'))
  const currentUser = JSON.parse(elem.getAttribute('data-current-user'))
  render(<ReservationBoxMount user={user} currentUser={currentUser} />, elem)
}

App.addressesMount = target => {
  const elem = $(target)[0]
  const user = JSON.parse(elem.getAttribute('data-user'))
  const address = JSON.parse(elem.getAttribute('data-address'))
  render(<AddressesContainer user={user} address={address} />, elem)
}

App.cartBoxMount = target => {
  const elem = $(target)[0]
  const user = JSON.parse(elem.getAttribute('data-user'))
  render(<CartBoxMount user={user} />, elem)
}

App.carouselMount = target => {
  const elem = $(target)[0];
  render(<Carousel />, elem);
};

export default App
