import { RECEIVE_RELEASES, RECEIVE_RELEASE } from './actionTypes'

const initialState = {
  releases: [],
  currentPage: 1
}

const release_reducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_RELEASE:
      if (state.id !== action.release.id) {
        return state
      }
      return { ...state, status: action.release.status }
    default:
      return state
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RELEASES:
      return {
        ...state,
        releases: state.releases.concat(action.releases),
        currentPage: state.currentPage + 1
      }
    case RECEIVE_RELEASE:
      return {
        ...state,
        releases: state.releases.map(release => {
          return release_reducer(release, action)
        })
      }
    default:
      return state
  }
}

export default reducer
