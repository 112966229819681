import React from 'react'
import PropTypes from 'prop-types'

import c from 'classnames'

const PaymentFormButton = ({ form, disableButton }) => {
  const { transaction_source } = form
  const isPaypal = transaction_source === 'paypal'

  return (
    <div className='form-actions'>
      <button
        type='submit'
        className={c({
          btn: true,
          'btn-paypal -mini': isPaypal,
          'btn-primary': !isPaypal
        })}
        disabled={disableButton}
      >
        {isPaypal ? (
          <div className='logo p-3'>
            <i>Pay</i>
            <i>Pal</i>
          </div>
        ) : (
          'Place Order'
        )}
      </button>
    </div>
  )
}

PaymentFormButton.propTypes = {
  form: PropTypes.object,
  disableButton: PropTypes.bool
}

export default PaymentFormButton
