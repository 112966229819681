import API from './api'

const OrdersAPI = {
  fetch(onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/orders`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  fetchReleaseOrders(releaseId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/orders`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  create(userId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/users/${userId}/orders`,
      type: 'POST',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  update(id, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/orders/${id}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },

  deleteOrder(id, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/orders/${id}`,
      type: 'DELETE',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default OrdersAPI
