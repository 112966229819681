import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class BlankSlate extends Component {
  render() {
    const { message, redirectTo } = this.props

    return (
      <a href={redirectTo || '#'}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='alert alert-info'>{message}</div>
          </div>
        </div>
      </a>
    )
  }
}

BlankSlate.propTypes = {
  message: PropTypes.string.isRequired
}
