import React, { Component } from 'react'

export default class ProductTable extends Component {
  render() {
    const { children, releaseId } = this.props

    return (
      <table className='table issues -admin'>
        <thead>
          <tr>
            <th>Cover</th>
            <th className='-highlight'>Product</th>
            <th className='-sm'>Price</th>
            <th>Publisher</th>
            <th>Category</th>
            <th className='-sm'> Cover Price </th>
            <th className='-sm' hidden={releaseId === undefined}>
              Orders
            </th>
            <th hidden={releaseId === undefined}>Reserved</th>
            <th hidden={releaseId === undefined}>Featured</th>
            <th hidden={releaseId === undefined} />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    )
  }
}
