import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ProductList extends Component {
  renderGrid() {
    const { children } = this.props

    return <div className='product-grid'>{children}</div>
  }

  renderList() {
    const { children } = this.props

    return (
      <div className='table-responsive'>
        <table className='table table-striped table-hover reservations -grid'>
          <thead>
            <tr>
              <th />
              <th>Cover</th>
              <th>Issue</th>
              <th>Price</th>
              <th className='hidden-640'>Publisher</th>
              <th className='hidden-640'>Category</th>
              <th className='hidden-640'>Quantity</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    )
  }

  render() {
    const { view } = this.props

    return view === 'grid' ? this.renderGrid() : this.renderList()
  }
}

ProductList.propTypes = {
  children: PropTypes.node.isRequired,
  view: PropTypes.string
}
