import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from '../../presenters/format'
import { ProductQuantity } from './ProductQuantity'
import { ProductItemDescription } from './ProductItemDescription'

const ProductRow = ({ cartItem, product, price, onCartRemove }) => {
  const handleRemove = e => {
    e.preventDefault()
    onCartRemove(cartItem.id)
  }

  return (
    <tr>
      <td>
        <a href={'/product/' + product.slug}>{product.title}</a>
        <ProductItemDescription cartItem={cartItem} />
      </td>
      <ProductQuantity cartItem={cartItem} />
      <td>{formatCurrency(price)}</td>
      {onCartRemove && (
        <td>
          <button onClick={handleRemove}>
            <div className='icon ion-trash-a' />
          </button>
        </td>
      )}
    </tr>
  )
}

ProductRow.propTypes = {
  cartItem: PropTypes.object,
  product: PropTypes.object,
  price: PropTypes.string,
  onCartRemove: PropTypes.func
}

export default ProductRow
