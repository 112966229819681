import React, { Component } from 'react'
import { connect } from 'react-redux'

import swal from 'sweetalert2'

import BlankSlate from '../../components/shared/BlankSlate'
import ReleaseItem from '../components/ReleaseItem'
import LoadMoreButton from '../../components/shared/LoadMoreButton'

import { ReleasesAPI } from '../apiWrappers'
import { fetchReleasesIndex, updateRelease } from '../actions'
import SweetAlert from '../../components/shared/SweetAlert'

class AdminReleasesContainer extends Component {
  onCloseRelease = releaseId => {
    const { onUpdateRelease } = this.props

    ReleasesAPI.unconfirmedUsers(
      releaseId,
      response => {
        if (response.length === 0) {
          return onUpdateRelease(releaseId, { status: 'close' })
        }

        const names = response.map(
          user => `${user.first_name} ${user.last_name}`
        )

        SweetAlert.prompt(
          'Yes',
          'Are you sure you want to close this release?',
          {
            html: `
              <div class='content -sm'>
                <strong>Users with unconfirmed reservations:</strong>
                <p>${names.join(', ')}</p>
              </div>
            `
          }
        ).then(_ => onUpdateRelease(releaseId, { status: 'close' }))
      },
      error => {
        console.log(error)
      }
    )
  }

  renderReleases() {
    const { onCloseRelease } = this
    const { releases, onUpdateRelease } = this.props

    return releases.map((release, index) => {
      return (
        <ReleaseItem
          key={index}
          release={release}
          onUpdateRelease={onUpdateRelease}
          onCloseRelease={onCloseRelease}
        />
      )
    })
  }

  render() {
    const { releases, currentPage } = this.props
    const { onLoadMore } = this.props

    return (
      <div>
        {releases.length === 0 ? (
          <BlankSlate message='You have no releases yet.' />
        ) : (
          <div>
            {this.renderReleases()}
            <LoadMoreButton
              onLoadMore={() => {
                onLoadMore(currentPage)
              }}
              message='Load more previous releases'
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { releases, currentPage } = state.admin_releases

  return {
    releases: releases,
    currentPage: currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateRelease: (releaseId, context) => {
      dispatch(updateRelease(releaseId, context))
    },
    onLoadMore: context => {
      dispatch(fetchReleasesIndex(context))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReleasesContainer)
