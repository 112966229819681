import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ProductImage extends Component {
  closeModal() {
    $('.special-modal').modal('hide')
  }

  renderDescriptionWithBreaklines(text) {
    return text.split('\n').map(item => {
      return (
        <span>
          {item}
          <br />
        </span>
      )
    })
  }

  render() {
    const {
      image,
      description,
      title,
      formatted_price,
      creators,
      publisher_name
    } = this.props

    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='product-blown-up-image'>
            <div>
              <a
                href={image}
                target='_blank'
                className='btn btn-primary btn-sm'
              >
                Download Cover
              </a>
              <span>&nbsp;</span>
              <a
                onClick={this.closeModal}
                aria-label='Close'
                className='btn btn-default btn-sm'
              >
                Close
              </a>
            </div>
            <hr />
            <img src={image} />
            <h4> {title} </h4>
            <strong> {formatted_price} </strong>
            <p>
              {publisher_name}
              &nbsp; | &nbsp;
              {creators}
            </p>
            <hr />
            <p>{this.renderDescriptionWithBreaklines(description)}</p>
            <p>Disclaimer: Images may not be the actual cover.</p>

            <div>
              <a
                href={image}
                target='_blank'
                className='btn btn-primary btn-sm'
              >
                Download Cover
              </a>
              <span>&nbsp;</span>
              <a
                onClick={this.closeModal}
                aria-label='Close'
                className='btn btn-default btn-sm'
              >
                Close
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProductImage.PropTypes = {
  image: PropTypes.string.isRequired
}
