import API from './api'

const ProductsAPI = {
  fetch(releaseId, page, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products?page=${page}`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  query(releaseId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products/search`,
      headers: API.authorizationHeaders(),
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  generalQuery(data, onSuccess, onError, onComplete = _ => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/products/search`,
      headers: API.authorizationHeaders(),
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default ProductsAPI
