import React from 'react'
import PropTypes from 'prop-types'

import {
  calculateShippingFee,
  calculateDiscount
} from '../../utilities/summaryCalculations'
import { formatCurrency } from '../../presenters/format'
import { fetchPrice, fetchQuantity } from '../utilities'

const CheckoutTable = ({
  items,
  user,
  children,
  deliveryOption,
  shippingRegion
}) => {
  let rows = []
  let totalPrice = 0.0
  let quantity = 0

  for (let item of items) {
    quantity += fetchQuantity(item)
    totalPrice += parseFloat(fetchPrice(item) * fetchQuantity(item))
  }

  const discountRate = +user.discount_rate
  const discountedAmount = calculateDiscount(discountRate, totalPrice)
  const shippingFee = calculateShippingFee(shippingRegion)
  totalPrice = totalPrice - discountedAmount
  totalPrice = totalPrice + shippingFee

  if (discountRate > 0) {
    rows.push(
      <tr key='discount'>
        <td>{discountRate}% Discount</td>
        <td />
        <td>{formatCurrency(discountedAmount)}</td>
      </tr>
    )
  }

  if (deliveryOption === 'shipping' && shippingFee > 0) {
    rows.push(
      <tr key='shipping'>
        <td>Shipping</td>
        <td />
        <td>{formatCurrency(shippingFee)}</td>
      </tr>
    )
  }

  rows.push(
    <tr className='total' key='total'>
      <td>Total</td>
      <td>{quantity}</td>
      <td>
        <strong>{formatCurrency(totalPrice)}</strong>
      </td>
      <td />
    </tr>
  )

  return (
    <table className='table table-striped orders'>
      <tbody>
        {children}
        {rows}
      </tbody>
    </table>
  )
}

CheckoutTable.propTypes = {
  items: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  deliveryOption: PropTypes.string,
  shippingRegion: PropTypes.string
}

export default CheckoutTable
