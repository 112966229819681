import React from 'react'
import PropTypes from 'prop-types'

import Paginate from '../../components/Paginate'
import ReservationRow from './ReservationRow'
import LoadingScreen from '../../components/shared/LoadingScreen'

class ReservationTable extends React.Component {
  static propTypes = {
    current_page: PropTypes.number.isRequired,
    total_pages: PropTypes.number.isRequired,
    reservations: PropTypes.array.isRequired,
    disable: PropTypes.bool.isRequired,
    changePage: PropTypes.func.isRequired,
    searchProducts: PropTypes.func.isRequired,
    updateReservation: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    requesterId: PropTypes.number
  }

  changePage = page => {
    this.props.changePage(page)
  }

  render() {
    if (this.props.disable) {
      return <LoadingScreen />
    }
    return (
      <div>
        <table className='table table-bordered table-condensed'>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Product Title</th>
              <th>Quantity</th>
              <th style={{ width: '22.5%' }}>Fulfillment Option</th>
              <th style={{ width: '22.5%' }}>Customer</th>
              <th style={{ width: '15%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.reservations.map(reservation => {
              return (
                <ReservationRow
                  key={reservation.id}
                  reservation={reservation}
                  searchProducts={this.props.searchProducts}
                  searchResults={this.props.products}
                  requesterId={this.props.requesterId}
                  updateReservation={this.props.updateReservation}
                />
              )
            })}
          </tbody>
        </table>
        <Paginate
          current_page={this.props.current_page}
          total_pages={this.props.total_pages}
          changePage={this.changePage}
          disable={this.props.disable}
        />
      </div>
    )
  }
}

export default ReservationTable
