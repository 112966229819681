import * as Types from '../constants/ActionTypes'

export const initNotifications = () => {
  return dispatch => {
    dispatch(receiveEmptyNotifications())
  }
}

export const sendNotification = (message, level) => {
  return dispatch => {
    dispatch(receiveNotification(message, level))
  }
}

const receiveNotification = (message, level) => {
  return {
    type: Types.RECEIVE_NOTIFICATION,
    message: {
      message: message,
      level: level
    }
  }
}

const receiveEmptyNotifications = () => {
  return {
    type: Types.RECEIVE_EMPTY_NOTIFICATIONS,
    messages: []
  }
}
