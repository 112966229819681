import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import pathRegexp from 'path-to-regexp'
import isEmpty from 'lodash/isEmpty'

import {
  fetchRelease,
  fetchReservationLists,
  approveReleaseReservationLists
} from '../actions'
import ReservationListSection from '../containers/ReservationListSection'
import ControlPanel from '../components/ControlPanel'
import Paginate from '../../components/Paginate'
import LoadingScreen from '../../components/shared/LoadingScreen'

class ReservationManagementContainer extends React.Component {
  static propTypes = {
    fetchRelease: PropTypes.func.isRequired,
    fetchReservationLists: PropTypes.func.isRequired,
    approveReleaseReservationLists: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    total_pages: PropTypes.number.isRequired,
    reservation_lists: PropTypes.array.isRequired,
    results: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    const pathName = window.location.pathname
    const urlRegex = pathRegexp('/admin/releases/:id/reservations', [])
    if (urlRegex.test(pathName)) {
      this.state = {
        release_id: urlRegex.exec(pathName)[1],
        currentFilters: {}
      }
      props.fetchRelease(this.state.release_id)
      props.fetchReservationLists(this.state.release_id)
    } else {
      this.state = {}
    }
  }

  approveReleaseReservationLists = () => {
    this.props.approveReleaseReservationLists(
      this.state.release_id,
      this.state.currentFilters
    )
  }

  filterReservationLists = filters => {
    this.setState({
      currentFilters: filters
    })
    this.props.fetchReservationLists(this.state.release_id, filters)
  }

  changePage = page => {
    this.props.fetchReservationLists(this.state.release_id, {
      ...this.state.currentFilters,
      page
    })
  }

  render() {
    return (
      <div>
        <ControlPanel
          results={this.props.results}
          approveReleaseReservationLists={this.approveReleaseReservationLists}
          filterReservationLists={this.filterReservationLists}
          loading={this.props.loading}
        />
        {this.props.loading && isEmpty(this.props.reservation_lists) && (
          <LoadingScreen />
        )}
        {!isEmpty(this.props.reservation_lists) &&
          this.props.reservation_lists.map(reservation_list => {
            return (
              <ReservationListSection
                key={reservation_list.id}
                reservationListId={reservation_list.id}
                currentFilters={this.state.currentFilters}
              />
            )
          })}
        {!isEmpty(this.props.reservation_lists) && (
          <Paginate
            current_page={this.props.page}
            total_pages={this.props.total_pages}
            changePage={this.changePage}
            disable={this.props.loading || this.props.total_pages === 1}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    results: state.reservation_management.results,
    reservation_lists: state.reservation_management.results.reservation_lists,
    page: state.reservation_management.results.page,
    total_pages: state.reservation_management.results.total_pages,
    loading: state.reservation_management.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRelease: releaseId => {
      dispatch(fetchRelease(releaseId))
    },
    fetchReservationLists: (releaseId, filters = {}) => {
      dispatch(fetchReservationLists(releaseId, filters))
    },
    approveReleaseReservationLists: (releaseId, filters = {}) => {
      dispatch(approveReleaseReservationLists(releaseId, filters))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationManagementContainer)
