import * as Types from '../constants/ActionTypes'
import { sendNotification } from './notifications'
import { cancelReservation } from './reservations'
import ReservationListsAPI from '../services/reservation_lists_api'

function collectReservationIds(reservations) {
  let reservationIds = []

  reservations.map(reservation => {
    reservationIds.push(reservation.id)
  })

  return reservationIds
}

function collectProductIds(reservations) {
  let productIds = []

  reservations.map(reservation => {
    productIds.push(reservation.product.id)
  })

  return productIds
}

function receiveReservationLists(reservationLists) {
  return {
    type: Types.RECEIVE_RESERVATION_LISTS,
    reservationLists: reservationLists
  }
}

export function fetchReservationLists(releaseId = null, options = {}) {
  return dispatch => {
    if (releaseId) {
      ReservationListsAPI.fetchReleaseReservationLists(
        releaseId,
        [],
        data => {
          dispatch(receiveReservationLists(data))
        },
        error => {
          console.error(error)
        },
        () => {}
      )
    } else {
      ReservationListsAPI.fetch(
        options,
        data => {
          dispatch(receiveReservationLists(data))
        },
        error => {
          console.error(error)
        },
        () => {}
      )
    }
  }
}

export function confirmReservations(data) {
  return (dispatch, getState) => {
    let state = getState()

    data['release_id'] = state.releases.selectedRelease.id
    data['reservation_ids'] = collectReservationIds(state.releases.reservations)
    data['product_ids'] = collectProductIds(state.releases.reservations)

    let reservationListData = { reservation_list: data }

    ReservationListsAPI.create(
      data.release_id,
      reservationListData,
      () => {
        dispatch(fetchReservationLists(data.release_id))
        dispatch(cancelReservation())
        dispatch(
          sendNotification(
            ' Successfully created your reservation for this release',
            'success'
          )
        )
      },
      error => {
        dispatch(
          sendNotification(
            'We encountered an error when reserving your items',
            'danger'
          )
        )
        console.error(error)
      }
    )
  }
}

function deleteCurrentReservationList() {
  return {
    type: Types.DELETE_RESERVATION_LIST
  }
}

export function cancelReservationList(reservationList) {
  return (dispatch, getState) => {
    let state = getState()

    const releaseId = state.releases.selectedRelease.id

    ReservationListsAPI.destroyReleaseReservationList(
      releaseId,
      reservationList.id,
      () => {
        dispatch(deleteCurrentReservationList())
        dispatch(fetchReservationLists(releaseId))
        dispatch(cancelReservation())
        dispatch(
          sendNotification(
            ' Successfully cancelled your reservation for this release',
            'success'
          )
        )
      },
      error => {
        dispatch(
          sendNotification(
            'We encountered an error when cancelling your reservations',
            'danger'
          )
        )
        console.error(error)
      }
    )
  }
}
