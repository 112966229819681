import API from '../services/api'

export const ReleaseProductsAPI = {
  fetch(releaseId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products?type=all`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  uploadCover(releaseId, productId, formData, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products/${productId}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      aysnc: true,
      data: formData,
      cache: false,
      processData: false,
      contentType: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  update(releaseId, productId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products/${productId}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  destroy(releaseId, productId, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/products/${productId}`,
      type: 'DELETE',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}
