import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import sortBy from 'lodash/sortBy'
import Loading from 'react-loading'
import { debounce } from 'throttle-debounce'

import { mapReservationStatus } from '../../presenters/humanize'

class BoxFilters extends React.Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    onFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
  }

  state = this.props.filters

  onChange = e => {
    e.preventDefault()

    const newState = {
      ...this.state,
      [e.target.name]: e.target.value
    }
    const isFill = newState.status === 'fill'
    newState.no_order_id = !isFill ? '' : isFill

    this.setState(newState)
    this.onFilter(newState)
  }

  onFilter = debounce(300, newState => {
    this.props.onFilter(newState)
  })

  render() {
    const formattedStatuses = mapReservationStatus(this.props.statuses, {
      mergeShort: true
    })

    return (
      <Fragment>
        <form
          className='box-filter form-inline'
          onSubmit={e => e.preventDefault()}
        >
          <div className='input-wrapper'>
            <input
              className='form-control search'
              name='product'
              placeholder='Search'
              value={this.state.product}
              onChange={this.onChange}
            />
            <i className='ion ion-ios-search-strong' />
          </div>
          <select
            name='category_id'
            className='form-control'
            value={this.state.category_id}
            onChange={this.onChange}
          >
            <option value=''>All Categories</option>
            {sortBy(this.props.categories, ['name']).map(c => (
              <option key={c.id} name='category_id' value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <select
            name='status'
            className='form-control'
            value={this.state.status}
            onChange={this.onChange}
          >
            {sortBy(formattedStatuses, 'name').map(s => (
              <option key={s.value} name='status' value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </form>
        {this.props.loading && (
          <div className='loading-screen'>
            <Loading type='bars' color='#7F8C8D' height='50px' width='50px' />
          </div>
        )}
      </Fragment>
    )
  }
}

export default BoxFilters
