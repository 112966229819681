import React from 'react'

import PropTypes from 'prop-types'
import reject from 'lodash/reject'
import isEqual from 'lodash/isEqual'

import NewReservationRow from './NewReservationRow'
import ReservationRow from './ReservationRow'
import TableFooter from './TableFooter'

class ReservationListTable extends React.Component {
  static propTypes = {
    toggleFulfill: PropTypes.func.isRequired,
    toggleChecked: PropTypes.func.isRequired,
    updateReservationList: PropTypes.func.isRequired,
    updateReservation: PropTypes.func.isRequired,
    createReservation: PropTypes.func.isRequired,
    reservationList: PropTypes.object.isRequired,
    searchProduct: PropTypes.func.isRequired,
    searchResults: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      reservations: this.props.reservationList.reservations,
      newReservations: []
    }
  }

  componentWillUpdate(nextProps) {
    if (
      !isEqual(nextProps.reservationList.reservations, this.state.reservations)
    ) {
      this.setState({
        reservations: nextProps.reservationList.reservations
      })
    }
  }

  handleFulfill(reservation) {
    this.props.toggleFulfill(reservation)
  }

  handleChecked = () => {
    this.setState({
      newReservations: []
    })
    this.props.toggleChecked(this.props.reservationList)
  }

  updateReservation = (reservation, update) => {
    this.props.updateReservation(this.props.reservationList.id, {
      ...reservation,
      ...update
    })
  }

  newReservation = () => {
    this.setState({
      newReservations: [
        ...this.state.newReservations,
        { uid: this.state.newReservations.length + 1, product: {}, quantity: 1 }
      ]
    })
  }

  createReservation = reservation => {
    const data = { reservation: reservation }
    this.removeNewReservation(reservation)
    this.props.createReservation(this.props.reservationList.id, data)
  }

  removeNewReservation = reservation => {
    const newReservations = reject(this.state.newReservations, {
      uid: reservation.uid
    })

    this.setState({
      newReservations: newReservations
    })
  }

  render() {
    return (
      <div>
        <table className='table table-breservationListed table-condensed reservations'>
          <thead>
            <tr>
              <th className='text-center'>Status</th>
              <th className='text-center'>Quantity</th>
              <th>Product</th>
              <th>Price</th>
              <th>Comments</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.reservations.map(reservation => {
              return (
                <ReservationRow
                  key={reservation.id}
                  reservation={reservation}
                  reservationList={this.props.reservationList}
                  onChange={this.updateReservation}
                  searchProduct={this.props.searchProduct}
                  searchResults={this.props.searchResults}
                />
              )
            })}
            {this.state.newReservations.map(reservation => {
              return (
                <NewReservationRow
                  key={reservation.uid}
                  reservation={reservation}
                  createReservation={this.createReservation}
                  releaseId={this.props.reservationList.release_id}
                  removeEntry={this.removeNewReservation}
                  searchProduct={this.props.searchProduct}
                  searchResults={this.props.searchResults}
                />
              )
            })}
            {this.props.reservationList.status === 'open' ? (
              <tr>
                <td colSpan='6' className='text-center'>
                  <button
                    className='btn btn-outline-success btn-lg px-5'
                    onClick={this.newReservation}
                  >
                    <i className='ion-plus-circled' />
                  </button>
                </td>
              </tr>
            ) : null}
          </tbody>
          <TableFooter
            reservationList={this.props.reservationList}
            updateReservationList={this.props.updateReservationList}
            handleChecked={this.handleChecked}
          />
        </table>
      </div>
    )
  }
}

export default ReservationListTable
