import React from 'react'
import PropTypes from 'prop-types'
import InputSelect from '../../AdminProductItem/components/InputSelect'
import AddressSection from './AddressSection'
import InputText from '../../AdminProductItem/components/InputText'
import BranchSection from './BranchSection'

const CheckoutFormSection = ({
  onSubmit,
  user,
  regions,
  form,
  addressForm,
  handleChange,
  handleAddressChange,
  selectRegion,
  selectCity,
  selectBarangay,
  cities,
  barangays,
  handleDeliveryOption,
  getFullAddresses,
  disableButton
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(form)
  }

  return (
    <div className='mt-3'>
      <div className='well checkout-form'>
        <form onSubmit={handleSubmit}>
          <InputSelect
            label='Delivery Option'
            name='delivery_option'
            options={[
              { name: 'Shipping', value: 'shipping' },
              { name: 'Store', value: 'store' }
            ]}
            value={form.delivery_option}
            required
            onChange={handleDeliveryOption}
          />
          {form.delivery_option === 'shipping' && (
            <AddressSection
              {...{
                user,
                handleChange,
                form,
                addressForm,
                handleAddressChange,
                regions,
                selectRegion,
                cities,
                selectCity,
                barangays,
                selectBarangay,
                getFullAddresses
              }}
            />
          )}
          {form.delivery_option === 'store' && (
            <BranchSection branch={form.branch} handleChange={handleChange} />
          )}
          <InputText
            label='Phone Number*'
            name='phone_number'
            value={form.phone_number}
            required
            onChange={handleChange}
          />
          <InputText
            label='Notes'
            name='notes'
            value={form.notes}
            onChange={handleChange}
          />
          <button
            type='submit'
            className='btn btn-primary'
            disabled={disableButton}
          >
            Checkout
          </button>
        </form>
      </div>
    </div>
  )
}

CheckoutFormSection.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.object,
  regions: PropTypes.array,
  form: PropTypes.object,
  addressForm: PropTypes.object,
  handleChange: PropTypes.func,
  handleAddressChange: PropTypes.func,
  selectRegion: PropTypes.func,
  selectCity: PropTypes.func,
  selectBarangay: PropTypes.func,
  cities: PropTypes.array,
  barangays: PropTypes.array,
  handleDeliveryOption: PropTypes.func,
  getFullAddresses: PropTypes.array,
  disableButton: PropTypes.bool
}

export default CheckoutFormSection
