import React from 'react'

import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import SearchBar from '../../components/shared/SearchBar'

class ControlPanel extends React.Component {
  static propTypes = {
    results: PropTypes.object.isRequired,
    approveReleaseReservationLists: PropTypes.func.isRequired,
    filterReservationLists: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      filters: {}
    }
  }

  showFinishButton() {
    return this.props.results.checked_count > 0
  }

  cleanFilters(filters, key) {
    let newFilter = { ...this.state.filters, [key]: filters }
    if (isEmpty(filters)) {
      newFilter = omit(newFilter, [key])
    }
    return newFilter
  }

  handleClick = newStatus => {
    let newFilter = this.cleanFilters(newStatus, 'status')
    this.setState({
      filters: newFilter
    })
    this.props.filterReservationLists(newFilter)
  }

  handleSelect = e => {
    let newFilter = this.cleanFilters(e.target.value, 'branch')
    this.setState({
      filters: newFilter
    })
    this.props.filterReservationLists(newFilter)
  }

  handleSearch = query => {
    let newFilter = this.cleanFilters(query.user, 'user')
    this.setState({
      filters: newFilter
    })
    this.props.filterReservationLists(newFilter)
  }

  render() {
    return (
      <div>
        <SearchBar
          search={this.handleSearch}
          searchKey={'user'}
          placeholder={'Search user'}
        />
        {this.showFinishButton() && (
          <span>
            <button
              className='btn btn-success'
              onClick={this.props.approveReleaseReservationLists}
            >
              Approve Checked Reservations
            </button>{' '}
          </span>
        )}
        <button
          className={`btn btn-default ${isEmpty(this.state.filters.status) &&
            'btn-primary'}`}
          disabled={isEmpty(this.state.filters) || this.props.loading}
          onClick={() => this.handleClick(null)}
        >
          All Reservations {`(${this.props.results.total_count})`}
        </button>{' '}
        <button
          className={`btn btn-default ${this.state.filters.status === 'open' &&
            'btn-primary'}`}
          disabled={this.state.filters.open || this.props.loading}
          onClick={() => this.handleClick('open')}
        >
          Open Reservations {`(${this.props.results.open_count})`}
        </button>{' '}
        <button
          className={`btn btn-default ${this.state.filters.status ===
            'checked' && 'btn-primary'}`}
          disabled={this.state.filters.checked || this.props.loading}
          onClick={() => this.handleClick('checked')}
        >
          Checked Reservations {`(${this.props.results.checked_count})`}
        </button>{' '}
        <select
          id='branch-selection'
          disabled={this.props.loading}
          onChange={e => {
            this.handleSelect(e)
          }}
        >
          <option value=''>All Reservations</option>
          <option value='Robinsons Galleria'>Robinsons Galleria</option>
          <option value='Fort Fully Booked 4th Floor'>
            Fort Fully Booked 4th Floor
          </option>
          <option value='shipping'>Shipping</option>
        </select>
      </div>
    )
  }
}

export default ControlPanel
