import React from 'react'
import PropTypes from 'prop-types'

export const ProductQuantity = ({ cartItem }) => {
  return cartItem.reservation ? (
    <td>
      <div className='text-center'>{cartItem.reservation.quantity}</div>
    </td>
  ) : (
    <td>
      <div className='text-center'>1</div>
    </td>
  )
}

ProductQuantity.propTypes = {
  cartItem: PropTypes.object
}
