import * as Types from '../constants/ActionTypes'

export const openingModal = (actionType = '') => {
  return dispatch => {
    dispatch(openModal(actionType))
  }
}

export const closeModal = () => {
  return dispatch => {
    dispatch(closingModal())
  }
}

export const openModal = actionType => {
  return {
    type: Types.OPEN_MODAL,
    actionType: actionType
  }
}

export const clearModal = () => {
  return dispatch => {
    dispatch(clearingModal())
  }
}

const closingModal = () => {
  return {
    type: Types.CLOSE_MODAL
  }
}

const clearingModal = () => {
  return {
    type: Types.CLEAR_MODAL
  }
}
