import React, { Component } from 'react'
import capitalize from 'lodash/capitalize'

export default class ReleaseItem extends Component {
  handleClick = () => {
    const { id, status } = this.props.release
    const { onUpdateRelease, onCloseRelease } = this.props

    if (status === 'publish') {
      onCloseRelease(id)
    } else {
      onUpdateRelease(id, { status: 'publish' })
    }
  }

  render() {
    const {
      id,
      title,
      status,
      products_count,
      reservations_total,
      customers_count
    } = this.props.release

    return (
      <div className='row release-item'>
        <div className='col-sm-7'>
          <div className='release-status hidden-xs'>
            <span className='icon ion-stats-bars' />
          </div>
          <h3 className='title'>
            <a href={`/admin/releases/${id}`}>{title}</a>
          </h3>
          <div className='release-button'>
            <span>
              {' '}
              {status === 'publish'
                ? 'Published'
                : status === 'close'
                ? 'Closed'
                : 'Draft'}{' '}
            </span>
            <button
              className='btn btn-primary btn-sm'
              onClick={this.handleClick}
            >
              {status === 'publish' ? 'Close' : 'Publish'}
            </button>
          </div>
        </div>
        <div className='col-sm-5 release-details'>
          <div className='btn-group btn-group-justified'>
            <div className='btn-group' role='group'>
              <button type='button' className='btn btn-default'>
                {products_count}
                <span className='button-label'>Issues</span>
              </button>
            </div>
            <div className='btn-group' role='group'>
              <a
                href={`/admin/releases/${id}/reservations`}
                className='btn btn-default'
              >
                {reservations_total}
                <span className='button-label'>Reservations</span>
              </a>
            </div>
            <div className='btn-group' role='group'>
              <button type='button' className='btn btn-default'>
                {customers_count}
                <span className='button-label'>Customers</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
