import React from 'react'
import PropTypes from 'prop-types'

export const ProductItemDescription = ({ cartItem }) => {
  if (cartItem.reservation) {
    return null
  }

  const description = cartItem.product_item.description
    ? `${cartItem.product_item.description} ${cartItem.product_item.condition}`
    : `${cartItem.product_item.condition}`

  return <div className='small'>{description}</div>
}

ProductItemDescription.propTypes = {
  cartItem: PropTypes.object
}
