import {
  ADD_ORDER,
  RECEIVE_ORDERS,
  RESET_STORE
} from '../constants/ActionTypes'
import find from 'lodash/find'

const initialState = {
  orders: []
}

export default function orders(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ORDERS:
      return { ...state, orders: action.orders }
    case ADD_ORDER:
      return { ...state, orders: state.orders.concat(action.order) }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}

export function findOrder(orders, currentRelease) {
  return find(orders, item => {
    return item.release_id === currentRelease.id
  })
}
