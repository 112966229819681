import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { searchForProducts, resetFilter } from '../actions/filter'

class FilterContainer extends Component {
  renderPublisherFilter() {
    let items = []

    this.props.publishers.map((publisher, index) => {
      items.push(
        <option key={index} value={publisher.id}>
          {publisher.title}
        </option>
      )
    })

    return (
      <div className='form-group'>
        <label htmlFor='publisher'> Publisher </label>
        <select id='publisher' className='form-control'>
          <option value='0'> Select a Publisher </option>
          {items}
        </select>
      </div>
    )
  }

  renderCategoryFilter() {
    let items = []

    this.props.categories.map((category, index) => {
      items.push(
        <option key={index} value={category.id}>
          {category.name}
        </option>
      )
    })

    return (
      <div className='form-group'>
        <label htmlFor='category'> Category </label>
        <select id='category' className='form-control'>
          <option value='0'> Select a Category </option>
          {items}
        </select>
      </div>
    )
  }

  renderReservedFilter() {
    return (
      <div className='form-group'>
        <label htmlFor='reserved'> Status </label>
        <select id='reserved' className='form-control'>
          <option value='All'> All </option>
          <option value='Reserved'> Reserved </option>
        </select>
      </div>
    )
  }

  handleSubmit(evt, currentRelease, params) {
    evt.preventDefault()

    const { onSubmitSearch } = this.props
    const { onResetSearch } = this.props
    const { search } = params
    if (search.value.length === 0) {
      onResetSearch(currentRelease.id)
    } else {
      let data = {
        title: search.value,
        description: search.value,
        creators: search.value
      }

      onSubmitSearch(currentRelease.id, data)
    }
  }

  render() {
    const { publishers, categories, currentRelease } = this.props
    const { onResetSearch } = this.props
    let search, publisher, category

    return (
      <form
        className='filter'
        onSubmit={event => this.handleSubmit(event, currentRelease, { search })}
      >
        <div className='row'>
          <div className='col-6'>
            <div className='form-group'>
              <div className='input-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search'
                  name='title'
                  id='search'
                  ref={el => {
                    search = el
                  }}
                />

                <span className='input-group-btn'>
                  <button type='submit' className='btn btn-plain'>
                    <i className='ion-ios-search' />
                  </button>
                  <a href='#' onClick={() => onResetSearch(currentRelease.id)}>
                    Reset
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentRelease: state.releases.selectedRelease,
    publishers: state.publishers.publishers,
    categories: state.categories.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmitSearch: (context1, context2) => {
      dispatch(searchForProducts(context1, context2))
    },
    onResetSearch: context => {
      dispatch(resetFilter(context))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer)

FilterContainer.propTypes = {
  publishers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired
}
