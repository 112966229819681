import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { debounce } from 'throttle-debounce'

import LoadingScreen from '../../components/shared/LoadingScreen'
import { searchProduct, searchSeries } from '../apiWrappers'
import SearchProductForm from '../components/SearchProductForm'

const defaultState = {
  loading: false,
  form: {},
  seriesList: [],
  autocomplete: {}
}

export default class SearchProductContainer extends Component {
  static propTypes = {
    onSearchResult: PropTypes.func,
    categories: PropTypes.array
  }

  state = defaultState

  handleChange = e => {
    if (!e.target.name) {
      return
    }

    const { form } = this.state
    let newForm

    if (e.target.name === 'category_id') {
      newForm = { [e.target.name]: e.target.value }
    } else {
      newForm = {
        ...form,
        [e.target.name]: e.target.value
      }
    }
    this.setState({ form: newForm })
  }

  handleSearchProduct = async e => {
    e.preventDefault()
    this.props.onSearchResult({
      productData: {},
      formData: {},
      displayForm: false
    })
    try {
      this.setState({ loading: true })
      const response = await searchProduct(this.state.form)
      this.props.onSearchResult({
        productData: response.data,
        formData: this.state.form,
        displayForm: true
      })
    } catch (err) {
      this.props.onSearchResult({
        productData: {},
        formData: this.state.form,
        displayForm: true
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  handleSearchSeries = debounce(300, async search => {
    if (search.length === 0) {
      return
    }
    try {
      const response = await searchSeries(search, 50)
      const series = response.data.series
      const formatted = series.map(object => {
        return { value: object.id, label: object.title }
      })
      this.setState({ seriesList: formatted })
    } catch (err) {
      console.error(err)
    }
  })

  handleSelectSeries = series => {
    const { form } = this.state

    this.setState({
      form: {
        ...form,
        series_id: series.value
      }
    })
  }

  render() {
    const { loading, seriesList } = this.state
    const { categories } = this.props
    return (
      <Fragment>
        <SearchProductForm
          form={this.state.form}
          categories={categories}
          seriesList={seriesList}
          onChange={this.handleChange}
          onSelectCategory={this.handleSelectCategory}
          onSearchProduct={this.handleSearchProduct}
          onSearchSeries={this.handleSearchSeries}
          onSelectSeries={this.handleSelectSeries}
        />
        {loading && <LoadingScreen />}
      </Fragment>
    )
  }
}
