// @flow

import React, { Fragment } from 'react'

import InputSelect from '../InputSelect'
import InputText from '../InputText'
import type { ProductItemFormProps } from '../../types'
import { conditionOptions } from '../../constants'

const ProductItemForm = (props: ProductItemFormProps) => {
  const { toggleForm, onCreate, onChange, form, displayForm } = props
  const {
    prefix,
    condition,
    price,
    location_id,
    quantity,
    description,
    errors
  } = form
  const locationOptions = [
    { name: 'Warehouse', value: '1' },
    { name: 'Galleria', value: '2' }
  ]

  const renderErrors = _ => {
    const { base } = errors
    if (!base) {
      return null
    }

    return (
      <Fragment>
        {base.map(error => (
          <div className='alert alert-danger' key={error}>
            {error}
          </div>
        ))}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {displayForm ? (
        <form className='form' onSubmit={onCreate} onChange={onChange}>
          {renderErrors()}
          <div className='form-row'>
            <InputText
              label='SKU Prefix'
              name='prefix'
              value={prefix}
              errors={errors.prefix}
              col
              required
            />
            <InputSelect
              label='Condition'
              name='condition'
              options={conditionOptions}
              value={condition}
              errors={errors.condition}
              col
              required
            />
            <InputText
              label='Description'
              name='description'
              value={description}
              errors={errors.description}
              col
            />
            <InputText
              label='Price'
              value={price}
              name='price'
              type='number'
              errors={errors.price}
              col
              required
            />
            <InputText
              label='Quantity'
              value={quantity}
              name='quantity'
              type='number'
              errors={errors.quantity}
              col
              required
            />
            <InputSelect
              label='Location'
              name='location_id'
              options={locationOptions}
              value={location_id}
              errors={errors.location_id}
              col
              required
            />
          </div>
          <div className='form-group text-right'>
            <button type='submit' className='btn btn-xs btn-primary'>
              Create
            </button>
            <a href='#' className='btn btn-xs btn-danger' onClick={toggleForm}>
              Cancel
            </a>
          </div>
        </form>
      ) : (
        <div className='form-group text-right'>
          <button className='btn btn-xs btn-primary' onClick={toggleForm}>
            New
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default ProductItemForm
