import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import serialize from 'form-serialize'

export default class SidebarFilterer extends Component {
  handleInputChange(params) {
    const { onSearch } = this.props
    const { query } = params

    onSearch({
      title: query,
      description: query,
      creators: query
    })
  }

  handleFilterSubmit(evt, formEl) {
    const { onFilter } = this.props
    evt.preventDefault()

    onFilter(serialize(formEl, { hash: true }))
  }

  handleReset(evt) {
    const { onReset } = this.props

    onReset()
  }

  renderPublishers() {
    const { publishers } = this.props

    return publishers.map((publisher, index) => {
      return (
        <li key={index}>
          <label htmlFor={`publishers-${index}`}>
            <input
              type='checkbox'
              key={index}
              value={publisher.id}
              name='publisher_id[]'
              id={`publishers-${index}`}
            />
            &nbsp;
            {publisher.title}
          </label>
        </li>
      )
    })
  }

  renderCategories() {
    const { categories } = this.props

    return categories.map((category, index) => {
      return (
        <li key={index}>
          <label htmlFor={`category-${index}`}>
            <input
              type='checkbox'
              key={`${index}-category`}
              id={`category-${index}`}
              value={category.id}
              name='category_id[]'
            />
            &nbsp;
            {category.name}
          </label>
        </li>
      )
    })
  }

  render() {
    const { publishers, categories } = this.props
    let queryInput, filterForm

    return (
      <div className='sidebar -marketplace'>
        <div className='panel'>
          <input
            type='text'
            ref={el => {
              queryInput = el
            }}
            onChange={debounce(() => {
              this.handleInputChange({ query: queryInput.value })
            }, 500)}
            placeholder='Search by title, issue number'
          />
        </div>
        <form
          ref={el => {
            filterForm = el
          }}
          onSubmit={evt => {
            this.handleFilterSubmit(evt, filterForm)
          }}
        >
          <div className='form-actions'>
            <button className='btn btn-primary btn-xs' type='submit'>
              Filter
            </button>
            &nbsp;
            <button
              onClick={evt => this.handleReset(evt)}
              className='btn btn-default btn-xs'
              type='button'
            >
              Reset
            </button>
          </div>
          <div className='panel'>
            <h3> By Categories </h3>
            <ul>{categories.length === 0 ? '' : this.renderCategories()}</ul>
          </div>
          <div className='panel'>
            <h3> By Publishers </h3>
            <ul>{publishers.length === 0 ? '' : this.renderPublishers()}</ul>
          </div>
        </form>
      </div>
    )
  }
}
