import React, { Component } from 'react'
import { Provider } from 'react-redux'

import MarketplaceContainer from './MarketplaceContainer'
import ModalContainer from '../../containers/ModalContainer'
import DevTools from '../../containers/DevTools'

export default class MarketplaceRoot extends Component {
  render() {
    const { store, env } = this.props

    return (
      <Provider store={store}>
        <div>
          <MarketplaceContainer />
          <ModalContainer />
          {env === 'development' ? <DevTools /> : ''}
        </div>
      </Provider>
    )
  }
}
