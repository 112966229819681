import React, { Component } from 'react'
import { connect } from 'react-redux'

import SidebarFilterer from '../components/SidebarFilterer'
import ProductItem from '../components/ProductItem'
import LoadMoreButton from '../../components/shared/LoadMoreButton'

import {
  fetchProductsIndex,
  searchProducts,
  filterProducts,
  resetProducts,
  fetchProduct
} from '../actions'

class MarketplaceContainer extends Component {
  renderItems() {
    const { products } = this.props
    const { onViewProduct } = this.props

    return products.map((product, index) => {
      return (
        <ProductItem
          onViewProduct={onViewProduct}
          product={product}
          key={index}
        />
      )
    })
  }

  render() {
    const { publishers, categories, currentPage, paginating } = this.props
    const {
      onLoadMore,
      onSearchProducts,
      onFilterProducts,
      onResetProducts
    } = this.props

    return (
      <div className='row'>
        <div className='col-md-3'>
          <SidebarFilterer
            onSearch={onSearchProducts}
            onFilter={onFilterProducts}
            onReset={onResetProducts}
            publishers={publishers}
            categories={categories}
          />
        </div>
        <div className='col-md-9'>
          <div className='product-grid'>{this.renderItems()}</div>
        </div>
        {paginating ? (
          <LoadMoreButton
            onLoadMore={() => {
              onLoadMore(currentPage)
            }}
            message='Load more products'
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { products, currentPage, paginating } = state.marketplace.present
  const { categories } = state.categories
  const { publishers } = state.publishers

  return {
    products: products,
    currentPage: currentPage,
    publishers: publishers,
    categories: categories,
    paginating: paginating
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadMore: context => {
      dispatch(fetchProductsIndex(context))
    },
    onSearchProducts: context => {
      dispatch(searchProducts(context))
    },
    onFilterProducts: context => {
      dispatch(filterProducts(context))
    },
    onViewProduct: context => {
      dispatch(fetchProduct(context))
    },
    onResetProducts: () => {
      dispatch(resetProducts())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceContainer)
