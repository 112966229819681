import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from '../../presenters/format'

const ShippingFeeView = ({ deliveryCost }) => {
  // Removes free shipping for now
  // if (subTotal >= 1000) {
  //   return <td>Free Shipping</td>
  // } else {
    // }
    return <td>{formatCurrency(deliveryCost)}</td>
}

ShippingFeeView.propTypes = {
  deliveryCost: PropTypes.number,
  subTotal: PropTypes.number
}

export default ShippingFeeView
