import API from './api'

const UsersAPI = {
  fetch(onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/users`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default UsersAPI
