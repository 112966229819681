import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

TextArea.propTypes = {
  autoComplete: PropTypes.string,
  col: PropTypes.bool,
  cols: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.string,
  value: PropTypes.string
}

TextArea.defaultProps = {
  onChange: () => {},
  cols: '55',
  rows: '5',
  maxLength: 165,
  value: '',
  required: false
}

export default function TextArea(props) {
  const {
    autoComplete,
    col,
    cols,
    disabled,
    label,
    maxLength,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    rows,
    value
  } = props
  const textareaProps = {
    autoComplete,
    cols,
    disabled,
    id: name,
    maxLength,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    rows,
    value: value || ''
  }
  const cn = classnames('form-group', { col: col })
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea className={cn} {...textareaProps} />
    </div>
  )
}
