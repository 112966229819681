import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import omit from 'lodash/omit'
import find from 'lodash/find'
import produce from 'immer'

import ProductItemForm from '../components/forms/ProductItemForm'
import ProductItemTable from '../components/ProductItemTable'
import ProductItem from '../components/ProductItem'
import ProductItemEdit from '../components/ProductItemEdit'
import {
  fetchProductItems,
  updateProductItem,
  createProductItem,
  deleteProductItem
} from '../apiWrappers'

const defaultState = {
  uiState: {
    isLoading: false,
    displayForm: false
  },
  newProductItem: {},
  productItems: {}
}

const sameConditionPrice = (productItems, condition) => {
  const similarItemCondition = find(productItems, { condition: condition })
  return similarItemCondition ? similarItemCondition.price : '0'
}

const arrayToObject = (list, key) => {
  const object = {}
  list.forEach(element => {
    object[element[key]] = element
  })
  return object
}

const updateSameConditionPrices = (items, { condition, price }) => {
  Object.keys(items).forEach(key => {
    if (items[key].condition === condition) {
      items[key].price = price
    }
  })

  return items
}

export default class ManageProductItemContainer extends Component {
  static propTypes = {
    productData: PropTypes.object
  }

  state = defaultState

  async componentDidMount() {
    const { uiState } = defaultState
    this.setState({
      uiState: { ...uiState, isLoading: true }
    })

    try {
      const { productData } = this.props
      const response = await fetchProductItems(productData.id)
      this.setState({
        uiState: uiState,
        productItems: arrayToObject(response.data, 'id')
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        uiState: { ...uiState, isLoading: false }
      })
    }
  }

  handleEdit = productItem => {
    const { productItems } = this.state
    const { id } = productItem

    this.setState({
      productItems: {
        ...productItems,
        [id]: { ...productItem, edit: true, old: productItem, errors: {} }
      }
    })
  }

  handleDelete = async productItem => {
    const { productItems } = this.state

    try {
      await deleteProductItem(productItem)
      this.setState({
        productItems: omit(productItems, productItem.id)
      })
    } catch (err) {
      console.error(err)
    }
  }

  handleUpdate = async productItem => {
    const { productItems } = this.state

    this.setState(
      produce(draft => {
        draft.productItems[productItem.id].errors = {}
      })
    )

    try {
      const response = await updateProductItem(productItem)
      const { data } = response
      const updatedItems = updateSameConditionPrices(productItems, data)
      this.setState({
        productItems: {
          ...updatedItems,
          [data.id]: data
        }
      })
    } catch (err) {
      const { data } = err.response
      this.setState(
        produce(draft => {
          draft.productItems[productItem.id].errors = data
        })
      )
      console.error(err)
    }
  }

  handleChange = (target, productItem) => {
    const { productItems } = this.state
    const { id } = productItem

    this.setState(
      produce(draft => {
        const draftProductItem = {
          ...productItem,
          [target.name]: target.value
        }

        if (target.name === 'condition') {
          draftProductItem.price = sameConditionPrice(
            productItems,
            target.value
          )
        }

        if (id) {
          draft.productItems[id] = draftProductItem
        } else {
          draft.newProductItem = draftProductItem
        }
      })
    )
  }

  handleCancel = productItem => {
    const { productItems } = this.state
    const { id } = productItem

    this.setState({
      productItems: {
        ...productItems,
        [id]: { ...productItem.old, edit: false }
      }
    })
  }

  defaultNewProductItem = () => {
    const { productData } = this.props
    const { productItems } = this.state

    return {
      product_id: productData.id,
      price: sameConditionPrice(productItems, 'NM'),
      condition: 'NM',
      quantity: 1,
      location_id: 1,
      errors: {}
    }
  }

  handleCreate = async e => {
    e.preventDefault()

    const { productData } = this.props
    const { newProductItem } = this.state

    this.setState(
      produce(draft => {
        draft.newProductItem.errors = {}
      })
    )

    try {
      await createProductItem(newProductItem)
      const response = await fetchProductItems(productData.id)
      this.setState(
        produce(draft => {
          draft.productItems = arrayToObject(response.data, 'id')
          draft.uiState.displayForm = false
          draft.newProductItem = this.defaultNewProductItem()
        })
      )
    } catch (err) {
      const { data } = err.response
      this.setState(
        produce(draft => {
          draft.newProductItem.errors = data
        })
      )
      console.error(err)
    }
  }

  handleNewFormChange = e => {
    this.handleChange(e.target, this.state.newProductItem)
  }

  toggleForm = e => {
    e.preventDefault()

    this.setState(
      produce(draft => {
        draft.uiState.displayForm = !draft.uiState.displayForm
        if (draft.uiState.displayForm) {
          draft.newProductItem = this.defaultNewProductItem()
        }
      })
    )
  }

  render() {
    const { productItems, newProductItem, uiState } = this.state
    const formProps = {
      toggleForm: this.toggleForm,
      onCreate: this.handleCreate,
      onChange: this.handleNewFormChange,
      productItems,
      form: newProductItem,
      displayForm: uiState.displayForm
    }

    return (
      <Fragment>
        <div className='col-12'>
          <br />
          <h3> Product Items </h3>
          <hr />

          <ProductItemForm {...formProps} />
          <ProductItemTable newProductItem={newProductItem}>
            {Object.keys(productItems).map((key, index) =>
              productItems[key].edit ? (
                <ProductItemEdit
                  key={key}
                  productItem={productItems[key]}
                  handleChange={this.handleChange}
                  onUpdate={this.handleUpdate}
                  onCancel={this.handleCancel}
                />
              ) : (
                <ProductItem
                  key={key}
                  productItem={productItems[key]}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                />
              )
            )}
          </ProductItemTable>
        </div>
      </Fragment>
    )
  }
}
