import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  searchShortedReservations,
  searchProducts,
  updateReservation
} from '../actions'
import SearchBar from '../../components/shared/SearchBar'
import ReservationTable from '../components/ReservationTable'

class AdminShortagesContainer extends React.Component {
  static propTypes = {
    searchShortedReservations: PropTypes.func.isRequired,
    searchProducts: PropTypes.func.isRequired,
    updateReservation: PropTypes.func.isRequired,
    current_page: PropTypes.number.isRequired,
    total_pages: PropTypes.number.isRequired,
    requesterId: PropTypes.number,
    reservations: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    uiState: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.props.searchShortedReservations(
      { status: 'short' },
      this.props.current_page
    )
  }

  search = query => {
    this.setState({ filters: query })
    this.props.searchShortedReservations(
      { status: 'short', ...query },
      this.props.current_page
    )
  }

  changePage = page => {
    this.props.searchShortedReservations(
      { status: 'short', ...this.state.filters },
      page
    )
  }

  render() {
    return (
      <div>
        <h3 className='page-title'>Shortages</h3>
        <SearchBar
          search={this.search}
          searchKey={'product'}
          placeholder={'Search product'}
        />
        <a href='/admin/shortages/approve' className='btn btn-primary mb-3'>
          Approve Reservations
        </a>
        <ReservationTable
          reservations={this.props.reservations}
          current_page={this.props.current_page}
          total_pages={this.props.total_pages}
          changePage={this.changePage}
          disable={this.props.uiState.search}
          searchProducts={this.props.searchProducts}
          products={this.props.products}
          requesterId={this.props.requesterId}
          updateReservation={this.props.updateReservation}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_page: state.admin_shortages.current_page,
    total_pages: state.admin_shortages.total_pages,
    reservations: state.admin_shortages.reservations,
    uiState: state.admin_shortages.uiState,
    products: state.admin_shortages.products,
    requesterId: state.admin_shortages.requesterId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchShortedReservations: (query, page) => {
      dispatch(searchShortedReservations(query, page))
    },
    searchProducts: (query, reservationId) => {
      dispatch(searchProducts(query, reservationId))
    },
    updateReservation: (releaseId, resevationId, data) => {
      dispatch(updateReservation(releaseId, resevationId, data))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminShortagesContainer)
