import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import swal from 'sweetalert2'

import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import QuantityOptions from '../../components/shared/QuantityOptions'
import ProductTypeahead from '../../components/shared/ProductTypeahead'

const statuses = {
  void: 'Void',
  for_approval: 'Awaiting Approval'
}

const humanizeStatus = status => {
  return statuses[status] || ''
}

class ReservationRow extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    updateReservation: PropTypes.func.isRequired,
    searchProducts: PropTypes.func.isRequired,
    searchResults: PropTypes.array,
    requesterId: PropTypes.number
  }

  shouldComponentUpdate(nextProps) {
    if (
      !isEqual(nextProps.searchResults, this.props.searchResults) &&
      nextProps.requesterId !== this.props.reservation.id
    ) {
      return false
    }
    return true
  }

  onChange = (reservation, update) => {
    this.props.updateReservation(
      this.props.reservation.release_id,
      this.props.reservation.id,
      { ...reservation, ...update }
    )
  }

  fill = e => {
    e.preventDefault()
    this.props.updateReservation(
      this.props.reservation.release_id,
      this.props.reservation.id,
      { status: 'for_approval' }
    )
  }

  void = e => {
    e.preventDefault()
    swal({
      title: 'Are you sure you want to void this reservation?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    })
      .then(() => {
        this.props.updateReservation(
          this.props.reservation.release_id,
          this.props.reservation.id,
          { status: 'void' }
        )
      })
      .catch(() => {})
  }

  onSearch = query => {
    this.props.searchProducts({ title: query }, this.props.reservation.id)
  }

  selectProduct = product => {
    this.props.updateReservation(
      this.props.reservation.release_id,
      this.props.reservation.id,
      { product_id: product.id, product: product }
    )
  }

  enableModifications() {
    const { reservation } = this.props
    const { status } = reservation

    return !['void', 'fill', 'for_approval'].includes(status)
  }

  render() {
    const { reservation, searchResults } = this.props

    return (
      <tr>
        <td>
          <ProductTypeahead
            enableEdit={this.enableModifications()}
            onSearch={this.onSearch}
            reservation={reservation}
            searchResults={searchResults}
            selectProduct={this.selectProduct}
          />
        </td>
        <td className='text-center'>
          {this.enableModifications() ? (
            <QuantityOptions
              reservation={reservation}
              onChange={this.onChange}
            />
          ) : (
            <label>{reservation.quantity}</label>
          )}
        </td>
        <td>
          {isEmpty(reservation.fulfillment_location)
            ? 'Shipping'
            : reservation.fulfillment_location}
        </td>
        <td>
          {reservation.user.first_name} {reservation.user.last_name}
        </td>
        <td className='text-center'>
          <p>
            {this.enableModifications() ? (
              <Fragment>
                {reservation.status !== 'for_approval' && (
                  <Fragment>
                    <a
                      href='#'
                      className='btn btn-sm btn-success'
                      onClick={this.fill}
                    >
                      Fill
                    </a>{' '}
                  </Fragment>
                )}
                <a
                  href='#'
                  className='btn btn-sm btn-warning'
                  onClick={this.void}
                >
                  Void
                </a>
              </Fragment>
            ) : (
              <span>{humanizeStatus(reservation.status)}</span>
            )}
          </p>
        </td>
      </tr>
    )
  }
}

export default ReservationRow
