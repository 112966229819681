import * as Types from '../constants/ActionTypes'
import ProductsAPI from '../services/products_api'
import { switchLoading } from '../actions/releases'
import find from 'lodash/find'
import { openModal } from './modals'

export const fetchProducts = (releaseId, page = 1) => {
  return dispatch => {
    dispatch(switchLoading(true))
    ProductsAPI.fetch(
      releaseId,
      page,
      data => {
        dispatch(receiveProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {
        dispatch(switchLoading(false))
      }
    )
  }
}

export const blowUpImage = productId => {
  return (dispatch, getState) => {
    const products = getState().releases.products

    const product = find(products, function(item) {
      return item.id === productId
    })

    dispatch(receiveImage(product))
    dispatch(openModal(Types.BLOWUP_IMAGE))
  }
}

export const receiveProducts = (products, override = undefined) => {
  return {
    type: Types.RECEIVE_PRODUCTS,
    products: products,
    override: override
  }
}

export const clearProducts = () => {
  return {
    type: Types.CLEAR_PRODUCTS
  }
}

const receiveImage = product => {
  return {
    type: Types.BLOWUP_IMAGE,
    product: product
  }
}
