export const fullAddress = address => {
  const {
    house_number,
    street_name,
    building,
    region,
    city,
    barangay,
    country,
    zip_code
  } = address
  const baseAddress = `${house_number}, ${street_name}, ${region}, ${city}, ${barangay}, ${country}, ${zip_code}`
  return building ? `${building}, ` + baseAddress : baseAddress
}
