import React, { Component } from 'react'
import { connect } from 'react-redux'

import BlankSlate from '../../components/shared/BlankSlate'
import ProductTable from '../components/ProductTable'
import ProductItem from '../components/ProductItem'

import { updateProduct, destroyProduct, uploadCover } from '../actions'

class AdminProductsContainer extends Component {
  renderProducts() {
    const { products, categories, publishers, releaseId } = this.props
    const { onUpdateProduct, onDestroyProduct, onUploadCover } = this.props

    return products.map((product, index) => {
      return (
        <ProductItem
          onUploadCover={onUploadCover}
          onUpdateProduct={onUpdateProduct}
          onDestroyProduct={onDestroyProduct}
          releaseId={releaseId}
          product={product}
          publishers={publishers}
          categories={categories}
          key={index}
        />
      )
    })
  }

  render() {
    const { products, releaseId } = this.props

    return (
      <div>
        {products.length === 0 ? (
          <BlankSlate message='You have no issues for this release yet.' />
        ) : (
          <ProductTable releaseId={releaseId}>
            {this.renderProducts()}
          </ProductTable>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { products, publishers, categories, releaseId } = state.admin_products

  return {
    products: products,
    publishers: publishers,
    categories: categories,
    releaseId: releaseId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProduct: (releaseId, productId, context) => {
      dispatch(updateProduct(releaseId, productId, context))
    },
    onDestroyProduct: (releaseId, productId) => {
      dispatch(destroyProduct(releaseId, productId))
    },
    onUploadCover: (releaseId, productId, context) => {
      dispatch(uploadCover(releaseId, productId, context))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductsContainer)
