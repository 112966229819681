import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import c from 'classnames'

const PaymentOptions = ({ form, handlePaymentOption }) => {
  const { delivery_option, transaction_source, total_price } = form
  const isShipping = delivery_option === 'shipping'
  const showCOD = false // isShipping && total_price >= 1000

  const handleClick = (e, value) => {
    e.preventDefault()

    handlePaymentOption(value)
  }

  return (
    <ul className='nav nav-pills nav-fill'>
      <li className='nav-item'>
        <a
          className={c({
            'nav-link': true,
            active: transaction_source === 'paypal'
          })}
          href='#'
          onClick={e => handleClick(e, 'paypal')}
        >
          Paypal (w/ Credit Card)
        </a>
      </li>
      {showCOD && (
        <Fragment>
          <li className='nav-item'>
            <a
              className={c({
                'nav-link': true,
                active: transaction_source === 'cod'
              })}
              href='#'
              onClick={e => handleClick(e, 'cod')}
            >
              Cash on Delivery
            </a>
          </li>
        </Fragment>
      )}
      <Fragment>
        <li className='nav-item'>
          <a
            className={c({
              'nav-link': true,
              active: transaction_source === 'bank_deposit'
            })}
            href='#'
            onClick={e => handleClick(e, 'bank_deposit')}
          >
            Bank Deposit
          </a>
        </li>
      </Fragment>
      {!isShipping && (
        <Fragment>
          <li className='nav-item'>
            <a
              className={c({
                'nav-link': true,
                active: transaction_source === 'pay_at_store'
              })}
              href='#'
              onClick={e => handleClick(e, 'pay_at_store')}
            >
              Pay at Store
            </a>
          </li>
        </Fragment>
      )}
    </ul>
  )
}

PaymentOptions.propTypes = {
  form: PropTypes.object,
  handlePaymentOption: PropTypes.func
}

export default PaymentOptions
