import React from 'react'
import PropTypes from 'prop-types'

import range from 'lodash/range'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

class Paginate extends React.Component {
  static propTypes = {
    current_page: PropTypes.number.isRequired,
    total_pages: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    disable: PropTypes.bool.isRequired
  }

  disableKey = page => {
    return page === this.props.current_page || this.props.disable
  }

  changePage = (e, page) => {
    e.preventDefault()
    if (this.disableKey(page)) {
      return
    }
    this.props.changePage(page)
  }

  render() {
    const min = 5
    const max = 5
    const currentPage = this.props.current_page
    const totalPages = this.props.total_pages
    const start = currentPage - min < 0 ? 0 : currentPage - min
    const end = currentPage + max > totalPages ? totalPages : currentPage + max
    const visiblePages = range(start, end)
    return (
      <Pagination size='md'>
        <PaginationItem disabled={this.disableKey(1)}>
          <PaginationLink
            previous
            href='#'
            onClick={e => this.changePage(e, 1)}
          />
        </PaginationItem>
        <PaginationItem disabled={this.disableKey(1)}>
          <PaginationLink
            href='#'
            onClick={e => this.changePage(e, this.props.current_page - 1)}
          >
            Previous
          </PaginationLink>
        </PaginationItem>
        {start !== 0 && (
          <PaginationItem disabled={this.disableKey(1)}>...</PaginationItem>
        )}
        {visiblePages.map(page => (
          <PaginationItem
            key={page}
            active={page + 1 === this.props.current_page}
          >
            <PaginationLink
              href='#'
              onClick={e => this.changePage(e, page + 1)}
            >
              {page + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        {end !== totalPages && (
          <PaginationItem disabled={this.disableKey(1)}>...</PaginationItem>
        )}
        <PaginationItem disabled={this.disableKey(this.props.total_pages)}>
          <PaginationLink
            href='#'
            onClick={e => this.changePage(e, this.props.current_page + 1)}
          >
            Next
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={this.disableKey(this.props.total_pages)}>
          <PaginationLink
            next
            href='#'
            onClick={e => this.changePage(e, this.props.total_pages)}
          />
        </PaginationItem>
      </Pagination>
    )
  }
}

export default Paginate
