import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import ReservationInfo from './ReservationInfo'

export default class ReservationModal extends Component {
  static propTypes = {
    reservations: PropTypes.array.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSubmitReservations: PropTypes.func.isRequired,
    onUpdateReservations: PropTypes.func.isRequired,
    cancelReservationList: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    showModal: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      attributes: this.initializeAttributes(props.attributes, props.currentUser)
    }
  }

  componentWillUpdate(nextProps) {
    if (!nextProps.showModal && this.props.showModal) {
      this.setState({
        attributes: this.initializeAttributes(
          nextProps.attributes,
          nextProps.currentUser
        )
      })
    }

    if (
      !isEmpty(nextProps.attributes) &&
      !isEqual(nextProps.attributes, this.props.attributes)
    ) {
      this.setState({
        attributes: nextProps.attributes
      })
    }
  }

  initializeAttributes = (attributes, currentUser) => {
    const oldAttributes = isEmpty(attributes) ? {} : attributes

    return {
      ...oldAttributes,
      contact_number:
        oldAttributes.contact_number || currentUser.contact_number,
      branch: oldAttributes.branch || currentUser.branch_name,
      delivery_option:
        oldAttributes.delivery_option || currentUser.default_fulfillment
    }
  }

  submitData = event => {
    event.preventDefault()
    if (this.props.attributes) {
      this.props.onUpdateReservations(
        this.state.attributes.id,
        this.state.attributes
      )
    } else {
      this.props.onSubmitReservations(this.state.attributes)
    }
  }

  handleValueChange = (element, key) => {
    const value = element.target.value
    this.setState({
      attributes: {
        ...this.state.attributes,
        [key]: value
      }
    })
  }

  closeModal = () => {
    this.props.onCloseModal()
  }

  confirmation = () => {
    return (
      <Modal
        className='reservation-modal'
        isOpen={this.props.showModal}
        toggle={this.closeModal}
        size='lg'
      >
        <form onSubmit={this.submitData}>
          <ModalHeader toggle={this.closeModal}>
            Confirm Reservations
          </ModalHeader>
          <ModalBody>
            <div className='form-group'>
              <label htmlFor='contact_number'> Phone Number </label>
              <input
                type='text'
                id='contact_number'
                name='contact_number'
                className='form-control'
                value={this.state.attributes.contact_number}
                onChange={e => this.handleValueChange(e, 'contact_number')}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='payment_option'> Delivery Option </label>
              <select
                className='form-control'
                id='delivery_option'
                name='delivery_option'
                value={this.state.attributes.delivery_option}
                onChange={e => this.handleValueChange(e, 'delivery_option')}
                required
              >
                <option value=''> Select a Delivery Option </option>
                <option value='store'> Pickup at Store </option>
                <option value='shipping'> For Shipping </option>
              </select>
            </div>
            {this.state.attributes.delivery_option === 'store' && (
              <div className='form-group'>
                <label htmlFor='branch'> Branch </label>
                <select
                  className='form-control'
                  id='branch'
                  name='branch'
                  value={this.state.attributes.branch}
                  onChange={e => this.handleValueChange(e, 'branch')}
                  required
                >
                  <option value=''> Select a Branch </option>
                  <option value='Robinsons Galleria'>
                    {' '}
                    Robinsons Galleria{' '}
                  </option>
                </select>
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='notes'> Notes </label>
              <textarea
                className='form-control'
                id='notes'
                name='notes'
                rows='3'
                value={this.state.attributes.notes}
                onChange={e => this.handleValueChange(e, 'notes')}
              />
            </div>
            <hr />
            <ReservationInfo
              currentUser={this.props.currentUser}
              attributes={this.state.attributes}
              reservations={this.props.reservations}
            />
            <p className='alert alert-danger'>
              PLEASE NOTE: RESERVATIONS ARE SUBJECT TO RETAILER AVAILABILITY AND
              MAY NOT ALL BE FILLED. PLEASE REST ASSURED THAT WE WILL DO OUR
              BEST TO ACCOMMODATE YOUR RESERVATION.
              <br />
              <br />
              <span>
                By clicking on Confirm below, you are affirming that you read
                and understand the statement above.
              </span>
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              type='button'
              className='btn btn-default'
              onClick={this.closeModal}
            >
              {' '}
              Back to Reservation List
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={
                this.props.loading || this.props.reservations.length === 0
                  ? 'disabled'
                  : ''
              }
            >
              {' '}
              Confirm Reservation{' '}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }

  handleCancelForm = e => {
    e.preventDefault()
    this.props.cancelReservationList(this.props.attributes)
  }

  cancellation = () => {
    return (
      <Modal
        className='reservation-modal'
        isOpen={this.props.showModal}
        toggle={this.closeModal}
        size='lg'
      >
        <form onSubmit={this.handleCancelForm}>
          <ModalHeader toggle={this.closeModal}>Cancel Reservation</ModalHeader>
          <ModalBody>
            <ReservationInfo
              currentUser={this.props.currentUser}
              attributes={this.state.attributes}
              reservations={this.props.reservations}
            />
          </ModalBody>
          <ModalFooter>
            <button type='submit' className='btn btn-danger'>
              Cancel Reservation
            </button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }

  render() {
    if (isEmpty(this.props.attributes)) {
      return this.confirmation()
    } else {
      return this.cancellation()
    }
  }
}
