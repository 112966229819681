import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ProductLayoutSwitcher extends Component {
  render() {
    const { currentLayout, onSwitchLayout } = this.props

    return (
      <div className='btn-group -wide' role='group'>
        <button
          onClick={() => onSwitchLayout('list')}
          className={`btn btn-plain btn-sm ${
            currentLayout === 'list' ? 'active' : ''
          } `}
        >
          <i className='ion-ios-list' />
          <span className='text'>List</span>
        </button>
        <button
          onClick={() => onSwitchLayout('grid')}
          className={`btn btn-plain btn-sm ${
            currentLayout === 'grid' ? 'active' : ''
          } `}
        >
          <i className='ion-ios-grid-view' />
          <span className='text'>Grid</span>
        </button>
      </div>
    )
  }
}

ProductLayoutSwitcher.propTypes = {
  currentLayout: PropTypes.string.isRequired
}
