import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import CartView from '../components/CartView'
import ReservationBox from './ReservationBox'

const defaultState = {
  reservations: [],
  metadata: {
    current_page: 1,
    total_pages: 0,
    products: [],
    categories: [],
    statuses: []
  },
  filters: { status: 'fill', no_order_id: true },
  cartItemsState: 'pending',
  cartItems: [],
  uiState: {
    loading: true,
    modal: false
  },
  navArea: null,
  reservationBoxArea: null
}

class ReservationBoxMount extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired
  }

  setParentState = state => {
    this.setState(state)
  }

  constructor(props) {
    super(props)

    if (document.querySelector("[role='navcart']")) {
      const navArea = document.querySelector("[role='navcart']")
      navArea.innerHTML = ''

      const reservationBoxArea = document.querySelector(
        "[role='reservation-box-area']"
      )
      reservationBoxArea.innerHTML = ''

      this.state = {
        ...defaultState,
        reservationBoxArea,
        navArea
      }
    } else if (!document.querySelector("[role='navcart']")) {
      const reservationBoxArea = document.querySelector(
        "[role='reservation-box-area']"
      )
      reservationBoxArea.innerHTML = ''

      this.state = {
        ...defaultState,
        reservationBoxArea
      }
    }
  }

  render() {
    if (document.querySelector("[role='navcart']")) {
      return [
        ReactDOM.createPortal(
          <CartView count={this.state.cartItems.length} />,
          this.state.navArea
        ),
        ReactDOM.createPortal(
          <ReservationBox
            state={this.state}
            setParentState={this.setParentState}
            user={this.props.user}
            currentUser={this.props.currentUser}
          />,
          this.state.reservationBoxArea
        )
      ]
    }
    return [
      ReactDOM.createPortal(
        <ReservationBox
          state={this.state}
          setParentState={this.setParentState}
          user={this.props.user}
          currentUser={this.props.currentUser}
        />,
        this.state.reservationBoxArea
      )
    ]
  }
}

export default ReservationBoxMount
