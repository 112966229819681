import { combineReducers } from 'redux'
import releases from './releases'
import publishers from './publishers'
import categories from './categories'
import users from './users'
import orders from './orders'
import reservation_lists from './reservation_lists'
import notifications from './notifications'
import modals from './modals'
import admin_products from '../AdminProducts/reducer'
import admin_releases from '../AdminReleases/reducer'
import marketplace from '../Marketplace/reducer'
import reservation_management from '../AdminReservationManagement/reducer'
import admin_shortages from '../AdminShortages/reducer'

const rootReducer = combineReducers({
  releases,
  publishers,
  categories,
  users,
  orders,
  reservation_lists,
  notifications,
  modals,
  admin_products,
  admin_releases,
  marketplace,
  reservation_management,
  admin_shortages
})

export default rootReducer
