import API from './api'
import { onError, serviceHandlers } from './serviceHandlers'
import axios from 'axios'

const ReservationBoxAPI = {
  fetch: async (userId, options, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/reservation_box/${userId}/reservations`
    try {
      const response = await axios.post(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  },
  checkout: async (userId, options, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/reservation_box/${userId}/checkout`
    try {
      const response = await axios.post(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  }
}

export default ReservationBoxAPI
