import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getNotifications } from '../reducers/notifications'

import Notification from '../components/shared/Notification'

class NotificationContainer extends Component {
  renderItems() {
    let items = []

    this.props.notifications.map((item, index) => {
      items.push(
        <Notification key={index} message={item.message} level={item.level} />
      )
    })

    return items
  }

  render() {
    const { notifications } = this.props

    return (
      <div className='notifications-container'>
        {notifications.length === 0 ? '' : this.renderItems()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return getNotifications(state)
}

export default connect(mapStateToProps)(NotificationContainer)

NotificationContainer.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired
    })
  ).isRequired
}
