import {
  RECEIVE_PRODUCTS,
  ADD_TO_CART,
  RECEIVE_SEARCH_PRODUCTS,
  RECEIVE_FILTER_PRODUCTS
} from './actionTypes'
import undoable, { includeAction } from 'redux-undo'

const initialState = {
  products: [],
  productItems: [],
  publishers: [],
  categories: [],
  currentPage: 1,
  paginating: true,
  filtered: false,
  selectedProduct: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.products,
        currentPage: 1,
        paginating: false
      }
    case RECEIVE_FILTER_PRODUCTS:
      return {
        ...state,
        products: action.products,
        currentPage: 1,
        paginating: false,
        filtered: true
      }
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: state.products.concat(action.products),
        currentPage: state.currentPage + 1
      }
    default:
      return state
  }
}

export default undoable(reducer, {
  filter: includeAction(RECEIVE_FILTER_PRODUCTS)
})
