import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import sortBy from 'lodash/sortBy'
import omit from 'lodash/omit'

import ItemRow from './ItemRow'
import CheckoutTable from './CheckoutTable'
import CheckoutForm from './CheckoutForm'

class CheckoutModal extends React.Component {
  propTypes = {
    closeModal: PropTypes.func.isRequired,
    onCartRemove: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cartItems: PropTypes.array.isRequired,
    showModal: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired
  }

  state = {
    attributes: {
      branch: this.props.user.branch_name,
      delivery_option: this.props.user.admin
        ? 'store'
        : this.props.user.default_fulfillment,
      phone_number: this.props.user.contact_number,
      addresses: this.props.user.addresses,
      fulfilled: this.props.currentUser.admin
    }
  }

  updateAttribute = (e, key) => {
    e.preventDefault()
    let attributes = {
      ...this.state.attributes,
      [key]: e.target.value
    }
    if (attributes.delivery_option === 'store') {
      attributes = omit(attributes, ['shipping_address', 'shipping_region'])
    }
    this.setState({ attributes: attributes })
  }

  handleShippingSelect = e => {
    const index = e.target.selectedIndex
    this.setState({
      attributes: {
        ...this.state.attributes,
        shipping_address: e.target.value,
        shipping_region: e.target.options[index].dataset.region
      }
    })
  }

  submitData = e => {
    e.preventDefault()
    const attributes = {
      ...this.state.attributes,
      cart_item_ids: this.props.cartItems.map(item => item.id)
    }
    this.props.onSubmit(attributes)
  }

  render() {
    const sorted = sortBy(this.props.cartItems, ['product_title'])
    return (
      <Modal
        isOpen={this.props.showModal}
        toggle={this.props.closeModal}
        size='lg'
      >
        <form onSubmit={this.submitData}>
          <ModalHeader toggle={this.props.closeModal}>Checkout</ModalHeader>
          <ModalBody>
            <CheckoutForm
              isAdmin={this.props.currentUser.admin}
              attributes={this.state.attributes}
              updateAttribute={this.updateAttribute}
              handleShippingSelect={this.handleShippingSelect}
            />
            <CheckoutTable
              items={sorted}
              user={this.props.user}
              deliveryOption={this.state.attributes.deliveryOption}
              shippingRegion={this.state.attributes.shippingRegion}
            >
              {sorted.map(item => (
                <ItemRow
                  key={item.id}
                  item={item}
                  onCartRemove={this.props.onCartRemove}
                />
              ))}
            </CheckoutTable>
          </ModalBody>
          <ModalFooter>
            <button
              type='button'
              className='btn btn-default'
              onClick={this.props.closeModal}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Confirm
            </button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }
}

export default CheckoutModal
