import * as Types from './actionTypes'
import ReservationsAPI from '../services/reservations_api'
import ProductsAPI from '../services/products_api'
import SearchAPI from '../services/search_api'

const receiveReservations = data => {
  return {
    type: Types.RECEIVE_RESERVATIONS,
    payload: {
      reservations: data.reservations,
      current_page: data.current_page,
      total_pages: data.total_pages
    }
  }
}

export const searchShortedReservations = (
  query = { status: 'short' },
  page = 1
) => {
  return dispatch => {
    dispatch(updateUiState({ search: true }))
    SearchAPI.reservations(
      { search: { ...query, page: page } },
      data => {
        dispatch(receiveReservations(data))
      },
      error => {
        console.log(error)
      },
      () => {
        dispatch(updateUiState({ search: false }))
      }
    )
  }
}

const receiveUpdatedReservation = reservation => {
  return {
    type: Types.RECEIVE_UPDATED_RESERVATION,
    reservation: reservation
  }
}

export const updateReservation = (releaseId, reservationId, data) => {
  return dispatch => {
    ReservationsAPI.update(
      releaseId,
      reservationId,
      { reservation: data, shorted: true },
      data => {
        dispatch(receiveUpdatedReservation(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receiveProducts = (products, requesterId) => {
  return {
    type: Types.RECEIVE_PRODUCTS,
    payload: {
      products: products,
      requesterId: requesterId
    }
  }
}

export const searchProducts = (query, requesterId) => {
  return dispatch => {
    ProductsAPI.generalQuery(
      query,
      data => {
        dispatch(receiveProducts(data, requesterId))
      },
      error => {
        console.error(error)
      }
    )
  }
}

export const updateUiState = newState => {
  return {
    type: Types.UPDATE_UI_STATE,
    payload: newState
  }
}
