import { RECEIVE_CATEGORIES, RESET_STORE } from '../constants/ActionTypes'

const initialState = {
  categories: []
}

export default function categories(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CATEGORIES:
      return { ...state, categories: action.categories }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}
