import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import BoxFilters from '../components/BoxFilters'
import BoxTable from '../components/BoxTable'
import CheckoutModal from '../components/CheckoutModal'
import ReservationBoxAPI from '../../services/reservation_box_api'
import CartItemsAPI from '../../services/cart_items_api'
import { onError } from '../../services/serviceHandlers'

class ReservationBox extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setParentState: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    ReservationBoxAPI.fetch(
      this.props.user.id,
      { search: this.props.state.filters, metadata: 'full' },
      response => {
        this.props.setParentState({
          ...this.props.state,
          reservations: response.data.reservations,
          metadata: response.data.metadata,
          uiState: { ...this.props.state.uiState, loading: false }
        })
      }
    )

    CartItemsAPI.fetch(
      this.props.user.id,
      response => {
        this.props.setParentState({
          ...this.props.state,
          cartItemsState: 'success',
          cartItems: response.data
        })
      },
      () => {
        this.props.setParentState({
          ...this.props.state,
          cartItemsState: 'error'
        })
      }
    )
  }

  onFilter = filters => {
    this.props.setParentState({
      ...this.props.state,
      filters: filters,
      uiState: { ...this.props.state.uiState, loading: true }
    })
    ReservationBoxAPI.fetch(
      this.props.user.id,
      { search: filters, metadata: 'limited' },
      response => {
        this.props.setParentState({
          ...this.props.state,
          reservations: response.data.reservations,
          metadata: {
            ...this.props.state.metadata,
            ...response.data.metadata
          },
          uiState: { ...this.props.state.uiState, loading: false }
        })
      }
    )
  }

  changePage = page => {
    const filters = { ...this.props.state.filters, page: page }
    this.props.setParentState({
      ...this.props.state,
      uiState: { ...this.props.state.uiState, loading: true }
    })
    ReservationBoxAPI.fetch(
      this.props.user.id,
      { search: filters, metadata: 'limited' },
      response => {
        this.props.setParentState({
          ...this.props.state,
          reservations: response.data.reservations,
          metadata: {
            ...this.props.state.metadata,
            current_page: response.data.metadata.current_page,
            total_pages: response.data.metadata.total_pages
          },
          uiState: { ...this.props.state.uiState, loading: false }
        })
      }
    )
  }

  onCartAdd = reservationId => {
    CartItemsAPI.create(
      this.props.user.id,
      {
        cart_item: {
          reservation_id: reservationId,
          user_id: this.props.user.id
        }
      },
      response => {
        this.props.setParentState({
          ...this.props.state,
          cartItems: this.props.state.cartItems.concat(response.data)
        })
      }
    )
  }

  onCartRemove = cartItemId => {
    CartItemsAPI.destroy(this.props.user.id, cartItemId, response => {
      this.props.setParentState({
        ...this.props.state,
        cartItems: this.props.state.cartItems.filter(
          item => cartItemId !== item.id
        )
      })
    })
  }

  openModal = () => {
    this.props.setParentState({
      ...this.props.state,
      uiState: { ...this.props.state.uiState, modal: true }
    })
  }

  closeModal = () => {
    this.props.setParentState({
      ...this.props.state,
      uiState: { ...this.props.state.uiState, modal: false }
    })
  }

  onSubmit = attributes => {
    ReservationBoxAPI.checkout(
      this.props.user.id,
      { reservation_box: attributes },
      response => {
        if (response.data.redirect_to) {
          window.location = response.data.redirect_to
          return null
        }
        this.init()
        return true
      },
      e => {
        if (e.response.data && e.response.data.cart_items) {
          this.props.setParentState({
            ...this.props.state,
            cartItems: e.response.data.cart_items
          })
        }
        onError(e)
      }
    )
  }

  render() {
    const adminShowCheckout =
      this.props.currentUser.id !== this.props.user.id &&
      this.props.currentUser.admin &&
      this.props.state.cartItems.length > 0
    const { state, user, currentUser } = this.props
    const { metadata, cartItems, uiState, filters } = state
    const reservationCartItems = cartItems.filter(item => item.reservation_id)
    return (
      <div className='reservation-box'>
        <div className='d-flex'>
          <h2 className='p-2'>
            {user.full_name}
            's Box
          </h2>
          {adminShowCheckout && (
            <span className='ml-auto p2'>
              <button className='btn btn-success' onClick={this.openModal}>
                Checkout
              </button>
            </span>
          )}
        </div>
        <BoxFilters
          products={metadata.products}
          categories={metadata.categories}
          statuses={metadata.statuses}
          cartItems={cartItems}
          onFilter={this.onFilter}
          loading={uiState.loading}
          filters={filters}
          openModal={this.openModal}
        />
        <BoxTable
          {...state}
          loading={uiState.loading}
          changePage={this.changePage}
          onCartAdd={this.onCartAdd}
          onCartRemove={this.onCartRemove}
        />
        <CheckoutModal
          closeModal={this.closeModal}
          cartItems={reservationCartItems}
          onCartRemove={this.onCartRemove}
          onSubmit={this.onSubmit}
          showModal={uiState.modal}
          user={user}
          currentUser={currentUser}
        />
      </div>
    )
  }
}

export default ReservationBox
