import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InputSelect from '../../AdminProductItem/components/InputSelect'
import NewAddressSection from './NewAddressSection'

const AddressSection = ({
  user,
  handleChange,
  form,
  handleAddressChange,
  addressForm,
  regions,
  selectRegion,
  cities,
  selectCity,
  barangays,
  selectBarangay,
  getFullAddresses
}) => {
  return (
    <Fragment>
      <InputSelect
        label='Saved Addresses'
        name='shipping_address'
        onChange={handleChange}
        value={form.shipping_address}
        options={getFullAddresses}
        placeholder='Make new address'
      />
      {form.shipping_address === '' && (
        <NewAddressSection
          {...{
            addressForm,
            handleAddressChange,
            regions,
            selectRegion,
            cities,
            selectCity,
            barangays,
            selectBarangay
          }}
        />
      )}
    </Fragment>
  )
}

AddressSection.propTypes = {
  user: PropTypes.object,
  handleChange: PropTypes.func,
  form: PropTypes.object,
  handleAddressChange: PropTypes.func,
  addressForm: PropTypes.object,
  regions: PropTypes.array,
  selectRegion: PropTypes.func,
  cities: PropTypes.array,
  selectCity: PropTypes.func,
  barangays: PropTypes.array,
  selectBarangay: PropTypes.func,
  getFullAddresses: PropTypes.array
}

export default AddressSection
