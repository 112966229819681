import {
  RECEIVE_RESERVATION_LISTS,
  DELETE_RESERVATION_LIST
} from '../constants/ActionTypes'

const initialState = {
  reservationLists: []
}

const reservation_lists = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RESERVATION_LISTS:
      return { ...state, reservationLists: action.reservationLists }
    case DELETE_RESERVATION_LIST:
      return initialState
    default:
      return state
  }
}

export default reservation_lists
