import axios from 'axios'
import { apiURL } from '../AdminProductItem/constants'

const API = {
  _getMeta(key) {
    const meta = document.querySelector(`meta[name=${key}]`)
    if (!meta) throw new Error(`API: unable to fetch meta with key: ${key}`)
    return meta.getAttribute('content')
  },

  authorizationHeaders() {},

  baseURL() {
    return window.location.origin
  },

  client(config = {}) {
    return axios.create({
      baseURL: apiURL,
      timeout: 10000,
      ...config
    })
  }
}

export default API
