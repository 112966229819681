import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ProductItem extends Component {
  reservationDetails(prop) {
    return this.props.reservation === undefined
      ? ''
      : this.props.reservation[prop]
  }

  formatData(product, quantity) {
    let data = {}

    data['product_id'] = product.id
    data['reserved'] = true
    data['quantity'] = quantity || 1

    return { reservation: data }
  }

  createOrUpdateReservation(event = { currentTarget: undefined }) {
    const { releaseId, reservation, product } = this.props
    const quantity = parseInt($(event.currentTarget).val())

    if (quantity === 0) {
      reservation !== undefined &&
        this.props.onDeleteReservation(releaseId, reservation.id)
    } else {
      reservation === undefined
        ? this.props.onCreateReservation(
            releaseId,
            this.formatData(product, quantity)
          )
        : this.props.onUpdateReservation(
            releaseId,
            reservation.id,
            this.formatData(product, quantity)
          )
    }
  }

  createOrDeleteReservation() {
    const { releaseId, reservation, product } = this.props

    reservation === undefined
      ? this.props.onCreateReservation(releaseId, this.formatData(product))
      : this.props.onDeleteReservation(releaseId, reservation.id)
  }

  renderOptions(count) {
    let options = []
    for (let i = 0; i <= count; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }
    return options
  }

  renderDescriptionWithBreakLine(text) {
    return text.split('\n').map((item, index) => {
      return (
        <span key={index}>
          {item}
          <br />
        </span>
      )
    })
  }

  featured() {
    const { product } = this.props

    if (product.featured) {
      return <div className='label -featured'>HOT!</div>
    }
  }

  suggested() {
    return null
    // const { product, user } = this.props

    // if (user.series_ids.includes(product.series_id)) {
    //   return <div className='badge -featured'>Collecting</div>
    // }
  }

  renderGrid() {
    const { product, reservation } = this.props
    const {
      id,
      title,
      cover_url,
      publisher_name,
      formatted_price
    } = this.props.product
    const { onBlowUpImage } = this.props

    return (
      <div
        className={`product-item -with-reservation-trigger ${
          reservation === undefined ? '' : '-selected'
        } `}
      >
        <div className='product-details'>
          <img src={cover_url} className='cover' />
          <h4> {title} </h4>
          <div className='details'>
            <strong> {formatted_price} </strong>
            <div className='subline'>{publisher_name}</div>
          </div>
        </div>
        <div className='product-actions'>
          {this.props.reservationList ? null : (
            <button
              onClick={() => this.createOrDeleteReservation()}
              className={`btn ${
                reservation === undefined ? 'btn-primary' : 'btn-danger'
              }`}
            >
              {reservation === undefined ? 'Reserve' : 'Remove'}
            </button>
          )}
          <button onClick={() => onBlowUpImage(id)} className='btn btn-default'>
            View Details
          </button>
        </div>
      </div>
    )
  }

  renderList() {
    const { product, reservation } = this.props
    const {
      id,
      title,
      cover_url,
      publisher_name,
      formatted_price,
      series_id
    } = this.props.product
    const { onBlowUpImage } = this.props

    return (
      <tr className={reservation === undefined ? '' : 'reserved'}>
        {this.props.reservationList ? (
          <td />
        ) : (
          <td>
            <button
              onClick={() => this.createOrDeleteReservation()}
              className={`btn ${
                reservation === undefined ? 'btn-primary' : 'btn-danger'
              }`}
            >
              {reservation === undefined ? 'Reserve' : 'Remove'}
            </button>
          </td>
        )}
        <td onClick={() => onBlowUpImage(id)}>
          <img
            src={product.cover_url}
            className='cover-image'
            onClick={() => onBlowUpImage(id)}
          />
        </td>
        <td className='title -highlight'>
          {product.title}
          {this.featured()}
          {this.suggested()}
          <div className='sub'>{product.creators}</div>
          <hr />
          <div className='sub description'>
            {this.renderDescriptionWithBreakLine(product.description)}
          </div>
        </td>
        <td>{product.formatted_price}</td>
        <td className='hidden-640'>{product.publisher_name}</td>
        <td className='hidden-640'>{product.category_name}</td>
        <td className='hidden-640'>
          {this.props.reservationList ? (
            this.reservationDetails('quantity') || 0
          ) : (
            <select
              value={this.reservationDetails('quantity')}
              onChange={event => this.createOrUpdateReservation(event)}
            >
              {this.renderOptions(3)}
            </select>
          )}
        </td>
      </tr>
    )
  }

  render() {
    const { view } = this.props

    return view === 'grid' ? this.renderGrid() : this.renderList()
  }
}

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  reservation: PropTypes.object,
  // user: PropTypes.object,
  onCreateReservation: PropTypes.func,
  onUpdateReservation: PropTypes.func,
  onDeleteReservation: PropTypes.func,
  onBlowUpImage: PropTypes.func,
  releaseId: PropTypes.number
}
