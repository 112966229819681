import React, { Component } from 'react'
import { Provider } from 'react-redux'

import AdminReleasesContainer from './AdminReleasesContainer'
import DevTools from '../../containers/DevTools'

export default class AdminReleasesRoot extends Component {
  render() {
    const { store, env } = this.props

    return (
      <Provider store={store}>
        <div>
          <AdminReleasesContainer />
          {env === 'development' ? <DevTools /> : ''}
        </div>
      </Provider>
    )
  }
}
