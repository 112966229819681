import qs from 'qs'

import API from '../services/api'

const axiosFileUpload = {
  timeout: 30000
}

const generateFormData = data => {
  const form = new FormData()
  const meta_attributes = data.meta_attributes || {}
  for (let key in data) {
    if (data[key] && key !== 'meta_attributes') {
      form.append(`product[${key}]`, data[key])
    }
  }
  for (let key in meta_attributes) {
    form.append(
      `product[meta_attributes][${key}]`,
      data['meta_attributes'][key]
    )
  }

  return form
}

export const fetchPublishers = _ => {
  const res = API.client().request({
    method: 'get',
    url: '/api/v1/publishers'
  })

  return res
}

export const fetchCategories = _ => {
  const res = API.client().request({
    method: 'get',
    url: '/api/v1/categories'
  })

  return res
}

export const searchProduct = data => {
  const res = API.client().request({
    data: { search_product_lookup: data },
    method: 'post',
    url: '/api/v1/products/lookup'
  })

  return res
}

export const searchSeries = (search, limit = 5) => {
  const queryString = qs.stringify({ search: search, limit: limit })

  const res = API.client().request({
    method: 'get',
    url: `/api/v1/search/series?${queryString}`
  })

  return res
}

export const createSeries = title => {
  const res = API.client().request({
    data: { series: { title } },
    method: 'post',
    url: '/api/v1/series'
  })

  return res
}

export const updateProduct = data => {
  const form = generateFormData(data)

  const res = API.client(axiosFileUpload).request({
    data: form,
    method: 'put',
    url: `/api/v1/products/${data.id}`
  })

  return res
}

export const createProduct = data => {
  const form = generateFormData(data)

  const res = API.client(axiosFileUpload).request({
    data: form,
    method: 'post',
    url: `/api/v1/products`
  })

  return res
}

export const fetchProductItems = productId => {
  const res = API.client().request({
    method: 'get',
    url: `/api/v1/products/${productId}/product_items`
  })

  return res
}

export const createProductItem = data => {
  const res = API.client().request({
    method: 'post',
    data: { product_item: data },
    url: `/api/v1/products/${data.product_id}/product_items`
  })

  return res
}

export const updateProductItem = data => {
  const res = API.client().request({
    method: 'put',
    data: data,
    url: `/api/v1/products/${data.product_id}/product_items/${data.id}`
  })

  return res
}

export const deleteProductItem = ({ product_id, id }) => {
  const res = API.client().request({
    method: 'delete',
    url: `/api/v1/products/${product_id}/product_items/${id}`
  })

  return res
}
