// @flow

import React, { Fragment } from 'react'

import CreatableSelect from 'react-select/lib/Creatable'

import InputText from '../InputText'
import InputCheckbox from '../InputCheckbox'
import TextArea from '../TextArea'
import type { CardProps } from '../../types'

const CardForm = (props: CardProps) => {
  const {
    form,
    isLoading,
    errors,
    onChange,
    onSearchSeries,
    onSelectSeries,
    seriesList
  } = props
  const { title, year, description, issue_number, series } = form
  const initialSeries = series ? { value: series.id, label: series.title } : {}
  const meta_attributes = form.meta_attributes || {}

  return (
    <Fragment>
      <InputText
        label='Title'
        name='title'
        value={title}
        errors={errors.title}
        onChange={onChange}
      />
      <InputText
        label='Card No.'
        name='issue_number'
        value={issue_number}
        errors={errors.issue_number}
        onChange={onChange}
      />
      <InputText
        label='Year'
        name='year'
        value={year}
        errors={errors.year}
        onChange={onChange}
      />
      <div className='form-group'>
        <label>Set</label>
        <CreatableSelect
          defaultValue={initialSeries}
          options={seriesList}
          onChange={onSelectSeries}
          onInputChange={onSearchSeries}
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            return (
              selectOptions.length === 0 && inputValue.length > 0 && !isLoading
            )
          }}
        />
      </div>
      <InputText
        label='Player / Character Name'
        name='meta_attributes-character_name'
        value={meta_attributes.character_name}
        onChange={onChange}
      />
      <InputText
        label='Team / Faction'
        name='meta_attributes-team'
        value={meta_attributes.team}
        onChange={onChange}
      />
      <div className='form-group'>
        <InputCheckbox
          label='Autograph'
          name='meta_attributes-autograph'
          checked={meta_attributes.autograph}
          inline
          onChange={onChange}
        />
        <InputCheckbox
          label='Memorabilia'
          name='meta_attributes-memorabilia'
          checked={meta_attributes.memorabilia}
          inline
          onChange={onChange}
        />
        <InputCheckbox
          label='Rookie Card'
          name='meta_attributes-rookie'
          checked={meta_attributes.rookie}
          inline
          onChange={onChange}
        />
      </div>
      <TextArea
        cols='46'
        label='Description'
        name='description'
        value={description}
        errors={errors.description}
        onChange={onChange}
      />
    </Fragment>
  )
}

export default CardForm
