import React, { Component } from 'react'
import { Provider } from 'react-redux'

import AdminProductsContainer from './AdminProductsContainer'
import DevTools from '../../containers/DevTools'

export default class AdminProductsRoot extends Component {
  render() {
    const { store, env } = this.props

    return (
      <Provider store={store}>
        <div>
          <AdminProductsContainer />
          {env === 'development' ? <DevTools /> : ''}
        </div>
      </Provider>
    )
  }
}
