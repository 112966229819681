import { createStore, applyMiddleware, compose } from 'redux'
import { autoRehydrate } from 'redux-persist'
import thunk from 'redux-thunk'
import createLogger from 'redux-logger'
import rootReducer from '../reducers'
import DevTools from '../containers/DevTools'

export const configureStore = (initialState, env) => {
  if (env === 'development') {
    return createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunk, createLogger()),
        DevTools.instrument(),
        autoRehydrate()
      )
    )
  } else {
    return createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunk, createLogger()), autoRehydrate())
    )
  }
}
