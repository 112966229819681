import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import sortBy from 'lodash/sortBy'

import InputSelect from './InputSelect'
import CardFields from './search/CardFields'
import ComicFields from './search/ComicFields'
import FigureFields from './search/FigureFields'

export default class SearchProductForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    categories: PropTypes.array,
    seriesList: PropTypes.array,
    onChange: PropTypes.func,
    onSearchProduct: PropTypes.func,
    onSearchSeries: PropTypes.func,
    onSelectSeries: PropTypes.func
  }

  renderFields() {
    const {
      onSearchSeries,
      onSelectSeries,
      seriesList,
      form,
      categories
    } = this.props
    const { category_id } = form
    const category = find(categories, { id: parseInt(category_id) })
    const slug = category ? category.slug : null
    const fieldProps = { form, onSearchSeries, onSelectSeries, seriesList }

    switch (slug) {
      case 'cards':
        return <CardFields {...fieldProps} />
      case 'action-figures-collectibles':
        return <FigureFields {...fieldProps} />
      case null:
        return null
      default:
        return <ComicFields {...fieldProps} />
    }
  }

  render() {
    const { onChange, onSearchProduct, form, categories } = this.props
    const { category_id } = form
    const sortedCategories = [...sortBy(categories, ['name'])]
    const categorySelection = [
      { name: 'Select Category', id: '' },
      ...sortedCategories
    ]
    return (
      <Fragment>
        <form onChange={onChange} onSubmit={onSearchProduct}>
          <div className='form-row'>
            <div className='col'>
              <InputSelect
                label='Category'
                name='category_id'
                options={categorySelection}
                value={category_id}
                required
              />
            </div>
          </div>
          {this.renderFields()}
          <button type='submit' style={{ display: 'none' }}>
            Go
          </button>
        </form>
      </Fragment>
    )
  }
}
