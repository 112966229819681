import ProductsAPI from '../services/products_api'
import {
  fetchProducts,
  receiveProducts,
  clearProducts
} from '../actions/products'
import { switchLoading } from '../actions/releases'

export const searchForProducts = (releaseId, params) => {
  return dispatch => {
    dispatch(switchLoading(true))
    ProductsAPI.query(
      releaseId,
      params,
      data => {
        dispatch(clearProducts())
        dispatch(receiveProducts(data, true))
      },
      error => {
        console.error(error)
      },
      () => {
        dispatch(switchLoading(false))
      }
    )
  }
}

export const resetFilter = releaseId => {
  return dispatch => {
    dispatch(clearProducts())
    dispatch(fetchProducts(releaseId, 1))
  }
}
