import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Paginate from '../../components/Paginate'
import BoxTableRow from './BoxTableRow'

class BoxTable extends React.Component {
  static propTypes = {
    reservations: PropTypes.array.isRequired,
    metadata: PropTypes.shape({
      current_page: PropTypes.number.isRequired,
      total_pages: PropTypes.number.isRequired
    }),
    changePage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    cartItems: PropTypes.array.isRequired,
    onCartAdd: PropTypes.func.isRequired,
    onCartRemove: PropTypes.func.isRequired
  }

  render() {
    return (
      <Fragment>
        <table className='box-table table'>
          <thead>
            <tr>
              <th>Item</th>
              <th>Status</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.reservations.map(reservation => (
              <BoxTableRow
                onCartAdd={this.props.onCartAdd}
                onCartRemove={this.props.onCartRemove}
                reservation={reservation}
                cartItem={this.props.cartItems.find(
                  item => item.reservation_id === reservation.id
                )}
                key={reservation.id}
              />
            ))}
          </tbody>
        </table>
        {this.props.reservations.length === 0 ? (
          <div className='alert alert-danger'>Your Box is empty</div>
        ) : (
          <Paginate
            current_page={this.props.metadata.current_page}
            total_pages={this.props.metadata.total_pages}
            changePage={this.props.changePage}
            disable={this.props.loading}
          />
        )}
      </Fragment>
    )
  }
}

export default BoxTable
