import API from './api'

const SearchAPI = {
  reservations(query, onSuccess, onError, onComplete = () => {}) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/search/reservations`,
      data: query,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  }
}

export default SearchAPI
