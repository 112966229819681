import React from 'react'

const CartView = ({ count }) => {
  return (
    <div>
      <i className='icon ion-ios-cart-outline' />
      <span role='cart_counter'> Cart ({count})</span>
    </div>
  )
}

export default CartView
