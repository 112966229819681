import React from 'react'

import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import PropTypes from 'prop-types'
import QuantityOptions from '../../components/shared/QuantityOptions'
import ProductTypeahead from '../../components/shared/ProductTypeahead'

class ReservationRow extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    reservationList: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    searchProduct: PropTypes.func.isRequired,
    searchResults: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      searchResults: []
    }
  }

  componentWillUpdate(nextProps) {
    if (
      nextProps.searchResults &&
      !isEqual(nextProps.searchResults, this.state.searchResults)
    ) {
      this.setState({
        searchResults: nextProps.searchResults
      })
    }
  }

  onSearch = query => {
    this.props.searchProduct(this.props.reservationList.release_id, {
      title: query
    })
  }

  selectProduct = product => {
    this.props.onChange(this.props.reservation, {
      product_id: product.id,
      product: product
    })
  }

  enableModifications() {
    return (
      this.props.reservationList.status === 'open' &&
      this.props.reservation.status !== 'void'
    )
  }

  render() {
    let statusSelect
    const statusMap = {
      for_approval: 'Approved',
      internal: 'Short',
      external: 'Damaged/Lost',
      void: 'Void'
    }

    return (
      <tr>
        <td>
          <div className='checkbox text-center'>
            {this.props.reservationList.status === 'open' ? (
              <label>
                <select
                  defaultValue={this.props.reservation.status}
                  onChange={_ =>
                    this.props.onChange(this.props.reservation, {
                      status: statusSelect.value
                    })
                  }
                  ref={el => (statusSelect = el)}
                >
                  {keys(statusMap).map(status => (
                    <option
                      key={`${this.props.reservation.id}-${status}`}
                      value={status}
                    >
                      {statusMap[status]}
                    </option>
                  ))}
                </select>
              </label>
            ) : (
              <label>{statusMap[this.props.reservation.status]}</label>
            )}
          </div>
        </td>
        <td className='text-center'>
          {this.enableModifications() ? (
            <QuantityOptions
              reservation={this.props.reservation}
              onChange={this.props.onChange}
            />
          ) : (
            <label>{this.props.reservation.quantity}</label>
          )}
        </td>
        <td className='main'>
          <ProductTypeahead
            enableEdit={this.enableModifications()}
            onSearch={this.onSearch}
            reservation={this.props.reservation}
            searchResults={this.state.searchResults}
            selectProduct={this.selectProduct}
          />
        </td>
        <td className='main'>{this.props.reservation.price}</td>
        <td className='comments'>{this.props.reservation.comments}</td>
      </tr>
    )
  }
}

export default ReservationRow
