import { RECEIVE_PUBLISHERS, RESET_STORE } from '../constants/ActionTypes'

const initialState = {
  publishers: []
}

const publishers = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PUBLISHERS:
      return { ...state, publishers: action.publishers }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}

export default publishers
