export const changeId = (currentId, newId) => {
  let newUrl = document.location.href
  newUrl = newUrl.replace(`/${currentId}`, '')

  const newHistory = {
    title: document.title,
    url: `${newUrl}/${newId}`
  }

  history.pushState(newHistory, newHistory.title, newHistory.url)
}
