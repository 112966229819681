export const RECEIVE_RELEASES = 'RECEIVE_RELEASES'
export const REFRESH_RELEASE = 'REFRESH_RELEASE'
export const SWITCH_RELEASES = 'SWITCH_RELEASES'
export const RECEIVE_RESERVATIONS = 'RECEIVE_RESERVATIONS'
export const CREATE_RESERVATION = 'CREATE_RESERVATION'
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION'
export const DELETE_RESERVATION = 'DELETE_RESERVATION'
export const RECEIVE_PUBLISHERS = 'RECEIVE_PUBLISHERS'
export const RECEIVE_PUBLISHER_FILTER = 'RECEIVE_PUBLISHER_FILTER'
export const RECEIVE_RESERVATION_FILTER = 'RECEIVE_RESERVATION_FILTER'
export const RECEIVE_SEARCH_FILTER = 'RECEIVE_SEARCH_FILTER'
export const RECEIVE_FILTER = 'RECEIVE_FILTER'
export const CONFIRM_RESERVATION = 'CONFIRM_RESERVATION'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS'
export const ADD_ORDER = 'ADD_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION'
export const RECEIVE_EMPTY_NOTIFICATIONS = 'RECEIVE_EMPTY_NOTIFICATIONS'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CLEAR_MODAL = 'CLEAR_MODAL'
export const BLOWUP_IMAGE = 'BLOWUP_IMAGE'
export const UPDATE_CURRENT_ORDER = 'UPDATE_CURRENT_ORDER'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_LAYOUT = 'RECEIVE_LAYOUT'
export const RECEIVE_LOADING = 'RECEIVE_LOADING'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const RESET_STORE = 'RESET_STORE'
export const RECEIVE_RESERVATION_LISTS = 'RECEIVE_RESERVATION_LISTS'
export const DELETE_RESERVATION_LIST = 'DELETE_RESERVATION_LIST'
