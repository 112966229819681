import React from 'react'
import PropTypes from 'prop-types'
import CheckoutApi from '../../services/checkout_api'
import PaymentOptionsForm from '../components/PaymentOptionsForm'
import CartItems from '../components/CartItems'
import SweetAlert from '../../components/shared/SweetAlert'

export default class PaymentMethodContainer extends React.Component {
  static propTypes = {
    cartData: PropTypes.object,
    cartItems: PropTypes.array,
    user: PropTypes.object
  }

  state = {
    form: {
      ...this.props.cartData,
      transaction_source: 'paypal'
    },
    disableButton: false
  }

  handlePaymentOption = value => {
    const { form } = this.state

    let newForm = {
      ...form,
      transaction_source: value
    }

    this.setState({
      form: newForm
    })
  }

  handleSubmit = form => {
    const data = {
      order: {
        ...form
      }
    }
    this.setState({ disableButton: true })

    CheckoutApi.create(
      this.props.user.id,
      data,
      response => {
        window.location = response.data.next_url
      },
      error => {
        SweetAlert.error('Error', error)
        this.setState({ disableButton: false })
      }
    )
  }

  render() {
    const { form, disableButton } = this.state
    return (
      <div className='cart-container container'>
        <div className='row justify-content-center'>
          <div className='cart col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-4'>
                <h1>Payment Method</h1>
              </div>
            </div>
            <hr />
            <div className='row'>
              <PaymentOptionsForm
                onSubmit={this.handleSubmit}
                handlePaymentOption={this.handlePaymentOption}
                form={form}
                disableButton={disableButton}
              />
              <div className='col-5'>
                <h2>Order Summary</h2>
                <CartItems
                  cartItems={this.props.cartItems}
                  subTotal={form.sub_total_price}
                  discountTotal={form.discount_total}
                  user={this.props.user}
                  total={form.total_price}
                  deliveryCost={form.shipping_total}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
