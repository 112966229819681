import { RECEIVE_USER, RESET_STORE } from '../constants/ActionTypes'

const initialState = {
  contact_number: null,
  branch_name: null
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_USER:
      return {
        ...state,
        contact_number: action.contact_number,
        branch_name: action.branch_name,
        series_ids: action.series_ids,
        discount_rate: action.discount_rate,
        addresses: action.addresses,
        default_fulfillment: action.default_fulfillment
      }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}

export function getUser(state) {
  return {
    contact_number: state.contact_number,
    branch_name: state.branch_name,
    series_ids: state.series_ids,
    discount_rate: state.discount_rate,
    addresses: state.addresses,
    default_fulfillment: state.default_fulfillment
  }
}
