import React from 'react'
import PropTypes from 'prop-types'

import InputText from '../../AdminProductItem/components/InputText'
import InputSelect from '../../AdminProductItem/components/InputSelect'

const AddressFormRow = ({
  form,
  addressId,
  regions,
  selectRegion,
  selectCity,
  selectBarangay,
  handleChange,
  cities,
  barangays,
  onSubmit,
  houseNumber,
  streetName,
  building,
  region,
  city,
  barangay,
  zipCode
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(form)
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputText
        label='Street/Block/Lot No.*'
        name='house_number'
        value={houseNumber}
        required
        onChange={handleChange}
      />
      <InputText
        label='Street Name*'
        name='street_name'
        value={streetName}
        required
        onChange={handleChange}
      />
      <InputText
        label='Floor, Unit No., and Building Name'
        name='building'
        value={building}
        onChange={handleChange}
      />
      <div className='form-group'>
        <div className='row'>
          <div className='col-6'>
            <InputSelect
              label='Region*'
              name='region'
              value={region}
              required
              onChange={selectRegion}
              options={regions}
              placeholder='Select Region'
            />
          </div>
          <div className='col-6'>
            <InputText
              label='Zip Code*'
              name='zip_code'
              value={zipCode}
              required
              maxLength='4'
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='form-group'>
        <div className='row'>
          <div className='col-6'>
            <InputSelect
              label='City*'
              name='city'
              value={city}
              required
              onChange={selectCity}
              options={cities}
              placeholder='Select City'
            />
          </div>
          <div className='col-6'>
            <InputSelect
              label='Barangay*'
              name='barangay'
              value={barangay}
              required
              onChange={selectBarangay}
              options={barangays}
              placeholder='Select Barangay'
            />
          </div>
        </div>
      </div>
      <div className='actions text-center'>
        <button type='submit' className='btn btn-primary'>
          Submit
        </button>
      </div>
    </form>
  )
}

AddressFormRow.propTypes = {
  form: PropTypes.object,
  addressId: PropTypes.number,
  regions: PropTypes.array,
  selectRegion: PropTypes.func,
  cities: PropTypes.array,
  selectCity: PropTypes.func,
  barangays: PropTypes.array,
  selectBarangay: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  houseNumber: PropTypes.string,
  streetName: PropTypes.string,
  building: PropTypes.string,
  region: PropTypes.string,
  city: PropTypes.string,
  barangay: PropTypes.string,
  zipCode: PropTypes.string
}

export default AddressFormRow
