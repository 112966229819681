import React, { Component } from 'react'
import PropTypes from 'prop-types'

import InputSelect from './InputSelect'
import InputText from './InputText'
import { conditionOptions } from '../constants'

const ProductItemEdit = ({ productItem, handleChange, onUpdate, onCancel }) => {
  const {
    errors,
    sku,
    available,
    condition,
    price,
    location_id,
    description
  } = productItem
  const locationOptions = [
    { name: 'Warehouse', value: '1' },
    { name: 'Galleria', value: '2' }
  ]

  const onChange = e => {
    const { target } = e
    handleChange(target, productItem)
  }

  return (
    <tr>
      <td>
        <InputText
          onChange={onChange}
          value={sku}
          name='sku'
          errors={errors.sku}
          required
        />
      </td>
      <td>
        <InputSelect
          name='condition'
          onChange={onChange}
          options={conditionOptions}
          value={condition}
          errors={errors.condition}
          required
        />
      </td>
      <td>
        <InputText
          name='description'
          onChange={onChange}
          value={description}
          errors={errors.description}
        />
      </td>
      <td>
        <InputText
          onChange={onChange}
          value={price}
          name='price'
          type='number'
          errors={errors.price}
          required
        />
      </td>
      <td>
        <InputSelect
          name='location_id'
          onChange={onChange}
          options={locationOptions}
          value={location_id}
          errors={errors.location_id}
          required
        />
      </td>
      <td>{available ? 'Yes' : 'No'}</td>
      <td>
        <button
          onClick={e => onUpdate(productItem)}
          className='btn btn-xs btn-primary'
        >
          Save
        </button>
        <button
          onClick={e => onCancel(productItem)}
          className='btn btn-xs btn-danger'
        >
          Cancel
        </button>
      </td>
    </tr>
  )
}

ProductItemEdit.propTypes = {
  productItem: PropTypes.object,
  handleChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func
}

export default ProductItemEdit
