import * as Types from './actionTypes'
import { ProductsAPI } from './apiWrappers'
import { openingModal } from '../actions/modals'
import { ActionCreators } from 'redux-undo'

export const fetchProductsIndex = (page = 1) => {
  return dispatch => {
    ProductsAPI.fetch(
      page,
      data => {
        dispatch(receiveProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const fetchProduct = id => {
  return dispatch => {
    ProductsAPI.fetchOne(
      id,
      data => {
        dispatch(receiveProductItem(data))
        dispatch(openingModal(Types.RECEIVE_PRODUCT_ITEM))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const searchProducts = query => {
  return dispatch => {
    ProductsAPI.query(
      query,
      data => {
        dispatch(receiveSearchProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const filterProducts = query => {
  return dispatch => {
    ProductsAPI.filter(
      query,
      data => {
        dispatch(receiveFilterProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const resetProducts = () => {
  return (dispatch, getState) => {
    if (getState().marketplace.present.filtered) {
      dispatch(ActionCreators.undo())
    }
  }
}

const receiveProducts = products => {
  return {
    type: Types.RECEIVE_PRODUCTS,
    products: products
  }
}

const receiveSearchProducts = products => {
  return {
    type: Types.RECEIVE_SEARCH_PRODUCTS,
    products: products
  }
}

const receiveFilterProducts = products => {
  return {
    type: Types.RECEIVE_FILTER_PRODUCTS,
    products: products
  }
}

const receiveProductItem = product => {
  return {
    type: Types.RECEIVE_PRODUCT_ITEM,
    product: product,
    productItems: product.normalized_product_items
  }
}
