import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import uuidv1 from 'uuid/v1'
import produce from 'immer'
import { debounce } from 'throttle-debounce'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'

import ProductForm from '../components/forms/ProductForm'
import Alert from '../components/Alert'
import {
  createProduct,
  updateProduct,
  searchSeries,
  createSeries
} from '../apiWrappers'

const messages = {
  success: 'The product was successfully updated!',
  error: 'Something went wrong. Please try again.'
}

const defaultState = {
  uiState: {
    isLoading: false,
    isSuccess: false,
    isError: false
  },
  form: {},
  errors: {},
  seriesList: []
}

export default class ManageProductContainer extends Component {
  static propTypes = {
    categories: PropTypes.array,
    publishers: PropTypes.array,
    productData: PropTypes.object,
    formData: PropTypes.object,
    onProductUpdate: PropTypes.func
  }

  state = {
    ...defaultState,
    form: isEmpty(this.props.productData)
      ? this.props.formData
      : this.props.productData
  }

  handleChange = e => {
    if (!e.target.name) {
      return
    }

    const { name, type, value, checked } = e.target
    const isMeta = name.match(/meta_attributes/)
    let newState

    if (isMeta) {
      const metaName = name.replace(/meta_attributes-/, '')
      newState = produce(draft => {
        draft.form.meta_attributes = draft.form.meta_attributes || {}
        draft.form.meta_attributes[metaName] =
          type === 'checkbox' ? checked : value
      })
    } else {
      newState = produce(draft => {
        draft.form[name] = value
      })
    }

    this.setState(newState)
  }

  handleDrop = acceptedFiles => {
    const newState = produce(draft => {
      draft.form.cover = acceptedFiles[0]
      draft.form.dropzoneText = acceptedFiles[0].name
    })

    this.setState(newState)
  }

  handleApiCall = async (data, apiFunction) => {
    const { uiState, errors } = defaultState

    this.setState({
      uiState: { ...uiState, isLoading: true },
      errors: errors
    })

    try {
      const response = await apiFunction(data)
      this.setState({
        uiState: { ...uiState, isSuccess: true, isLoading: false },
        form: response.data
      })
      this.props.onProductUpdate(response.data)
    } catch (err) {
      this.setState({
        uiState: { ...uiState, isError: true, isLoading: false },
        errors: err.response.data
      })
      console.error(err)
    }
  }

  handleUpdateProduct = data => {
    this.handleApiCall(data, updateProduct)
  }

  handleCreateProduct = data => {
    this.handleApiCall(data, createProduct)
  }

  handleSearchSeries = debounce(300, async search => {
    const { uiState } = this.state

    if (search.length === 0) {
      return
    }

    this.setState({
      uiState: { ...uiState, isLoading: true }
    })

    try {
      const response = await searchSeries(search, 50)
      const series = response.data.series
      const formatted = series.map(object => {
        return { value: object.id, label: object.title }
      })
      this.setState({ seriesList: formatted })
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        uiState: { ...uiState, isLoading: false }
      })
    }
  })

  handleCreateSeries = async newSeries => {
    const { seriesList } = this.state

    try {
      const response = await createSeries(newSeries.title)
      const { data } = response
      const index = findIndex(seriesList, { uid: newSeries.uid })
      const newState = produce(draft => {
        draft.form.series_id = data.id
        draft.seriesList[index] = {
          value: data.id,
          label: data.title
        }
      })
      this.setState(newState)
    } catch (err) {
      console.error(err)
    }
  }

  handleSelectSeries = series => {
    const { seriesList } = this.state

    if (series.label === series.value) {
      const uid = uuidv1()
      const newSeries = { ...series, uid, id: uid, title: series.label }
      const newSeriesList = [newSeries, ...seriesList]

      const newState = produce(draft => {
        draft.form.series_id = newSeries.id
        draft.seriesList = newSeriesList
      })

      return this.setState(newState, () => this.handleCreateSeries(newSeries))
    }
    this.setState(
      produce(draft => {
        draft.form.series_id = series.value
      })
    )
  }

  render() {
    const { categories, publishers, productData } = this.props
    const { uiState, errors, form, seriesList } = this.state
    const { isLoading, isError, isSuccess } = uiState
    const onSubmit =
      productData && productData.id
        ? this.handleUpdateProduct
        : this.handleCreateProduct
    const formProps = {
      categories,
      publishers,
      seriesList,
      form,
      isLoading,
      errors,
      onSubmit
    }

    return (
      <Fragment>
        {isSuccess && <Alert type='success' message={messages.success} />}
        {isError && <Alert type='error' message={messages.error} />}
        <ProductForm
          {...formProps}
          onChange={this.handleChange}
          onDrop={this.handleDrop}
          onSearchSeries={this.handleSearchSeries}
          onSelectSeries={this.handleSelectSeries}
        />
      </Fragment>
    )
  }
}
