/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import App from './react/App'

$.onmount("[role='reservation-mount']", function() {
  App.reservationMount()
})

$.onmount("[role='release-mount']", function() {
  App.releaseMount()
})

$.onmount("[role='admin-products-mount']", function() {
  App.adminProductsMount()
})

$.onmount("[role='admin-releases-mount']", function() {
  App.adminReleasesMount()
})

$.onmount("[role='admin-product-mount']", function() {
  App.adminProductItemMount()
})

$.onmount("[role='marketplace-mount']", function() {
  App.marketplace()
})

$.onmount("[role='chosen-select']", function() {
  $(this).chosen()
})

$.onmount("[role='autoexpand']", function() {
  $(this).autoexpand()
})

$.onmount("[role='admin-reservation-management-mount']", function() {
  App.adminReservationManagementMount(
    "[role='admin-reservation-management-mount']"
  )
})

$.onmount("[role='admin-shortages-mount']", function() {
  App.adminShortagesMount("[role='admin-shortages-mount']")
})

$.onmount("[role='reservation-box']", function() {
  App.reservationBox(this)
})

$.onmount("[role='addresses-mount']", function() {
  App.addressesMount(this)
})

$.onmount("[role='cart-box']", function() {
  App.cartBoxMount(this)
})

$.onmount("[role='carousel-mount']", function() {
  App.carouselMount(this)
})