import React from 'react'
import PropTypes from 'prop-types'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'

class ProductTypeahead extends React.Component {
  static propTypes = {
    enableEdit: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    reservation: PropTypes.object.isRequired,
    searchResults: PropTypes.array,
    selectProduct: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      editProduct: false
    }
  }

  editProduct = setting => {
    this.setState({
      editProduct: setting
    })
  }

  renderOptions = option => {
    return (
      <div key={option.id}>
        <span>{option.title}</span>
      </div>
    )
  }

  onChange = product => {
    this.editProduct(false)
    this.props.selectProduct(product[0])
  }

  render() {
    !this.props.reservation.product && console.log(this.props.reservation.id)
    if (!this.props.enableEdit) {
      return <span>{this.props.reservation.product.title}</span>
    }
    return (
      <div className='row'>
        <div className='col-sm-10'>
          {this.state.editProduct ? (
            <AsyncTypeahead
              placeholder={'Search for a new product'}
              onSearch={this.props.onSearch}
              options={this.props.searchResults || []}
              renderMenuItemChildren={this.renderOptions}
              onChange={this.onChange}
              labelKey={'title'}
              useCache={false}
              delay={500}
            />
          ) : (
            this.props.reservation.product.title
          )}
        </div>
        <div className='col-sm-2 text-right'>
          <button
            className='btn btn-outline-success btn-sm'
            onClick={() => this.editProduct(!this.state.editProduct)}
          >
            {this.state.editProduct ? (
              <i className='ion-close' />
            ) : (
              <i className='ion-edit' />
            )}
          </button>
        </div>
      </div>
    )
  }
}

export default ProductTypeahead
