import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closeModal } from '../actions/modals'

class ModalContainer extends Component {
  componentDidUpdate() {
    this.props.modalOpen
      ? $('.special-modal').modal('show')
      : $('.special-modal').modal('hide')
  }

  render() {
    const { children } = this.props

    return (
      <div
        className='modal fade special-modal'
        tabIndex='-1'
        role='dialog'
        data-keyboard='true'
      >
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-body'>{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

ModalContainer.propTypes = {
  modalOpen: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    modalOpen: state.modals.modalOpen,
    children: state.modals.children
  }
}

export default connect(mapStateToProps, { closeModal })(ModalContainer)
