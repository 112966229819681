import API from '../services/api'

export const ReleasesAPI = {
  fetch(page, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases?type=all&page=${page}`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  update(releaseId, data, onSuccess, onError, onComplete) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}`,
      type: 'PUT',
      headers: API.authorizationHeaders(),
      dataType: 'json',
      data: data,
      cache: false,
      success: onSuccess,
      error: onError,
      complete: onComplete
    })
  },
  unconfirmedUsers(releaseId, onSuccess, onError) {
    $.ajax({
      url: `${API.baseURL()}/api/v1/releases/${releaseId}/unconfirmed_users`,
      headers: API.authorizationHeaders(),
      dataType: 'json',
      success: onSuccess,
      error: onError
    })
  }
}
