import * as Types from '../constants/ActionTypes'
import CategoriesAPI from '../services/categories_api'

export const fetchCategories = () => {
  return dispatch => {
    CategoriesAPI.fetch(
      data => {
        dispatch(receiveCategories(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receiveCategories = categories => {
  return {
    type: Types.RECEIVE_CATEGORIES,
    categories: categories
  }
}
