import React from 'react'
import PropTypes from 'prop-types'

import Dropzone from 'react-dropzone'
import find from 'lodash/find'

import InputText from '../InputText'
import InputSelect from '../InputSelect'
import CardForm from './CardForm'
import ComicForm from './ComicForm'
import FigureForm from './FigureForm'

const messages = {
  dropzone: 'Drag & drop cover to upload'
}

const ProductForm = ({
  categories,
  publishers,
  seriesList,
  form,
  isLoading,
  onSubmit,
  onChange,
  onSearchSeries,
  onSelectSeries,
  onDrop,
  errors
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(form)
  }

  const renderFields = () => {
    const { category_id } = form
    const category = find(categories, { id: parseInt(category_id) })
    const formProps = {
      form,
      isLoading,
      errors,
      onChange,
      onSearchSeries,
      onSelectSeries,
      seriesList
    }

    if (!category) {
      return null
    }

    switch (category.slug) {
      case 'cards':
        return <CardForm {...formProps} />
      case 'action-figures-collectibles':
        return <FigureForm {...formProps} />
      case null:
        return null
      default:
        return <ComicForm {...formProps} />
    }
  }

  const {
    price,
    category_id,
    cover_price,
    cover_url,
    publisher_id,
    cover
  } = form

  return (
    <div className='col-12'>
      <br />
      <h3> Product Details </h3>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-4'>
            <Dropzone
              className='dropzone-cover form-control'
              accept='image/*'
              multiple={false}
              onDrop={onDrop}
            >
              <small className='cover-notice'>{messages.dropzone}</small>
            </Dropzone>
            {cover ? (
              <img className='cover-image -with-cover' src={cover.preview} />
            ) : (
              <img className='cover-image -with-cover' src={cover_url} />
            )}
          </div>
          <div className='col-4'>{renderFields()}</div>
          <div className='col-4'>
            <InputText
              label='Base Price'
              name='price'
              value={price}
              errors={errors.price}
              onChange={onChange}
            />
            <InputText
              label='Cover Price'
              name='cover_price'
              value={cover_price}
              errors={errors.cover_price}
              onChange={onChange}
            />
            {categories && (
              <InputSelect
                label='Category'
                name='category_id'
                options={categories}
                value={category_id}
                onChange={onChange}
              />
            )}
            {publishers && (
              <InputSelect
                label='Publisher'
                name='publisher_id'
                options={publishers}
                value={publisher_id}
                onChange={onChange}
              />
            )}
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isLoading}
            >
              {isLoading ? 'Loading' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

ProductForm.propTypes = {
  categories: PropTypes.array,
  publishers: PropTypes.array,
  seriesList: PropTypes.array,
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onSearchSeries: PropTypes.func,
  onSelectSeries: PropTypes.func,
  onDrop: PropTypes.func,
  errors: PropTypes.object
}

export default ProductForm
