import PropTypes from 'prop-types'
import React, { Component } from 'react'

import BlankSlate from '../../components/shared/BlankSlate'
import {
  calculateShippingFee,
  calculateDiscount
} from '../../utilities/summaryCalculations'
import { formatCurrency } from '../../presenters/format'

export default class ReservationInfo extends Component {
  static propTypes = {
    reservations: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired
  }

  renderInfo = () => {
    let items = []
    let totalPrice = 0.0
    let quantity = 0

    this.props.reservations.map(reservation => {
      quantity += reservation.quantity
      totalPrice += parseFloat(reservation.product.price * reservation.quantity)
      items.push(
        <tr key={reservation.id}>
          <td> {reservation.product.title} </td>
          <td> {reservation.quantity} </td>
          <td> {formatCurrency(reservation.product.price)} </td>
        </tr>
      )
    })

    const discount_rate = +this.props.currentUser.discount_rate
    if (discount_rate > 0) {
      const discounted_amount = calculateDiscount(discount_rate, totalPrice)
      totalPrice = totalPrice - discounted_amount

      items.push(
        <tr key='discount'>
          <td>{discount_rate}% Discount</td>
          <td />
          <td>{formatCurrency(discounted_amount)}</td>
        </tr>
      )
    }

    const { delivery_option, shipping_region } = this.props.attributes

    let shipping_fee = calculateShippingFee(shipping_region)
    if (delivery_option === 'shipping' && shipping_fee > 0) {
      totalPrice = totalPrice + shipping_fee
      items.push(
        <tr key='shipping'>
          <td>Shipping</td>
          <td />
          <td>{formatCurrency(shipping_fee)}</td>
        </tr>
      )
    }

    items.push(
      <tr className='total' key='total'>
        <td>Total</td>
        <td>{quantity}</td>
        <td>
          <strong>{formatCurrency(totalPrice)}</strong>
        </td>
      </tr>
    )

    return (
      <table className='table table-striped orders'>
        <tbody>{items}</tbody>
      </table>
    )
  }

  renderBlankInfo = () => {
    return <BlankSlate message='You currently have no reservations' />
  }

  render() {
    return (
      <div>
        {this.props.reservations.length === 0
          ? this.renderBlankInfo()
          : this.renderInfo()}
      </div>
    )
  }
}
