import API from './api'
import { onError, serviceHandlers } from './serviceHandlers'
import axios from 'axios'

const CartItemAPI = {
  fetch: async (userId, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/cart_items`
    try {
      const response = await axios.get(url)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  },
  create: async (userId, options, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/cart_items`
    try {
      const response = await axios.post(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  },
  destroy: async (userId, cartItemId, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/cart_items/${cartItemId}`
    try {
      const response = await axios.delete(url)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  },
  destroy_all: async (userId, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/cart_items`
    try {
      const response = await axios.delete(url)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  }
}

export default CartItemAPI
