import React from 'react'

import isEqual from 'lodash/isEqual'
import times from 'lodash/times'
import PropTypes from 'prop-types'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

class NewReservationRow extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    releaseId: PropTypes.number.isRequired,
    searchProduct: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
    createReservation: PropTypes.func.isRequired,
    searchResults: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      editProduct: true,
      searchResults: [],
      attributes: props.reservation
    }
  }

  componentWillUpdate(nextProps) {
    if (
      nextProps.searchResults &&
      !isEqual(nextProps.searchResults, this.state.searchResults)
    ) {
      this.setState({
        searchResults: nextProps.searchResults
      })
    }
  }

  handleProductSearch = query => {
    this.props.searchProduct(this.props.releaseId, { title: query })
  }

  renderOptions = option => {
    return (
      <div key={option.id}>
        <span>{option.title}</span>
      </div>
    )
  }

  selectProduct = product => {
    const selected = product[0]
    this.setState({
      attributes: {
        ...this.state.attributes,
        product_id: selected.id,
        product: selected
      }
    })
    this.editProduct(false)
    this.onChange(this.state.attributes, {
      product_id: selected.id,
      product: selected
    })
  }

  onChange = (reservation, params) => {
    this.setState({
      attributes: {
        ...this.state.attributes,
        ...params
      }
    })
  }

  editProduct = setting => {
    this.setState({
      editProduct: setting
    })
  }

  renderQuantityOptions(count = 3) {
    return times(count, i => {
      return (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      )
    })
  }

  renderProductInput() {
    if (this.state.editProduct) {
      return (
        <div className='row'>
          <div className='col-sm-11'>
            <AsyncTypeahead
              placeholder={'Search for a new product'}
              onSearch={this.handleProductSearch}
              options={this.state.searchResults || []}
              renderMenuItemChildren={this.renderOptions}
              onChange={this.selectProduct}
              labelKey={'title'}
              useCache={false}
              delay={500}
            />
          </div>
          <div className='col-sm-1'>
            <button
              className='btn btn-outline-success btn-sm'
              onClick={() => this.editProduct(false)}
            >
              <i className='ion-close' />
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-sm-11'>{this.state.attributes.product.title}</div>
          <div className='col-sm-1'>
            <button
              className='btn btn-outline-success btn-sm'
              onClick={() => this.editProduct(true)}
            >
              <i className='ion-edit' />
            </button>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <tr>
        <td />
        <td className='text-center'>
          <div>
            <select
              className='custom-select'
              value={this.state.attributes.quantity}
              onChange={e =>
                this.onChange(this.state.attributes, {
                  quantity: e.target.value
                })
              }
            >
              {this.renderQuantityOptions()}
            </select>
          </div>
        </td>
        <td className='main'>{this.renderProductInput()}</td>
        <td colSpan='2'>
          <button
            className='btn btn-success btn-sm mr-15'
            onClick={() => this.props.createReservation(this.state.attributes)}
            disabled={!this.state.attributes.product.id}
          >
            Create
          </button>

          <button
            className='btn btn-danger btn-sm'
            onClick={() => this.props.removeEntry(this.state.attributes)}
          >
            Remove
          </button>
        </td>
      </tr>
    )
  }
}

export default NewReservationRow
