import API from './api'
import axios from 'axios'
import { onError, serviceHandlers } from './serviceHandlers'

const CheckoutApi = {
  create: async (userId, options, onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/users/${userId}/checkout`
    try {
      const response = await axios.post(url, options)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  }
}

export default CheckoutApi
