import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'
import InputText from '../InputText'

const CardFields = ({ form, onSearchSeries, onSelectSeries, seriesList }) => {
  const { title, issue_number } = form
  return (
    <Fragment>
      <div className='form-row'>
        <InputText col label='Title' name='title' value={title} />
        <div className='form-group col'>
          <label>Set</label>
          <Select
            options={seriesList}
            onChange={onSelectSeries}
            onInputChange={onSearchSeries}
          />
        </div>
        <InputText
          col
          label='Card No.'
          name='issue_number'
          value={issue_number}
        />
      </div>
    </Fragment>
  )
}

CardFields.propTypes = {
  form: PropTypes.object.isRequired
}
export default CardFields
