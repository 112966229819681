import API from './api'
import axios from 'axios'
import { onError, serviceHandlers } from './serviceHandlers'

const LocationsAPI = {
  fetch: async (onSuccess, onException = onError) => {
    const url = `${API.baseURL()}/api/v1/locations`
    try {
      const response = await axios.get(url)
      serviceHandlers(response, onSuccess, onException)
    } catch (e) {
      onException(e)
    }
  }
}

export default LocationsAPI
