import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import SearchProductContainer from './SearchProductContainer'
import ManageProductContainer from './ManageProductContainer'
import ManageProductItemContainer from './ManageProductItemContainer'
import Alert from '../components/Alert'

const messages = {
  productNotExisting:
    'Product was not found. Proceed below to create the product manually.'
}

const defaultState = {
  displayForm: false,
  productData: {},
  formData: {}
}

export default class AdminProductItemContainer extends Component {
  static propTypes = {
    publishers: PropTypes.array,
    categories: PropTypes.array
  }

  state = defaultState

  handleSearchResult = data => {
    this.setState(data)
  }

  handleProductUpdate = data => {
    this.setState({ productData: data })
  }

  render() {
    const { productData, displayForm, formData } = this.state
    const { categories, publishers } = this.props
    const isExisting = productData && productData.id

    return (
      <Fragment>
        <h3> Search Product </h3>
        <hr />
        <SearchProductContainer
          onSearchResult={this.handleSearchResult}
          categories={categories}
        />
        {displayForm && !isExisting && (
          <Fragment>
            <br />
            <Alert type='error' message={messages.productNotExisting} />
            <hr />
          </Fragment>
        )}
        {displayForm && (
          <ManageProductContainer
            categories={categories}
            publishers={publishers}
            productData={productData}
            onProductUpdate={this.handleProductUpdate}
            formData={formData}
          />
        )}
        {displayForm && isExisting && (
          <ManageProductItemContainer productData={productData} />
        )}
      </Fragment>
    )
  }
}
