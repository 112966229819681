import PropTypes from 'prop-types'
import React, { Component } from 'react'
import numeral from 'numeral'

export default class ProductDetailItem extends Component {
  render() {
    const { price, condition } = this.props.productItem
    const conditionName = {
      NM: 'Near Mint',
      VF: 'Very Fine',
      F: 'Fine',
      VG: 'Very Good',
      G: 'Good',
      P: 'Poor'
    }

    return (
      <tr>
        <td> {conditionName[condition]} </td>
        <td> {numeral(price).format('₱0,0.00')} </td>
        <td>
          <button className='btn btn-primary btn-xs'>Add To Cart</button>
        </td>
      </tr>
    )
  }
}
