import naturalSort from 'javascript-natural-sort'

export const getRegions = regions => {
  const regionsData = Object.keys(regions)
    .filter(regions => regions !== 'Metro Manila')
    .sort()
  return ['Metro Manila', ...regionsData].map(regionsData => ({
    name: regionsData
  }))
}

export const getCities = (region, locations) => {
  return Object.keys(locations[region])
    .sort()
    .map(citiesData => ({
      name: citiesData
    }))
}

export const getBarangays = (region, city, locations) => {
  return Object.values(locations[region][city])
    .sort(naturalSort)
    .map(barangaysData => ({ name: barangaysData }))
}
