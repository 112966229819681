export const apiURL = window.location.origin

export const conditionOptions = [
  { value: 'NM', name: 'NM' },
  { value: 'VF', name: 'VF' },
  { value: 'F', name: 'F' },
  { value: 'VG', name: 'VG' },
  { value: 'G', name: 'G' },
  { value: 'P', name: 'P' },
  { value: 'N/A', name: 'N/A' }
]
