import React from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'

const ProductItem = ({ productItem, onEdit, onDelete, onUpdate }) => {
  const {
    available,
    condition,
    price,
    id,
    sku,
    location,
    description
  } = productItem

  const onToggle = e => {
    onUpdate({
      ...productItem,
      available: !productItem.available
    })
  }

  const realSku = isEmpty(sku) ? id : sku

  return (
    <tr>
      <td>{realSku}</td>
      <td>{condition}</td>
      <td>{description}</td>
      <td>{numeral(price).format('0,0.00')}</td>
      <td>{location.name}</td>
      <td>{available ? 'Yes' : 'No'}</td>
      <td>
        <button
          onClick={e => onEdit(productItem)}
          className='btn btn-xs btn-primary'
        >
          Edit
        </button>
        &nbsp;
        <button onClick={onToggle} className='btn btn-xs btn-default'>
          Toggle
        </button>
        &nbsp;
        <button
          onClick={e => onDelete(productItem)}
          className='btn btn-xs btn-danger'
        >
          Delete
        </button>
      </td>
    </tr>
  )
}

ProductItem.propTypes = {
  productItem: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func
}

export default ProductItem
