import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import CartView from '../../ReservationBox/components/CartView'
import CartBox from './CartBox'
import PaymentMethodContainer from './PaymentMethodContainer'

export default class CartBoxMount extends React.Component {
  static propTypes = {
    user: PropTypes.object
  }

  setParentState = state => {
    this.setState(state)
  }

  state = {
    cartItems: [],
    navArea: null,
    cartBoxArea: null,
    showCartForm: true,
    cartData: {}
  }

  componentDidMount() {
    const cartBoxArea = document.querySelector("[role='cart-box']")
    const navArea = document.querySelector("[role='navcart']")
    if (cartBoxArea) this.setState({ cartBoxArea: cartBoxArea })
    if (navArea) this.setState({ navArea: navArea })
    navArea.innerHTML = ''
    cartBoxArea.innerHTML = ''
  }

  render() {
    const {
      navArea,
      cartBoxArea,
      showCartForm,
      cartData,
      cartItems
    } = this.state
    if (navArea && cartBoxArea && showCartForm) {
      return [
        ReactDOM.createPortal(<CartView count={cartItems.length} />, navArea),
        ReactDOM.createPortal(
          <CartBox
            setParentState={this.setParentState}
            user={this.props.user}
          />,
          cartBoxArea
        )
      ]
    } else if (navArea && cartBoxArea) {
      return [
        ReactDOM.createPortal(<CartView count={cartItems.length} />, navArea),
        ReactDOM.createPortal(
          <PaymentMethodContainer
            cartData={cartData}
            cartItems={cartItems}
            user={this.props.user}
          />,
          cartBoxArea
        )
      ]
    }
    return null
  }
}
