import {
  RECEIVE_NOTIFICATION,
  RECEIVE_EMPTY_NOTIFICATIONS,
  RESET_STORE
} from '../constants/ActionTypes'

const initialState = {
  messages: []
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATION:
      return { ...state, messages: state.messages.concat(action.message) }
    case RECEIVE_EMPTY_NOTIFICATIONS:
      return { ...state, messages: [] }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}

export const getNotifications = state => {
  return {
    notifications: state.notifications.messages
  }
}

export default notifications
