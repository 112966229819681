import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loading from 'react-loading'

export default class LoadingScreen extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    return (
      <div className='loading-screen'>
        <Loading type='bars' color='#7F8C8D' height='200px' width='200px' />
        <p className='text'>Loading...</p>
      </div>
    )
  }
}
