import {
  RECEIVE_PRODUCTS,
  RECEIVE_PRODUCT,
  REMOVE_PRODUCT,
  RECEIVE_PUBLISHERS,
  RECEIVE_CATEGORIES,
  RECEIVE_RELEASE_ID
} from './actionTypes'
import filter from 'lodash/filter'

const initialState = {
  products: [],
  categories: [],
  publishers: [],
  releaseId: undefined
}

const product_reducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCT:
      if (state.id !== action.product.id) {
        return state
      }
      return {
        ...state,
        cover_url: action.product.cover_url,
        featured: action.product.featured
      }
    default:
      return state
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RELEASE_ID:
      return { ...state, releaseId: action.releaseId }
    case RECEIVE_PUBLISHERS:
      return { ...state, publishers: action.publishers }
    case RECEIVE_CATEGORIES:
      return { ...state, categories: action.categories }
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products }
    case RECEIVE_PRODUCT:
      return {
        ...state,
        products: state.products.map(product => {
          return product_reducer(product, action)
        })
      }
    case REMOVE_PRODUCT:
      return {
        ...state,
        products: filter(state.products, product => {
          return product.id !== action.productId
        })
      }
    default:
      return state
  }
}

export default reducer
