import findIndex from 'lodash/findIndex'

import {
  RECEIVE_RELEASE,
  RECEIVE_RESERVATION_LISTS,
  RECEIVE_UPDATED_RESERVATION_LIST,
  RECEIVE_PRODUCTS,
  TOGGLE_LOADING
} from './actionTypes'
import updateObjectInArray from '../utilities/updateObjectInArray'

const initialState = {
  release: {},
  results: {
    reservation_lists: [],
    page: 1,
    total_pages: 1,
    total_count: 0,
    checked_count: 0,
    open_count: 0
  },
  modal: {},
  editing: false,
  loading: false
}

const reducer = (state = initialState, action) => {
  let updatedReservationLists, reservationListIndex

  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case RECEIVE_RELEASE:
      return {
        ...state,
        release: state.release
      }
    case RECEIVE_RESERVATION_LISTS:
      return {
        ...state,
        results: action.payload
      }
    case RECEIVE_UPDATED_RESERVATION_LIST:
      reservationListIndex = findIndex(state.results.reservation_lists, {
        id: action.reservation_list.id
      })
      updatedReservationLists = updateObjectInArray(
        state.results.reservation_lists,
        {
          index: reservationListIndex,
          item: action.reservation_list
        }
      )

      return {
        ...state,
        results: {
          ...state.results,
          reservation_lists: updatedReservationLists
        }
      }
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        searchResults: action.products
      }
    default:
      return state
  }
}

export default reducer
