import * as Types from './actionTypes'
import ReleasesAPI from '../services/releases_api'
import ReservationListsAPI from '../services/reservation_lists_api'
import ReservationsAPI from '../services/reservations_api'
import ProductsAPI from '../services/products_api'

const receiveRelease = release => {
  return {
    type: Types.RECEIVE_RELEASE,
    release: release
  }
}

export const fetchRelease = releaseId => {
  return dispatch => {
    ReleasesAPI.fetchOne(
      releaseId,
      data => {
        dispatch(receiveRelease(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receiveReservationLists = data => {
  return {
    type: Types.RECEIVE_RESERVATION_LISTS,
    payload: {
      reservation_lists: data.results,
      page: data.page,
      total_count: data.total_count,
      total_pages: data.total_pages,
      open_count: data.open_count,
      checked_count: data.checked_count
    }
  }
}

export const fetchReservationLists = (releaseId, options = {}) => {
  return dispatch => {
    dispatch(toggleLoading(true))
    ReservationListsAPI.fetchReleaseReservationLists(
      releaseId,
      { type: 'admin', ...options },
      data => {
        dispatch(receiveReservationLists(data))
      },
      error => {
        console.error(error)
      },
      () => {
        dispatch(toggleLoading(false))
      }
    )
  }
}

export const fetchReservationList = reservationListId => {
  return dispatch => {
    ReservationListsAPI.show(
      reservationListId,
      data => {
        dispatch(receiveUpdatedReservationList(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const updateReservation = (reservationListId, reservationId, data) => {
  return dispatch => {
    ReservationsAPI.updateReservationListReservation(
      reservationListId,
      reservationId,
      { reservation: data },
      () => {
        dispatch(fetchReservationList(reservationListId))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const receiveUpdatedReservationList = reservation_list => {
  return {
    type: Types.RECEIVE_UPDATED_RESERVATION_LIST,
    reservation_list: reservation_list
  }
}

export const updateReservationList = (
  reservationListId,
  data,
  options = {}
) => {
  return dispatch => {
    ReservationListsAPI.update(
      reservationListId,
      { reservation_list: data },
      data => {
        dispatch(fetchReservationLists(data.release_id, options))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const approveReleaseReservationLists = (releaseId, options = {}) => {
  return dispatch => {
    dispatch(toggleLoading(true))
    ReleasesAPI.approveReservationLists(
      releaseId,
      () => {
        dispatch(fetchReservationLists(releaseId, options))
      },
      error => {
        console.error(error)
      },
      () => {
        dispatch(toggleLoading(false))
      }
    )
  }
}

const receiveProducts = products => {
  return {
    type: Types.RECEIVE_PRODUCTS,
    products: products
  }
}

export const searchProduct = (releaseId, query) => {
  return dispatch => {
    ProductsAPI.query(
      releaseId,
      query,
      data => {
        dispatch(receiveProducts(data))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

export const createReservation = (reservationListId, reservation) => {
  return dispatch => {
    ReservationsAPI.createReservationListReservation(
      reservationListId,
      reservation,
      () => {
        dispatch(fetchReservationList(reservationListId))
      },
      error => {
        console.error(error)
      },
      () => {}
    )
  }
}

const toggleLoading = loading => {
  return {
    type: Types.TOGGLE_LOADING,
    loading: loading
  }
}
