import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InputText from '../../AdminProductItem/components/InputText'
import InputSelect from '../../AdminProductItem/components/InputSelect'

const NewAddressSection = ({
  handleAddressChange,
  addressForm,
  regions,
  selectRegion,
  cities,
  selectCity,
  barangays,
  selectBarangay
}) => {
  return (
    <Fragment>
      <div className='row'>
        <div className='col-4'>
          <InputText
            label='Street/Block/Lot No.*'
            name='house_number'
            value={addressForm.house_number}
            required
            onChange={handleAddressChange}
          />
        </div>
        <div className='col-8'>
          <InputText
            label='Street Name*'
            name='street_name'
            value={addressForm.street_name}
            required
            onChange={handleAddressChange}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5'>
          <InputText
            label='Floor, Unit No., and Building Name'
            name='building'
            value={addressForm.building}
            onChange={handleAddressChange}
          />
        </div>
        <div className='col-4'>
          <InputSelect
            label='Region*'
            name='region'
            value={addressForm.region}
            required
            options={regions}
            placeholder='Select Region'
            onChange={selectRegion}
          />
        </div>
        <div className='col-3'>
          <InputText
            label='Zip Code*'
            name='zip_code'
            value={addressForm.zip_code}
            required
            maxLength='4'
            onChange={handleAddressChange}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <InputSelect
            label='City*'
            name='city'
            value={addressForm.city}
            required
            options={cities}
            placeholder='Select City'
            onChange={selectCity}
          />
        </div>
        <div className='col-6'>
          <InputSelect
            label='Barangays*'
            name='barangay'
            value={addressForm.barangay}
            required
            options={barangays}
            placeholder='Select Barangay'
            onChange={selectBarangay}
          />
        </div>
      </div>
    </Fragment>
  )
}

NewAddressSection.propTypes = {
  handleAddressChange: PropTypes.func,
  addressForm: PropTypes.object,
  regions: PropTypes.array,
  selectRegion: PropTypes.func,
  cities: PropTypes.array,
  selectCity: PropTypes.func,
  barangays: PropTypes.array,
  selectBarangay: PropTypes.func
}

export default NewAddressSection
