import React, { Component } from 'react'
import LocationsAPI from '../../services/locations_api'
import AddressAPI from '../../services/address_api'
import AddressFormRow from '../components/AddressFormRow'
import {
  getBarangays,
  getCities,
  getRegions
} from '../../utilities/locationsHelper'

export default class AddressesContainer extends Component {
  state = {
    form: {
      house_number: this.props.address.house_number || '',
      country: 'Philippines',
      street_name: this.props.address.street_name || '',
      building: this.props.address.building || '',
      region: this.props.address.region || '',
      city: this.props.address.city || '',
      barangay: this.props.address.barangay || '',
      zip_code: this.props.address.zip_code || ''
    },
    regions: [],
    cities: [],
    barangays: []
  }

  componentDidMount() {
    const { id, region, city } = this.props.address
    LocationsAPI.fetch(({ data }) => {
      const regionsData = getRegions(data)
      if (id) {
        const citiesData = getCities(region, data)
        const barangaysData = getBarangays(region, city, data)
        this.setState({
          locations: data,
          regions: regionsData,
          cities: citiesData,
          barangays: barangaysData
        })
      } else {
        this.setState({
          locations: data,
          regions: regionsData
        })
      }
    })
  }

  selectRegion = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      cities: getCities(e.target.value, this.state.locations),
      barangays: []
    })
  }

  selectCity = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      barangays: getBarangays(
        this.state.form.region,
        e.target.value,
        this.state.locations
      )
    })
  }

  selectBarangay = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      barangay: e.target.value
    })
  }

  handleChange = e => {
    const { form } = this.state
    const { name, value } = e.target

    let newForm = {
      ...form,
      [name]: value
    }

    this.setState({
      form: newForm
    })
  }

  handleSubmit = data => {
    if (this.props.address.id) {
      AddressAPI.update(
        this.props.user.id,
        this.props.address.id,
        data,
        response => {
          if (response.statusText === 'OK') {
            window.location = `/addresses`
          }
        }
      )
    } else {
      AddressAPI.create(this.props.user.id, data, response => {
        if (response.statusText === 'OK') {
          window.location = `/addresses`
        }
      })
    }
  }

  render() {
    const { form, regions, cities, barangays } = this.state
    const {
      house_number,
      street_name,
      building,
      region,
      city,
      barangay,
      zip_code
    } = this.state.form
    return (
      <AddressFormRow
        form={form}
        regions={regions}
        selectRegion={this.selectRegion}
        cities={cities}
        selectCity={this.selectCity}
        barangays={barangays}
        selectBarangay={this.selectBarangay}
        handleChange={this.handleChange}
        onSubmit={this.handleSubmit}
        houseNumber={house_number}
        streetName={street_name}
        building={building}
        region={region}
        city={city}
        barangay={barangay}
        zipCode={zip_code}
        addressId={this.props.address.id}
      />
    )
  }
}
