import swal from 'sweetalert2'

const SweetAlert = {
  error: (title, text) =>
    swal({
      type: 'error',
      title: title,
      text: text
    }),
  prompt: (confirmText, title = 'Are you sure?', options = {}) =>
    swal({
      title,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText,
      ...options
    }),
  success: (title, text) =>
    swal({
      type: 'success',
      title: title,
      text: text
    })
}

export default SweetAlert
