import React from 'react'
import PropTypes from 'prop-types'

import PaymentTypeDescription from './PaymentTypeDescription'
import PaymentFormButton from './PaymentFormButton'
import PaymentOptions from './PaymentOptions'

const PaymentOptionsForm = ({
  onSubmit,
  handlePaymentOption,
  form,
  disableButton
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(form)
  }

  return (
    <div className='col-7'>
      <form onSubmit={handleSubmit}>
        <PaymentOptions form={form} handlePaymentOption={handlePaymentOption} />
        <hr />
        <PaymentTypeDescription paymentType={form.transaction_source} />
        <PaymentFormButton form={form} disableButton={disableButton} />
      </form>
    </div>
  )
}

PaymentOptionsForm.propTypes = {
  onSubmit: PropTypes.func,
  handlePaymentOption: PropTypes.func,
  form: PropTypes.object,
  disableButton: PropTypes.bool
}

export default PaymentOptionsForm
