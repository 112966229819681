import React from 'react'
import {
  BLOWUP_IMAGE,
  CLOSE_MODAL,
  CLEAR_MODAL,
  OPEN_MODAL,
  RESET_STORE
} from '../constants/ActionTypes'
import { RECEIVE_PRODUCT_ITEM } from '../Marketplace/actionTypes'

import ProductImage from '../components/products/ProductImage'
import ProductDetail from '../Marketplace/components/ProductDetail'

const initialState = {
  modalOpen: false,
  children: null,
  data: {}
}

const renderProperContainer = (state, action) => {
  switch (action.actionType) {
    case BLOWUP_IMAGE:
      return {
        ...state,
        modalOpen: true,
        children: renderProductImage(state)
      }
    case RECEIVE_PRODUCT_ITEM:
      return {
        ...state,
        modalOpen: true,
        children: renderProductDetails(state)
      }
    default:
      return state
  }
}

const modals = (state = initialState, action) => {
  switch (action.type) {
    case BLOWUP_IMAGE:
      return { ...state, data: { product: action.product } }
    case RECEIVE_PRODUCT_ITEM:
      return {
        ...state,
        data: { product: action.product, productItems: action.productItems }
      }

    case OPEN_MODAL:
      return renderProperContainer(state, action)
    case CLOSE_MODAL:
      return { ...state, modalOpen: false, children: null }
    case CLEAR_MODAL:
      return { ...state, children: null }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}

const renderProductImage = state => {
  const {
    cover_url_large,
    description,
    title,
    formatted_price,
    publisher_name,
    creators
  } = state.data.product
  return (
    <ProductImage
      formatted_price={formatted_price}
      publisher_name={publisher_name}
      creators={creators}
      title={title}
      description={description}
      image={cover_url_large}
    />
  )
}

const renderProductDetails = state => {
  const { product, productItems } = state.data
  return <ProductDetail product={product} productItems={productItems} />
}

export default modals
