import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../presenters/format'
import { fetchPrice, fetchQuantity, fetchProductTitle } from '../utilities'

const ItemRow = ({ item, onCartRemove }) => {
  return (
    <tr>
      <td>{fetchProductTitle(item)}</td>
      <td>{fetchQuantity(item)}</td>
      <td>{formatCurrency(fetchPrice(item))}</td>
      <td>
        <button
          className='btn btn-sm btn-danger'
          onClick={() => onCartRemove(item.id)}
        >
          Remove
        </button>
      </td>
    </tr>
  )
}

ItemRow.propTypes = {
  item: PropTypes.object.isRequired,
  onCartRemove: PropTypes.func.isRequired
}

export default ItemRow
