// @flow

import React, { Component } from 'react'

const alertTypes = {
  error: 'alert-danger',
  info: 'alert-info',
  success: 'alert-success'
}

type Props = {
  label: string,
  message: string,
  onClick: Function,
  type: string
}

export default class Alert extends Component<Props> {
  render() {
    const { label, message, onClick, type } = this.props
    return (
      <div
        className={'alert ' + alertTypes[type] || alertTypes.info}
        role='alert'
      >
        {message}{' '}
        {label && (
          <button className='btn btn-light' onClick={onClick}>
            {label}
          </button>
        )}
      </div>
    )
  }
}
