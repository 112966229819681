import React from 'react'

import PropTypes from 'prop-types'

class TableFooter extends React.Component {
  static propTypes = {
    reservationList: PropTypes.object.isRequired,
    updateReservationList: PropTypes.func.isRequired,
    handleChecked: PropTypes.func.isRequired
  }

  submitData = event => {
    event.preventDefault()
    const $form = $(event.currentTarget).serializeArray()

    let data = {}

    $form.map(item => {
      data[item.name] = item.value
    })

    this.props.updateReservationList(this.props.reservationList.id, data)
  }

  getDiscount() {
    return this.formattedAmount(this.props.reservationList.discount_total)
  }

  getShipping() {
    return this.formattedAmount(this.props.reservationList.shipping_total)
  }

  showDiscount() {
    const { discount_rate } = this.props.reservationList.user

    if (+discount_rate > 0) {
      return (
        <tr>
          <td colSpan='3'>
            <strong className='float-right'>{discount_rate}% Discount:</strong>
          </td>
          <td colSpan='2'>
            <strong className='float-right'>{this.getDiscount()}</strong>
          </td>
        </tr>
      )
    }
  }

  showShipping() {
    if (this.props.reservationList.shipping_region) {
      return (
        <tr>
          <td colSpan='3'>
            <strong className='float-right'>Shipping Fee:</strong>
          </td>
          <td colSpan='2'>
            <strong className='float-right'>{this.getShipping()}</strong>
          </td>
        </tr>
      )
    }
  }

  showTotalPrice() {
    return this.formattedAmount(this.props.reservationList.total_price)
  }

  formattedAmount(amount) {
    return parseFloat(Math.round(amount * 100) / 100).toFixed(2)
  }

  render() {
    return (
      <tfoot>
        {this.showDiscount()}
        {this.showShipping()}
        <tr>
          <td colSpan='3'>
            <strong className='float-right'>Total Price:</strong>
          </td>
          <td colSpan='2'>
            <strong className='float-right'>{this.showTotalPrice()}</strong>
          </td>
        </tr>
        <tr>
          {this.props.reservationList.status === 'open' ? (
            <td colSpan='5'>
              <strong>Notes: </strong>
              <form onSubmit={e => this.submitData(e)}>
                <textarea
                  className='notes'
                  name='notes'
                  rows='3'
                  defaultValue={this.props.reservationList.notes}
                />
                <button type='submit' className='btn btn-xs btn-primary'>
                  Update
                </button>
              </form>
            </td>
          ) : (
            <td colSpan='5'>
              <strong>Notes: </strong>
              <p className='raw-html'>{this.props.reservationList.notes}</p>
            </td>
          )}
        </tr>
        <tr>
          <td colSpan='5' className='text-right'>
            {this.props.reservationList.status === 'open' || this.props.reservationList.status ==='partially_approved' ? (
              <button
                type='button'
                className='btn btn-success'
                onClick={e => this.props.handleChecked(e)}
              >
                Set as Checked
              </button>
            ) : null}
            {this.props.reservationList.status === 'checked' ? (
              <button
                type='button'
                className='btn btn-success'
                onClick={e => this.props.handleChecked(e)}
              >
                Un-check
              </button>
            ) : null}
          </td>
        </tr>
      </tfoot>
    )
  }
}

export default TableFooter
