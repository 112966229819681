import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '../../presenters/format'
import { humanizeReservationStatus } from '../../presenters/humanize'

const BoxTableRow = ({ reservation, onCartAdd, onCartRemove, cartItem }) => {
  const onAdd = () => {
    onCartAdd(reservation.id)
  }

  const onRemove = () => {
    onCartRemove(cartItem.id)
  }

  const renderActions = () => {
    if (reservation.status !== 'fill') {
      return null
    }
    if (reservation.order_id) {
      return null
    }
    return cartItem ? (
      <button className='btn btn-sm btn-danger' onClick={onRemove}>
        Remove
      </button>
    ) : (
      <button className='btn btn-sm btn-primary' onClick={onAdd}>
        Add to Cart
      </button>
    )
  }

  return (
    <tr className='box-row'>
      <td>
        {reservation.product.title}
        {reservation.release && (
          <div className='subtitle'>{reservation.release.title}</div>
        )}
      </td>
      <td>{humanizeReservationStatus(reservation.status)}</td>
      <td>{formatCurrency(reservation.product.price)}</td>
      <td>{reservation.quantity}</td>
      <td>{formatCurrency(reservation.price)}</td>
      <td>{renderActions()}</td>
    </tr>
  )
}

BoxTableRow.propTypes = {
  reservation: PropTypes.object.isRequired,
  cartItem: PropTypes.object,
  onCartAdd: PropTypes.func.isRequired,
  onCartRemove: PropTypes.func.isRequired
}

export default BoxTableRow
