import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from '../../presenters/format'

const DiscountRateView = ({ discountTotal, discountRate }) => {
  return (
    <tr>
      <td>
        <strong>Order Discount {discountRate}%</strong>
      </td>
      <td />
      <td>({formatCurrency(discountTotal)})</td>
      <td />
    </tr>
  )
}

DiscountRateView.propTypes = {
  discountTotal: PropTypes.number,
  discountRate: PropTypes.number
}

export default DiscountRateView
